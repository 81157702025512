import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';
import Addresses from 'views/pages/clients/Addresses/Addresses';

// dashboard routing
const Products = Loadable(lazy(() => import('views/pages/products/Products')));
const Carts = Loadable(lazy(() => import('views/pages/clients/carts/Carts')));
const Checkouts = Loadable(lazy(() => import('views/pages/clients/checkouts/Checkouts')));
const OrderSuccess = Loadable(lazy(() => import('views/pages/clients/orders/OrderSuccess')));
const OrderDetails = Loadable(lazy(() => import('views/pages/clients/orders/OrderDetails')));
const ClientOrders = Loadable(lazy(() => import('views/pages/clients/orders/ClientOrders')));
const PaymentStatusPage = Loadable(lazy(() => import('views/pages/clients/payment-success/PaymentStatusPage')));
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const ClientRoutes = isAuthenticated => ({
  path: '/',
  element: isAuthenticated ? <MainLayout /> : <Navigate to="/auth/signin" />,
  children: [
    {
      path: '',
      element: <Products />,
    },
    {
      path: 'products',
      children: [
        {
          path: '',
          element: <Products />,
        },
      ],
    },
    {
      path: 'view-cart',
      children: [
        {
          path: '',
          element: <Carts />,
        },
      ],
    },
    {
      path: 'address',
      children: [
        {
          path: '',
          element: <Addresses />,
        },
      ],
    },
    {
      path: 'view-checkout',
      children: [
        {
          path: '',
          element: <Checkouts />,
        },
      ],
    },
    {
      path: 'order-success',
      children: [
        {
          path: '',
          element: <OrderSuccess />,
        },
      ],
    },
    {
      path: 'orders/order-details/:orderId',
      children: [
        {
          path: '',
          element: <OrderDetails />,
        },
      ],
    },
    {
      path: 'account/orders',
      children: [
        {
          path: '',
          element: <ClientOrders />,
        },
      ],
    },
    {
      path: 'payment-status/:paymentOrderId/:paymentStatus/:amount',
      children: [
        {
          path: '',
          element: <PaymentStatusPage />,
        },
      ],
    },
  ],
});

export default ClientRoutes;
