import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

export default function CustomTooltip(props) {
  const { placement = 'top', content = '', style = {}, className = '' } = props;
  return (
    <BootstrapTooltip style={style} className={className} placement={placement} title={content}>
      <b>{!content ? <>{props.children}</> : props.children}</b>
    </BootstrapTooltip>
  );
}
