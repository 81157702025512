import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import { APIProvider, AdvancedMarker, InfoWindow, Map } from '@vis.gl/react-google-maps';
import { CONST_GETALL, CONST_LOCAL_STORAGE_BRANCH, CONST_MODULE_ORDERS } from 'utils/constants';
import { useNavigate, useParams } from 'react-router-dom';
import { getLocalStorage, getLocalUser, getUserRole, setLocalStorage } from 'utils/commonFunc';
import MapDirections from './MapDirections';
import MapSocketConnection from './MapSocketConnection';
import { Box, Divider, IconButton } from '@mui/material';
import BlinkCircle from 'ui-component/icons/BlinkCircle';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { createLogger } from 'ui-component/logger';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useAppContext from 'store/useAppContext';
import CustomCard from 'ui-component/CustomCard/CustomCard';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import ClearIcon from '@mui/icons-material/Clear';
import { productTypesForSelect } from 'utils/variables';
import { AccountCircle, ArrowBack, Call, LocationOn, PhoneInTalkRounded } from '@mui/icons-material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CustomButton from 'ui-component/CustomButton/CustomButton';

export const checkLocationPermission = async () => {
  try {
    const result = await navigator.permissions.query({ name: 'geolocation' });
    return result.state === 'granted';
  } catch (error) {
    console.error('Error checking location permission:', error);
    return false;
  }
};

const isPlatform = {
  web: typeof document !== 'undefined' && !window?.ReactNativeWebView,
  reactNative: typeof document !== 'undefined' && !!window?.ReactNativeWebView,
};

const module = CONST_MODULE_ORDERS;
const TrackOrder = () => {
  const logger = createLogger('TrackOrder');
  const navigate = useNavigate();
  const stompClient = useRef(null);
  const { orderSessionId, toLat = 0, toLng = 0 } = useParams();
  const branchInfo = getLocalStorage(CONST_LOCAL_STORAGE_BRANCH);
  const { latitude = 0, longitude = 0 } = branchInfo ?? {};
  const center = { lat: Number(latitude), lng: Number(longitude) };
  const user = getLocalUser();
  const { isRoleRider, isRoleCustomer } = getUserRole();
  const { crudMethods, ordersMethods: { setOrdersState = undefined } = {} } = useAppContext();

  const endCoords = useMemo(() => ({ lat: Number(toLat), lng: Number(toLng) }), [toLat, toLng]);
  const [currentCoords, setCurrentCoords] = useState(null);
  const [isRiderInfoOpen, setRiderInfoOpen] = useState(false);
  const [isHomeInfoOpen, setHomeInfoOpen] = useState(false);

  const defaultZoom = 10;
  const [myZoom, setMyZoom] = useState(defaultZoom);
  const [myCenter, setMyCenter] = useState(endCoords);

  const [riderInfo, setRiderInfo] = useState({});
  const [orderList, setOrderList] = useState([]);

  // Watch location enable
  const [isLocationEnabled, setIsLocationEnabled] = useState(false);

  useEffect(() => {
    if ((!orderSessionId || !toLat || !toLng) && isRoleRider) {
      navigate('/rider/delivery-orders?tab=assigned');
    }
  }, [isRoleRider, orderSessionId, toLat, toLng, navigate]);

  const checkPermission = async () => {
    try {
      const permissionGranted = await checkLocationPermission();
      setIsLocationEnabled(permissionGranted);
    } catch (error) {
      console.error('Error checking location permission:', error);
      setIsLocationEnabled(false);
    }
  };

  const handleLocationPermission = event => {
    const { isEnabled } = event.detail;
    setIsLocationEnabled(!!isEnabled);
  };

  useEffect(() => {
    window.addEventListener('locationPermissionChange', handleLocationPermission);

    const isWebView = !!window?.ReactNativeWebView;
    if (!isWebView) {
      checkPermission();
      const intervalId = setInterval(checkPermission, 10_000);
      return () => clearInterval(intervalId);
    }

    return () => {
      window.removeEventListener('locationPermissionChange', handleLocationPermission);
    };
  }, []);

  const enableLocationManually = () => {
    if (window?.ReactNativeWebView) {
      // In WebView environment
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          type: 'ENABLE_LOCATION',
        })
      );
    } else {
      // In web environment
      alert(
        "To enable location:\n\n1. Ensure your device's location services are turned on.\n" +
          '2. Check your browser settings and allow location access for this website.\n' +
          '3. Refresh the page after enabling location.'
      );
    }
  };

  const commonParams = useMemo(
    () => ({
      module,
      crudMethods,
      setState: setOrdersState,
    }),
    [crudMethods, setOrdersState]
  );

  const fetchOrderDetails = useCallback(async () => {
    const orderRes = await handleApiAction({
      ...commonParams,
      action: CONST_GETALL,
      apiPropName: 'getOrderItemsBySession',
      orderSessionId,
      loadingParam: 'allOrdersItemsBySessionFetching',
    });
    orderRes.ok && setOrderList(orderRes.data);
  }, [commonParams, orderSessionId]);

  const fetchRiderDetails = useCallback(async () => {
    const riderRes = await handleApiAction({
      ...commonParams,
      action: CONST_GETALL,
      apiPropName: 'getRiderDetailsBySessionId',
      orderSessionId,
      loadingParam: 'riderDetailsBySessionFetching',
    });
    riderRes.ok && setRiderInfo(riderRes.data);
  }, [commonParams, orderSessionId]);

  useEffect(() => {
    fetchOrderDetails();
    fetchRiderDetails();
  }, [orderSessionId]);

  const sendTopic = '/app/chat.sendRestaurantWebSocket';
  const sendMessageToWebSocket = useCallback(message => {
    if (stompClient.current?.connected || stompClient.current?.active) {
      try {
        stompClient.current?.publish({
          destination: sendTopic,
          body: JSON.stringify(message),
        });
      } catch (err) {
        console.error('Error sending message:', err?.message);
      }
    } else {
      console.warn('STOMP connection not ready');
    }
  }, []);

  const updateCoords = position => {
    const { latitude: lat, longitude: lng } = position?.coords ?? {};
    if (lat && lng && isRoleRider) {
      const locData = {
        type: 'GPS_LOC',
        sender: user.userId,
        locationDto: {
          latitude: lat,
          longitude: lng,
          imeiNo: '1',
        },
      };
      sendMessageToWebSocket(locData);
      setCurrentCoords({ lat, lng });
    }
  };

  const handleNativeLocation = event => {
    const locationData = event.detail;
    if (locationData.type === 'LOCATION_UPDATE') {
      updateCoords(locationData);
    }
  };

  useEffect(() => {
    if (isRoleRider) {
      if (isPlatform.web) {
        navigator.geolocation.getCurrentPosition(
          position => {
            updateCoords(position);
          },
          error => {
            logger.error('getCurrentPosition', error);
          }
        );

        const interval = setInterval(() => {
          navigator.geolocation.getCurrentPosition(
            position => {
              updateCoords(position);
            },
            error => {
              logger.error('intervalUpdate', error);
            }
          );
        }, 5_000);

        return () => clearInterval(interval);
      } else {
        window.addEventListener('nativeLocationUpdate', handleNativeLocation);

        return () => {
          window.removeEventListener('nativeLocationUpdate', handleNativeLocation);
        };
      }
    }
  }, [isRoleRider]);

  // Handle receiving location updates (for customer view)
  const handleLocationReceivedFromRider = locationData => {
    if (!isRoleRider && locationData.type === 'GPS_LOC') {
      const { latitude: lat, longitude: lng } = locationData?.locationDto ?? {};
      setCurrentCoords({ lat, lng });
      orderSessionId && setLocalStorage(orderSessionId, { lat, lng });
    }
  };

  const handleCallCustomer = phoneNumber => {
    if (phoneNumber) {
      if (window.navigator.userAgent.includes('wv')) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'DIAL_CALL',
            phoneNumber,
          })
        );
      } else {
        window.location.href = `tel:${phoneNumber}`;
      }
    }
  };

  const lastRiderLatLng = getLocalStorage(orderSessionId);
  const liveCoords = currentCoords?.lat ? currentCoords : lastRiderLatLng;

  const getItemType = passValue => {
    const itemType = productTypesForSelect.find(typeData => typeData.value === passValue);
    return itemType.label;
  };

  const customerName = orderList?.[0]?.customerName || '';
  const customerNumber = orderList?.[0]?.phoneNumber || '';
  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <CustomButton
          size="small"
          variant="outlined"
          startIcon={<ArrowBack sx={{ fontSize: 14 }} />}
          name="Go back"
          sx={{ mb: 1 }}
          onClick={() => {
            navigate(-1);
          }}
        />
        {isRoleRider && !isLocationEnabled && (
          <CustomButton
            size="small"
            variant="outlined"
            startIcon={<LocationOn sx={{ fontSize: 14 }} />}
            name="Enable Location"
            sx={{ mb: 1 }}
            onClick={() => {
              enableLocationManually();
            }}
          />
        )}
      </Box>
      {!isRoleCustomer && (
        <CustomCard sx={{ p: 2, mb: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <CustomFlexRow sx={{ justfyContent: 'center', alignItems: 'center' }}>
              <AccountCircle sx={{ mr: 1 }} />
              <CustomTypography variant="" sx={{ fontSize: 15 }}>
                <b>Order from {customerName || 'Customer'}</b>
              </CustomTypography>
            </CustomFlexRow>
            <IconButton
              color="primary"
              onClick={() => {
                handleCallCustomer(customerNumber);
              }}>
              <Call fontSize="small" />
            </IconButton>
          </Box>
        </CustomCard>
      )}
      {!isRoleRider && (
        <CustomCard sx={{ p: 2, mb: 2 }}>
          <Box sx={{ py: 1 }}>
            <CustomTypography variant="h4">Item Details</CustomTypography>
          </Box>
          <Divider />
          {orderList.map((order, i) => {
            return (
              <Box key={order.orderSessionId} sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                <Box sx={{ justfyContent: 'center', alignItems: 'center' }}>
                  <CustomFlexRow sx={{ justfyContent: 'center', alignItems: 'center' }}>
                    <FiberManualRecordIcon sx={{ color: 'green', fontSize: 17 }} />
                    <CustomTypography variant="h5" sx={{ ml: 1 }}>
                      {order.productName}
                    </CustomTypography>
                  </CustomFlexRow>
                  <CustomTypography variant="caption" sx={{ fontSize: 10, ml: 3 }}>
                    {getItemType(order.productType)}
                  </CustomTypography>
                </Box>
                <CustomFlexRow sx={{ justfyContent: 'center', alignItems: 'center' }}>
                  <ClearIcon sx={{ fontSize: 15 }} />
                  <CustomTypography variant="h3" sx={{ pl: 1 }}>
                    {order.quantity}
                  </CustomTypography>
                </CustomFlexRow>
              </Box>
            );
          })}
        </CustomCard>
      )}
      <CustomCard>
        <Box p={2}>
          {!isRoleRider && riderInfo?.riderName && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
              <CustomFlexRow sx={{ justfyContent: 'center', alignItems: 'center' }}>
                <AccountBoxIcon sx={{ mr: 1 }} fontSize="large" />
                <CustomTypography variant="body2" sx={{ fontSize: 15 }}>
                  <b>{riderInfo?.riderName}</b> is reaching soon.
                </CustomTypography>
              </CustomFlexRow>
              <IconButton
                color="primary"
                onClick={() => {
                  handleCallCustomer(riderInfo?.phoneNumber);
                }}>
                <PhoneInTalkRounded fontSize="small" color="error" />
              </IconButton>
            </Box>
          )}
          <Box
            p={1}
            sx={{
              position: 'relative',
              '& > div': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              },
              height: '80vh',
            }}>
            <APIProvider
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              libraries={['maps', 'marker', 'places', 'routes', 'streetView']}>
              <Map
                disableDefaultUI={false}
                style={{ width: '100%', height: '100%' }}
                defaultZoom={defaultZoom}
                defaultCenter={currentCoords?.lat ? currentCoords : center}
                center={myCenter}
                zoom={myZoom}
                mapId="RES_MAP_ID"
                onZoomChanged={gmap => setMyZoom(gmap.detail.zoom)}
                onCenterChanged={gmap => setMyCenter(gmap.detail.center)}>
                <AdvancedMarker
                  key="rider"
                  position={liveCoords}
                  // onClick={() => setRiderInfoOpen(true)}
                >
                  <BlinkCircle />
                  {isRiderInfoOpen && (
                    <InfoWindow position={currentCoords} onCloseClick={() => setRiderInfoOpen(false)}>
                      <div>Rider's Current Location</div>
                    </InfoWindow>
                  )}
                </AdvancedMarker>
                <AdvancedMarker
                  key="home"
                  position={endCoords}
                  // onClick={() => setHomeInfoOpen(true)}
                >
                  <PersonPinIcon fontSize="large" style={{ color: 'red' }} />
                  {isHomeInfoOpen && (
                    <InfoWindow position={endCoords} onCloseClick={() => setHomeInfoOpen(false)}>
                      <div>Delivery Destination</div>
                    </InfoWindow>
                  )}
                </AdvancedMarker>
                {!!liveCoords?.lat && (
                  <MapDirections currentCoords={liveCoords} endCoords={endCoords} myCenter={myCenter} myZoom={myZoom} />
                )}
                {!liveCoords?.lat && (
                  <InfoWindow position={endCoords} headerDisabled>
                    <Box sx={{ py: 1 }}>Loading...</Box>
                    <Box>Waiting for rider's location...</Box>
                  </InfoWindow>
                )}
              </Map>
            </APIProvider>
            <MapSocketConnection
              isRider={isRoleRider}
              userId={user.userId}
              orderSessionId={orderSessionId}
              currentLocation={currentCoords}
              onMessageReceived={handleLocationReceivedFromRider}
              stompClient={stompClient}
              sendMessageToWebSocket={sendMessageToWebSocket}
            />
          </Box>
        </Box>
      </CustomCard>
    </div>
  );
};

export default TrackOrder;
