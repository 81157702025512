// constants
const CONST_LOCAL_STORAGE_LOGGED_USER = 'loggedUser';
const CONST_LOCAL_STORAGE_USER_ADDRESS = 'userAddress';
const CONST_LOCAL_STORAGE_BRANCH = 'branch';
// Roles
const CONST_ROLE_ADMIN = 'ROLE_ADMIN';
const CONST_ROLE_CUSTOMER = 'ROLE_CUSTOMER';
const CONST_ROLE_RIDER = 'ROLE_RIDER';
// module
const CONST_MODULE_CATEGORIES = 'CATEGORIES';
const CONST_MODULE_DISCOUNTS = 'DISCOUNTS';
const CONST_MODULE_PRODUCTS = 'PRODUCTS';
const CONST_MODULE_EMPLOYEES = 'EMPLOYEES';
const CONST_MODULE_DEPARTMENTS = 'DEPARTMENTS';
const CONST_MODULE_DESIGNATIONS = 'DESIGNATIONS';
const CONST_MODULE_CARTS = 'CARTS';
const CONST_MODULE_ADDRESSES = 'ADDRESSES';
const CONST_MODULE_ORDERS = 'ORDERS';
const CONST_MODULE_DASHBOARDS = 'DASHBOARDS';
const CONST_MODULE_BRANCHES = 'BRANCHES';
const CONST_MODULE_PAYMENTS = 'PAYMENTS';
const CONST_MODULE_VOUCHERS = 'VOUCHERS';
// action variables
const CONST_PAGINATION_SIZE = 10;
const CONST_ACTION_ADD = 'ADD';
const CONST_ACTION_VIEW = 'VIEW';
// CRUD
const CONST_GETALL = 'getAll';
const CONST_GET = 'get';
const CONST_POST = 'post';
const CONST_PUT = 'put';
const CONST_PATCH = 'patch';
const CONST_DELETE = 'delete';

//Form types
const CONST_TYPE_TEXT = 'text';
const CONST_TYPE_EMAIL = 'email';
const CONST_TYPE_NUMBER = 'number';
const CONST_TYPE_TEXT_AREA = 'textarea';
const CONST_TYPE_TIME = 'time';
// Form fieldTypes
const CONST_FIELD_INPUT = 'INPUT';
const CONST_FIELD_DATE_PICKER = 'DATE_PICKER';
const CONST_FIELD_RADIO = 'RADIO';
const CONST_FIELD_RADIO_GROUP = 'RADIO_GROUP';
const CONST_FIELD_CHECKBOX = 'CHECKBOX';
const CONST_FIELD_SELECT = 'SELECT';
const CONST_FIELD_MULTI_SELECT = 'MULTI_SELECT';

// Dialog open/close params
const CONST_MODULE_EMPLOYEES_MODAL = 'employeesModalOpen';
const CONST_APP_CONFIRM_MODAL = 'isConfirmModalOpen';
const CONST_APP_CONFIRM_MODAL_HANDLE = 'handleAppConfirmModal';

// Department
const CONST_MODULE_DEPARTMENTS_MODAL = 'departmentsModalOpen';
const CONST_MODULE_DESIGNATIONS_MODAL = 'designationsModalOpen';
// Menus
const CONST_APP_MENU = 'isMoreMenuOpen';

// components
const COMP_CustomCard = 'CustomCard';

// Message types
const CONST_TYPE_SUCCESS = 'success';
const END_TIME_VALIDATION_ERROR = 'Close time cannot be less or equal to Open time';

// Loading
const CONST_LOADING_SIGNUP = 'isSignupLoading';
const CONST_LOADING_SIGNIN = 'isLoginLoading';

// Color
const CONST_COLOR_BUTTON = 'primary';

// Routes
const CONST_ROUTE_BASE = '/';
const CONST_ROUTE_SIGN_IN = '/auth/signin';
const CONST_ROUTE_SIGN_UP = '/auth/signup';

// Const
const CONST_LOGGED_USER = 'loggedUser';
const CONST_PLACE_ORDER = 'PLACE ORDER';
const CONST_CHECKOUT = 'PROCEED TO BUY';
const CONST_ADD_MORE_ITEMS = 'ADD MORE ITEMS';
const CONST_CONTINUE = 'CONTINUE';
// Order type
const CONST_ORDER_COD = 'DELIVERY';
const CONST_ORDER_PAG = 'TAKE_AWAY';
// Payment type
const CONST_PAY_COD = 'PAY_COD';
const CONST_PAY_ONLINE = 'PAY_ONLINE';
// Order status
const CONST_ORDER_STATUS = 'PAYMENT_PENDING';
const CONST_STATUS_PAYMENT_COD = 'PAYMENT_COD';
const CONST_ORDER_STATUS_PAYMENT_PENDING = 'PAYMENT_PENDING';
const CONST_ORDER_STATUS_PAYMENT_FAILED = 'PAYMENT_FAILED';
const CONST_ORDER_STATUS_PAYMENT_COMPLETED = 'PAYMENT_COMPLETED';
const CONST_ORDER_STATUS_PAYMENT_COD = 'PAYMENT_COD';
const CONST_ORDER_STATUS_ORDER_PLACED = 'ORDER_PLACED';
const CONST_ORDER_STATUS_PREPARING = 'PREPARING';
const CONST_ORDER_STATUS_READY_TO_SERVE = 'READY_TO_SERVE';
const CONST_ORDER_STATUS_ON_THE_WAY = 'ON_THE_WAY';
const CONST_ORDER_STATUS_DELIVERED = 'DELIVERED';
const CONST_ORDER_STATUS_KEPT_IN_TABLE = 'KEPT_IN_TABLE';
const CONST_ORDER_STATUS_TAKE_AWAY = 'TAKE_AWAY';
const CONST_ORDER_STATUS_ORDER_COMPLETED = 'ORDER_COMPLETED';
const CONST_ORDER_STATUS_ORDER_FAILED = 'ORDER_FAILED';
const CONST_ORDER_STATUS_ORDER_REQUESTED = 'ORDER_REQUESTED';
const CONST_ORDER_STATUS_ORDER_CANCELLED = 'ORDER_CANCELLED';
const CONST_ORDER_STATUS_RIDE_ASSIGNED = 'RIDE_ASSIGNED';
const CONST_ORDER_STATUS_PICKED_UP = 'PICKED_UP';
const CONST_ORDER_STATUS_OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY';
// Payment status
const CONST_PAYMENT_STATUS_SUCCESS = 'SUCCESS';
// Label
const CONST_LABEL_REMOVE = 'Remove';
const CONST_LABEL_COD = 'Cash On Delivery';
const CONST_LABEL_PAY_AND_GET = 'Pay and Get';
const CONST_LABEL_ONLINE_PAY = 'Online Pay';
const CONST_LABEL_PAGE = 'PAGE';
const CONST_LABEL_DATA = 'DATA';
const CONST_LABEL_USER_ROLE = {
  [CONST_ROLE_ADMIN]: 'Admin',
  [CONST_ROLE_CUSTOMER]: 'Customer',
};
const CONST_LABEL_PRODUCT = 'Item';
const CONST_LABEL_PRODUCTS = 'Items';
const CONST_LABEL_FOOD = 'Food';
const CONST_LABEL_FOODS = 'Foods';
const CONST_LABEL_VOUCHERS = 'Vouchers';

// Pages
const CONST_PAGE_CART = 'CART';
const CONST_PAGE_CHECKOUT = 'CHECKOUT';

// Order delivery
const CONST_TAB_AWAITING = 'AWAITING';
const CONST_TAB_ASSIGNED = 'ASSIGNED';
const CONST_TAB_DELIVERED = 'DELIVERED';

let fetchRecentOrderTimer = '';

const CONST_SMALL_ORDER_FEE_ORDER_AMOUNT = 20;

export { COMP_CustomCard };
export {
  CONST_LOCAL_STORAGE_LOGGED_USER,
  CONST_LOCAL_STORAGE_USER_ADDRESS,
  CONST_LOCAL_STORAGE_BRANCH,
  // Role
  CONST_ROLE_ADMIN,
  CONST_ROLE_CUSTOMER,
  CONST_ROLE_RIDER,
  // Module
  CONST_MODULE_CATEGORIES,
  CONST_MODULE_DISCOUNTS,
  CONST_MODULE_PRODUCTS,
  CONST_MODULE_EMPLOYEES,
  CONST_MODULE_DEPARTMENTS,
  CONST_MODULE_DESIGNATIONS,
  CONST_MODULE_CARTS,
  CONST_MODULE_ADDRESSES,
  CONST_MODULE_ORDERS,
  CONST_MODULE_DASHBOARDS,
  CONST_MODULE_BRANCHES,
  CONST_MODULE_PAYMENTS,
  CONST_MODULE_VOUCHERS,
  // Page and action
  CONST_PAGINATION_SIZE,
  CONST_ACTION_ADD,
  CONST_ACTION_VIEW,
  // Crud
  CONST_GETALL,
  CONST_GET,
  CONST_POST,
  CONST_PUT,
  CONST_PATCH,
  CONST_DELETE,
  // Form types
  CONST_TYPE_TEXT,
  CONST_TYPE_EMAIL,
  CONST_TYPE_NUMBER,
  CONST_TYPE_TEXT_AREA,
  CONST_TYPE_TIME,
  // Form fieldTypes
  CONST_FIELD_INPUT,
  CONST_FIELD_DATE_PICKER,
  CONST_FIELD_RADIO_GROUP,
  CONST_FIELD_RADIO,
  CONST_FIELD_CHECKBOX,
  CONST_FIELD_SELECT,
  CONST_FIELD_MULTI_SELECT,
  // Modals
  CONST_MODULE_EMPLOYEES_MODAL,
  CONST_APP_CONFIRM_MODAL,
  CONST_APP_CONFIRM_MODAL_HANDLE,
  // dept
  CONST_MODULE_DEPARTMENTS_MODAL,
  CONST_MODULE_DESIGNATIONS_MODAL,
  // Menu
  CONST_APP_MENU,
  // Loading
  CONST_LOADING_SIGNUP,
  CONST_LOADING_SIGNIN,
  // Message types
  CONST_TYPE_SUCCESS,
  END_TIME_VALIDATION_ERROR,
  // Color
  CONST_COLOR_BUTTON,
  // Routes
  CONST_ROUTE_BASE,
  CONST_ROUTE_SIGN_IN,
  CONST_ROUTE_SIGN_UP,
  // Const
  CONST_LOGGED_USER,
  CONST_PLACE_ORDER,
  CONST_CHECKOUT,
  CONST_ADD_MORE_ITEMS,
  CONST_CONTINUE,
  CONST_ORDER_COD,
  CONST_ORDER_PAG,
  CONST_ORDER_STATUS,
  CONST_PAY_COD,
  CONST_PAY_ONLINE,
  // Page
  CONST_PAGE_CART,
  CONST_PAGE_CHECKOUT,
  // labels
  CONST_LABEL_REMOVE,
  CONST_LABEL_COD,
  CONST_LABEL_ONLINE_PAY,
  CONST_LABEL_PAY_AND_GET,
  CONST_LABEL_PAGE,
  CONST_LABEL_DATA,
  CONST_LABEL_USER_ROLE,
  CONST_LABEL_PRODUCT,
  CONST_LABEL_PRODUCTS,
  CONST_LABEL_FOOD,
  CONST_LABEL_FOODS,
  CONST_LABEL_VOUCHERS,
  // Payment
  CONST_STATUS_PAYMENT_COD,
  // Order status
  CONST_ORDER_STATUS_PAYMENT_PENDING,
  CONST_ORDER_STATUS_PAYMENT_FAILED,
  CONST_ORDER_STATUS_PAYMENT_COMPLETED,
  CONST_ORDER_STATUS_PAYMENT_COD,
  CONST_ORDER_STATUS_ORDER_PLACED,
  CONST_ORDER_STATUS_PREPARING,
  CONST_ORDER_STATUS_READY_TO_SERVE,
  CONST_ORDER_STATUS_ON_THE_WAY,
  CONST_ORDER_STATUS_DELIVERED,
  CONST_ORDER_STATUS_KEPT_IN_TABLE,
  CONST_ORDER_STATUS_TAKE_AWAY,
  CONST_ORDER_STATUS_ORDER_COMPLETED,
  CONST_ORDER_STATUS_ORDER_FAILED,
  CONST_ORDER_STATUS_ORDER_REQUESTED,
  CONST_ORDER_STATUS_ORDER_CANCELLED,
  CONST_ORDER_STATUS_RIDE_ASSIGNED,
  CONST_ORDER_STATUS_PICKED_UP,
  CONST_ORDER_STATUS_OUT_FOR_DELIVERY,
  // Payment status
  CONST_PAYMENT_STATUS_SUCCESS,
  // Timer
  fetchRecentOrderTimer,
  // Deliver order
  CONST_TAB_AWAITING,
  CONST_TAB_ASSIGNED,
  CONST_TAB_DELIVERED,
  // Condition
  CONST_SMALL_ORDER_FEE_ORDER_AMOUNT,
};
