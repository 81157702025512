import React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import {
  CONST_MODULE_ORDERS,
  CONST_ORDER_STATUS_ON_THE_WAY,
  CONST_ORDER_STATUS_ORDER_PLACED,
  CONST_ORDER_STATUS_ORDER_REQUESTED,
  CONST_ORDER_STATUS_PREPARING,
} from 'utils/constants';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { failedStatusData, failedStatusList } from './Orders';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import useAppContext from 'store/useAppContext';
import { useNavigate } from 'react-router-dom';

export const FailedStepperIcon = () => <CancelIcon sx={{ color: 'red' }} />;
export const StepperIcon = props => {
  const { completed = false, active = false, isFailed = false } = props ?? {};
  const notCompletedOrDefault =
    completed && !active ? (
      <CheckCircleIcon sx={{ color: 'green' }} />
    ) : (
      <CircleIcon sx={{ color: active ? 'green' : 'lightgrey' }} />
    );
  return isFailed ? <FailedStepperIcon /> : notCompletedOrDefault;
};

export default function OrderTracking({
  orderFirstItem,
  currentStatus,
  orderStatusToSelect,
  viewOrDownloadInvoice,
  orderSessionId,
  reportFetchingId,
  toggleCancelOrderConfirmModal,
}) {
  const {
    ordersState: { cancellationReasons },
  } = useAppContext();
  const navigate = useNavigate();
  const { latitude, longitude } = orderFirstItem;
  const isFailedItem = failedStatusData[currentStatus];
  const failedStatuses = isFailedItem ? failedStatusList(currentStatus) : '';
  const trackStatusList = isFailedItem ? failedStatuses : orderStatusToSelect;
  const currentStatusIndex = trackStatusList.findIndex(statusItem => statusItem.value === currentStatus);
  const canCancel = [
    CONST_ORDER_STATUS_ORDER_REQUESTED,
    CONST_ORDER_STATUS_ORDER_PLACED,
    CONST_ORDER_STATUS_PREPARING,
    // CONST_ORDER_STATUS_READY_TO_SERVE,
  ].includes(currentStatus);

  const getReasonLabel = reasonText => {
    const splitReason = reasonText.split('*');
    const [reason = '', remarks = ''] = splitReason ?? [];
    const itemInListItem = cancellationReasons.find(reasonItem => reasonItem.value === reason);
    return reason || remarks ? `${itemInListItem?.label || ''} ${reason && remarks ? ' - ' : ''} ${remarks ?? ''}` : '';
  };

  const reasonText = getReasonLabel(orderFirstItem?.comments);
  return (
    <CustomFlexRow>
      <Box sx={{ minWidth: '50%' }}>
        <Stepper activeStep={currentStatusIndex} orientation="vertical">
          {trackStatusList.map((step, index) => {
            return (
              <Step key={step.label} index={index}>
                <StepLabel
                  StepIconComponent={
                    isFailedItem && trackStatusList.length === index + 1 ? FailedStepperIcon : StepperIcon
                  }
                  // optional={<Typography variant="caption">Show time</Typography>}
                >
                  {step.label}
                </StepLabel>
                <StepContent spellCheck>
                  <Typography variant="caption">{step.description}</Typography>
                  {isFailedItem && orderFirstItem?.comments && reasonText && (
                    <CustomFlexRow sx={{ mt: 1 }}>
                      <b>Reason:</b>{' '}
                      <CustomTypography sx={{ pl: 1 }} variant="subtitle">
                        {reasonText}
                      </CustomTypography>
                    </CustomFlexRow>
                  )}
                  {canCancel && step.value === CONST_ORDER_STATUS_ORDER_REQUESTED && (
                    <Box sx={{ mt: 1 }}>
                      <CustomButton
                        colorSame
                        name="Cancel Order"
                        size="small"
                        color="error"
                        variant="outlined"
                        onClick={() => toggleCancelOrderConfirmModal(currentStatus, orderFirstItem?.orderItemId)}
                      />
                    </Box>
                  )}
                  {step.value === CONST_ORDER_STATUS_ON_THE_WAY && (
                    <Box sx={{ mt: 1 }}>
                      <CustomButton
                        size="small"
                        color="warning"
                        name="Track Order"
                        showLoader
                        onClick={() => navigate(`/track-order/${orderSessionId}/${latitude}/${longitude}`)}
                      />
                    </Box>
                  )}
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box sx={{ justifyContent: 'center', alignItems: 'center', minWidth: '50%' }}>
        <CustomButton
          name="Download Invoice"
          variant="outlined"
          onClick={viewOrDownloadInvoice}
          module={CONST_MODULE_ORDERS}
          showLoader
          passLoading={orderSessionId === reportFetchingId}
        />
      </Box>
    </CustomFlexRow>
  );
}
