import Box from '@mui/material/Box';
import React from 'react';

const CustomFlexRow = ({ index = 0, children, sx }) => (
  <Box key={index} sx={{ display: 'flex', flexDirection: 'row', ...sx }}>
    {children}
  </Box>
);

export default CustomFlexRow;
