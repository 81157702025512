// assets
import PlaylistAddCheckRoundedIcon from '@mui/icons-material/PlaylistAddCheckRounded';
import PlaylistRemoveRoundedIcon from '@mui/icons-material/PlaylistRemoveRounded';
import RoomServiceIcon from '@mui/icons-material/RoomService';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const orders = {
  id: 'admin-orders',
  title: 'Orders',
  type: 'group',
  children: [
    {
      id: 'admin-recent-orders',
      title: 'New Orders',
      type: 'item',
      url: '/admin/recent-orders',
      icon: () => <PlaylistAddCheckRoundedIcon sx={{ fontSize: 27 }} />,
      breadcrumbs: true,
    },
    {
      id: 'default',
      title: 'Delivery Orders',
      type: 'item',
      url: '/admin/delivery-orders',
      icon: () => <RoomServiceIcon sx={{ fontSize: 27 }} />,
      breadcrumbs: false,
    },
    {
      id: 'admin-failed-orders',
      title: 'Failed Orders',
      type: 'item',
      url: '/admin/failed-orders',
      icon: () => <PlaylistRemoveRoundedIcon sx={{ fontSize: 27 }} />,
      breadcrumbs: false,
    },
  ],
};

export default orders;
