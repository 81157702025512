import Pagination from '@mui/material/Pagination';

const PaginationComponent = ({ listData, page, handlePageChange, isFetching }) => {
  return (
    <Pagination
      // variant="outlined"
      color="secondary"
      count={listData.custTotalPage || listData.totalPages}
      defaultPage={page}
      page={page}
      showFirstButton
      showLastButton
      onChange={handlePageChange}
      disabled={isFetching}
      siblingCount={1}
    />
  );
};

export default PaginationComponent;
