const paymentsState = {
  // Data
  paymentsData: [], //getAll
  paymentsForm: {}, //Post/put
  paymentsOne: {}, //get
  page: 1,
  size: 10,
  //Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  //Form
  paymentsModalOpen: false,
  formState: {},
  // Additional
  parentDepartmentId: 0,
  // Loading
  createOrderPaymentPosting: false,
};
export default paymentsState;
