import { Box } from '@mui/material';
import { styled, keyframes } from '@mui/system';

const blink = keyframes`
  0%, 100% {
    border-color: transparent;
  }
  50% {
    border-color: yellow;
  }
`;

// Create a styled Box component with the blinking border
const BlinkCircle = styled(Box)(({ theme }) => ({
  width: '20px',
  height: '20px',
  borderRadius: '50%',
  backgroundColor: '#FF0000', // Set background color to white
  border: '3px solid transparent', // Initial border style
  animation: `${blink} 1s infinite`,
}));

export default BlinkCircle;
