import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import { Navigate } from 'react-router-dom';

// dashboard routing
const RiderOrdersTab = Loadable(lazy(() => import('views/pages/riders/RiderOrdersTab')));
// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const RiderRoutes = isAuthenticated => ({
  path: '/rider',
  element: isAuthenticated ? <MainLayout /> : <Navigate to="/auth/signin" />,
  children: [
    {
      path: 'delivery-orders',
      children: [
        {
          path: '',
          element: <RiderOrdersTab />,
        },
      ],
    },
  ],
});

export default RiderRoutes;
