import React, { useState } from 'react';
import 'views/pages/recent-orders/OrderCollapse.css';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { dateFormat, formAddress, getUserRole } from 'utils/commonFunc';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import { IconSoup } from '@tabler/icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import NumbersIcon from '@mui/icons-material/Numbers';
import {
  CONST_ORDER_STATUS_DELIVERED,
  CONST_ORDER_STATUS_ORDER_COMPLETED,
  CONST_ORDER_STATUS_PICKED_UP,
  CONST_ORDER_STATUS_RIDE_ASSIGNED,
  CONST_TAB_ASSIGNED,
  CONST_TAB_AWAITING,
  CONST_TAB_DELIVERED,
} from 'utils/constants';
import { deliveryStatusList, failedStatusData, deliveryTrackStatusData } from 'views/pages/clients/orders/Orders';
import { Paper } from '@mui/material';
import CustomSelect from 'ui-component/forms/CustomSelect';
import useAppContext from 'store/useAppContext';
// import CustomMuiSelect from 'ui-component/forms/CustomMuiSelect';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import InfoIcon from '@mui/icons-material/Info';
import OtherHousesIcon from '@mui/icons-material/OtherHousesOutlined';
import CustomTooltip from 'ui-component/CustomTooltip/CustomTooltip';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import CustomConfirmDialog from 'ui-component/CustomConfirmDialog/CustomConfirmDialog';

const getStatusLabel = passStatus => {
  const statusItem = deliveryStatusList.find(statusItem => statusItem.value === passStatus);
  const failedItemLabel = failedStatusData?.[passStatus] ? failedStatusData?.[passStatus]?.label : '';
  const trackItemLabel = deliveryTrackStatusData?.[passStatus] ? deliveryTrackStatusData?.[passStatus]?.label : '';
  return statusItem ? statusItem?.label : failedItemLabel || trackItemLabel || passStatus;
};

const RiderOrderCard = props => {
  const {
    orderSessionId,
    orderStatus,
    creationDateTime,
    assignRiderToOrder,
    assignRiderByAdminUpdating,
    assigingRiderOrderId,
    rideOrderId,
    riderUserName,
    tab,
    riderStatus,
    assignOrderByRiderUpdating,
    latitude,
    longitude,
    markOrderAsDelivered,
    updateStatusOrderIdForLoading,
  } = props;
  const {
    ordersState: { allRiders, allRidersFetching },
    setAppNotification,
  } = useAppContext();
  const navigate = useNavigate();
  const [manualConfirmOpen, setManualConfirmOpen] = useState(false);
  const { isRoleAdmin, isRoleRider, isRoleCustomer } = getUserRole();
  const isAwaiting = tab?.toUpperCase() === CONST_TAB_AWAITING;
  const isTabDelivered = tab?.toUpperCase() === CONST_TAB_DELIVERED;
  const isAssigned = tab?.toUpperCase() === CONST_TAB_ASSIGNED;
  const showStatus = isAssigned ? riderStatus : orderStatus;
  return (
    <Paper variant="outlined" sx={{ my: 1, px: 2, py: 1 }}>
      <Grid container direction="row" alignItems="center">
        <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={1.5}>
          <CustomFlexRow>
            <NumbersIcon fontSize="small" />
            <CustomTypography sx={{ mt: 0.2, ml: 1, fontWeight: 'bolder' }} variant="subtitle">
              {orderSessionId}
            </CustomTypography>
          </CustomFlexRow>
        </Grid>
        <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={3}>
          <CustomFlexRow>
            <IconSoup size="21" />
            <CustomTypography
              sx={{
                mt: 0.3,
                ml: 1,
                color: [CONST_ORDER_STATUS_ORDER_COMPLETED, CONST_ORDER_STATUS_DELIVERED].includes(showStatus)
                  ? 'green'
                  : failedStatusData[showStatus]
                  ? 'red'
                  : '#FF8C00',
                fontWeight: 'bolder',
                fontSize: 14,
              }}
              variant="body2"
              className={isRoleRider && riderStatus === CONST_ORDER_STATUS_RIDE_ASSIGNED ? 'blinking-status' : ''}>
              {getStatusLabel(showStatus)}
            </CustomTypography>
          </CustomFlexRow>
        </Grid>
        <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={2.5}>
          <CustomFlexRow>
            <AccessTimeIcon fontSize="small" />
            <CustomTypography sx={{ mt: 0.1, ml: 1 }} variant="subtitle">
              {dateFormat(creationDateTime, 'MMM dd, yyyy hh:mm a')}
            </CustomTypography>
          </CustomFlexRow>
        </Grid>
        <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={2}>
          <CustomFlexRow>
            <OtherHousesIcon fontSize="small" />
            <CustomTypography sx={{ mt: 0.2, ml: 1 }} variant="subtitle">
              {props.pinCode}
            </CustomTypography>
            <CustomTooltip content={formAddress(props)}>
              <InfoIcon sx={{ mt: 0.5, ml: 2, fontSize: 14 }} />
            </CustomTooltip>
          </CustomFlexRow>
        </Grid>
        <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={2.5}>
          {isRoleAdmin && isAwaiting && (
            <CustomSelect
              fieldLabel="Assign Rider"
              options={{
                placeholder: 'Select...',
                selectOptions: allRiders.map(rider => ({
                  label: rider.riderName,
                  value: rider.userName,
                })),
              }}
              variant="outlined"
              size="small"
              loading={allRidersFetching || assigingRiderOrderId === rideOrderId}
              onSelect={selectedRiderUserName => assignRiderToOrder?.(rideOrderId, selectedRiderUserName)}
              disabled={assignRiderByAdminUpdating}
            />
          )}
          {((isRoleAdmin && isAssigned) || isTabDelivered) && (
            <CustomFlexRow>
              <DeliveryDiningIcon />
              <CustomTypography sx={{ mt: 0.1, ml: 1 }} variant="subtitle">
                {riderUserName}
              </CustomTypography>
            </CustomFlexRow>
          )}
          {isRoleRider && riderStatus === CONST_ORDER_STATUS_RIDE_ASSIGNED && (
            <Box>
              <CustomButton
                size="small"
                variant="outlined"
                color="error"
                name="Pickup Order"
                passLoading={assigingRiderOrderId === rideOrderId}
                disabled={assignOrderByRiderUpdating}
                showLoader
                onClick={() => assignRiderToOrder?.(rideOrderId, riderUserName)}
              />
            </Box>
          )}
          {!isRoleCustomer && riderStatus === CONST_ORDER_STATUS_PICKED_UP && (
            <CustomFlexRow sx={{ mt: 1 }}>
              <Box>
                <CustomButton
                  variant="outlined"
                  size="small"
                  color="error"
                  name="Track Order"
                  onClick={() => {
                    if (latitude && longitude) {
                      navigate(`/track-order/${orderSessionId}/${latitude}/${longitude}`);
                    } else {
                      setAppNotification?.({
                        status: 500,
                        data: {
                          message: 'Warning',
                          details: ['No latitude and longitude found for this order.'],
                        },
                      });
                    }
                  }}
                />
              </Box>
              <Box sx={{ ml: 1 }}>
                <CustomButton
                  variant="outlined"
                  size="small"
                  color="info"
                  name="Mark as Delivered"
                  showLoader
                  passLoading={updateStatusOrderIdForLoading}
                  onClick={() => setManualConfirmOpen(true)}
                />
              </Box>
            </CustomFlexRow>
          )}
        </Grid>
      </Grid>
      <CustomConfirmDialog
        manualConfirmMessage="Are you sure you want to Mark Order as Delivered ?"
        manualConfirmButtonLabel="Yes"
        manualConfirmOpen={manualConfirmOpen}
        manualConfirmClick={() => {
          setManualConfirmOpen(false);
          markOrderAsDelivered?.(orderSessionId);
        }}
        manualCancelClick={() => setManualConfirmOpen(false)}
      />
    </Paper>
  );
};

export default RiderOrderCard;
