const dashboardsState = {
  // Data
  ordersData: {}, // getAll
  ordersForm: {}, // post/put
  ordersOne: {}, // get
  ordersView: {}, // view
  page: 1,
  size: 10,
  // Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  // Form
  ordersModalOpen: false,
  formState: {},
  orderTracks: [],
  getTracksLoading: false,
  // Main
  monthlyOrderCounts: [],
  getMonthlyOrderCountsFething: false,
  mostRatedProducts: [],
  getMostRatedProductsFetching: false,
  todayOrderCountData: {},
  getTodayOrderCountDataFetching: false,
  topSellingProducts: [],
  getTopSellingProductsFetching: false,
};

export default dashboardsState;
