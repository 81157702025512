import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import PaginationDOM from './PaginationDOM';

const CustomPagination = ({ listData, page, size, setState, isFetching, pageParamName, sizeParamName }) => {
  // setState is belongs to the module (e.g: setAuthState, setEmployeeState)
  const handlePageChange = (event, page) => {
    setContextState({ setState, paramName: pageParamName || 'page', paramValue: page });
  };

  const handleSizeChange = size => {
    setContextState({ setState, paramName: pageParamName || 'page', paramValue: 1 });
    setContextState({ setState, paramName: sizeParamName || 'size', paramValue: size });
  };

  return (
    !!(listData?.custTotalPage || listData?.totalPages) && (
      <PaginationDOM
        {...{ listData, page, size }}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        isFetching={isFetching}
      />
    )
  );
};

export default CustomPagination;
