import React, { useState } from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import DiscountIcon from '@mui/icons-material/Discount';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useAppContext from 'store/useAppContext';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { CONST_GET, CONST_MODULE_VOUCHERS } from 'utils/constants';
import { getLocalUser } from 'utils/commonFunc';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';

const module = CONST_MODULE_VOUCHERS;
const VoucherInput = () => {
  const {
    crudMethods,
    vouchersMethods: { setVouchersState },
    vouchersState: { voucherCode },
  } = useAppContext();
  const [isLoading, setLoading] = useState(false);

  const { getMethodByModule } = useStoreAccessByModule();

  const setVoucherContext = updateState => {
    if (Object.keys(updateState || {})?.length) {
      setContextState({
        setState: setVouchersState,
        paramValue: updateState,
      });
    }
  };

  const applyVoucher = async () => {
    const user = getLocalUser();
    setLoading(true);
    const res = await handleApiAction({
      crudMethods,
      setState: getMethodByModule({ module }),
      module,
      action: CONST_GET,
      apiPropName: 'applyVoucher',
      voucherCode,
      userName: user?.userId,
      hideError: true,
    });
    setLoading(false);
    if (res?.ok) {
      setVoucherContext({
        voucherDiscountType: res?.data?.discountType,
        voucherDiscount: res?.data?.discount,
      });
    }
  };

  return (
    <Box sx={{ px: 2, mb: 2 }}>
      <FormControl fullWidth variant="outlined">
        <OutlinedInput
          id="outlined-adornment-password"
          startAdornment={
            <InputAdornment position="end">
              <DiscountIcon />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <CustomButton
                type="button"
                name="Submit"
                edge="end"
                variant="link"
                style={{ color: 'orange' }}
                onClick={applyVoucher}
                disabled={!voucherCode || isLoading}
              />
            </InputAdornment>
          }
          placeholder="Enter Voucher Code"
          onChange={e => setVoucherContext({ voucherCode: e.target.value })}
          value={voucherCode}
          disabled={isLoading}
        />
      </FormControl>
    </Box>
  );
};

export default VoucherInput;
