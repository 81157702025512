import { useState, useEffect, useCallback, useRef } from 'react';
import SockJS from 'sockjs-client';
import { Client } from '@stomp/stompjs';

const MapSocketConnection = ({
  stompClient,
  onMessageReceived,
  isRider = false,
  userId,
  orderSessionId,
  currentLocation,
  sendMessageToWebSocket,
}) => {
  const [, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;
  const joinTopic = '/topic/restaurant-web-socket';

  const connectStomp = useCallback(() => {
    try {
      if (stompClient.current?.connected) {
        console.log('STOMP client is already connected');
        return;
      }

      const socket = new SockJS(`${process.env.REACT_APP_API_BASE_DOMAIN_URL}/ws`);
      stompClient.current = new Client({
        webSocketFactory: () => socket,
        reconnectDelay: 5000,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        onConnect: () => {
          console.log('Socket Connected');
          reconnectAttempts.current = 0;
          setError(null);
          setIsConnected(true);

          // Subscribe to the order-specific topic
          stompClient.current.subscribe(joinTopic, message => {
            try {
              const receivedMessage = JSON.parse(message.body);
              if (receivedMessage?.locationDto) {
                onMessageReceived?.(receivedMessage);
              }
            } catch (err) {
              console.error('Error parsing message:', err);
            }
          });

          // Send initial connection message after a small delay
          setTimeout(() => {
            if (stompClient.current?.connected) {
              sendMessageToWebSocket?.({
                type: 'JOIN',
                sender: userId,
                orderSessionId: orderSessionId,
                role: isRider ? 'RIDER' : 'CUSTOMER',
              });
            }
          }, 1000);
        },
        onDisconnect: () => {
          console.log('STOMP Disconnected');
          setIsConnected(false);
        },
        onStompError: frame => {
          console.error('STOMP protocol error:', frame);
          setError(`Protocol error: ${frame.headers['message']}`);
          setIsConnected(false);
        },
        onWebSocketError: event => {
          console.error('WebSocket error:', event);
          setError('Connection error. Please check your network connection.');
          setIsConnected(false);

          if (reconnectAttempts.current < maxReconnectAttempts) {
            const timeoutDuration = Math.min(1000 * Math.pow(2, reconnectAttempts.current), 10000);
            console.log(`Attempting to reconnect in ${timeoutDuration}ms...`);
            reconnectAttempts.current += 1;
            setTimeout(connectStomp, timeoutDuration);
          } else {
            setError('Maximum reconnection attempts reached. Please try connecting manually.');
          }
        },
      });

      stompClient.current.activate();
    } catch (err) {
      console.error('Error creating STOMP connection:', err);
      setError('Failed to create connection');
      setIsConnected(false);
    }
  }, [userId, orderSessionId, isRider, stompClient]);

  // Send location update if rider
  useEffect(() => {
    if (isRider && currentLocation && isConnected && stompClient.current?.connected) {
      sendMessageToWebSocket?.({
        type: 'GPS_LOC',
        sender: userId,
        locationDto: {
          latitude: currentLocation.lat,
          longitude: currentLocation.lng,
          imeiNo: '1',
        },
      });
    }
  }, [currentLocation, isRider, userId, isConnected, stompClient]);

  // Initialize connection
  useEffect(() => {
    connectStomp();
    return () => {
      if (stompClient.current?.connected) {
        stompClient.current.deactivate();
        setIsConnected(false);
      }
    };
  }, [connectStomp]);

  return null;
};

export default MapSocketConnection;
