import React from 'react';
import { Box, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
  },
}));

const RowColumnsDOM = props => {
  const classes = useStyles();
  const { isForm, listToLoop, getShowContent, md, sm = 6, xs = 12, className, style } = props;

  return (
    <Box className={`${classes.root} ${className}`} style={style}>
      <Grid container spacing={isForm ? 3 : 2}>
        {listToLoop?.map((loopItem = {}, i) => (
          <Grid
            item
            sm={loopItem?.sm || sm}
            xs={loopItem?.xs || xs}
            md={loopItem?.md || md}
            key={i}
            style={loopItem?.style || {}}>
            {getShowContent(loopItem)}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RowColumnsDOM;
