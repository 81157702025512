import { symbol } from 'prop-types';

const languagesForSelect = [
  { value: 'EN', label: 'English' },
  { value: 'TA', label: 'Tamil' },
  // { value: 'ML', label: 'Malayalam' },
  // { value: 'TE', label: 'Telugu' },
  // { value: 'KN', label: 'Kannada' },
  // { value: 'HI', label: 'Hindi' },
];
const departmentsForSelect = [
  { value: 1, label: 'new dep' },
  { value: 2, label: ' dep' },
  { value: 3, label: 'bha dep' },
];

const categoryTypesForSelect = [
  { value: 'RAW_FOOD_ITEM', label: 'Raw Food Item' },
  { value: 'COOK_FOOD_ITEM', label: 'Cook Food Item' },
];

const productTypesForSelect = [
  { value: 'VEG', label: 'Vegetarian' },
  { value: 'NON_VEG', label: 'Non-Vegetarian' },
];

const orderTypesForSelect = [
  { value: 'DELIVERY', label: 'Online Delivery Order' },
  { value: 'TAKE_AWAY', label: 'Take Away Order' },
  // { value: 'TABLE_ORDER', label: 'Table Order' },
];

const branchTabList = [
  { tabIndex: 0, tabTitle: 'Branches', query: 'branches' },
  { tabIndex: 1, tabTitle: 'Timings', query: 'timings' },
];

const weekDaysForSelect = [
  { label: 'Monday', value: 'MON' },
  { label: 'Tuesday', value: 'TUE' },
  { label: 'Wednesday', value: 'WED' },
  { label: 'Thursday', value: 'THU' },
  { label: 'Friday', value: 'FRI' },
  { label: 'Saturday', value: 'SAT' },
  { label: 'Sunday', value: 'SUN' },
];

const dayMapper = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
};

const orderCancelReasonsForSelect = [
  { label: 'Changed Mind', value: 'changed_mind' },
  { label: 'Item Unavailable', value: 'item_unavailable' },
  { label: 'Order Placed by Mistake', value: 'mistake' },
  { label: 'Delivery Time Too Long', value: 'long_delivery_time' },
  { label: 'Found a Better Deal Elsewhere', value: 'better_deal' },
  { label: 'Other', value: 'other' },
];

const voucherDiscountsForSelect = [
  { label: 'Percentage', value: 'PERCEN_DISC' },
  { label: 'Flat', value: 'FLAT_DISC' },
];

const voucherTypeForSelect = [
  { label: 'All User', value: 'ALL_CUST' },
  { label: 'Specific User', value: 'SPEC_CUST' },
];

export {
  languagesForSelect,
  departmentsForSelect,
  categoryTypesForSelect,
  productTypesForSelect,
  orderTypesForSelect,
  branchTabList,
  weekDaysForSelect,
  dayMapper,
  orderCancelReasonsForSelect,
  voucherDiscountsForSelect,
  voucherTypeForSelect,
};
