import React, { useState } from 'react';
import { getHeading } from '../carts/Carts';
import Paper from '@mui/material/Paper';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import CustomTwoInlineColumns from 'ui-component/CustomTwoInlineColumns/CustomTwoInlineColumns';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import Divider from '@mui/material/Divider';
import useAppContext from 'store/useAppContext';
import Box from '@mui/material/Box';
import { getLocalStorage } from 'utils/commonFunc';
import { CONST_LOCAL_STORAGE_USER_ADDRESS } from 'utils/constants';
import { useNavigate } from 'react-router-dom';

const UserAddress = () => {
  const [showUpdate] = useState(false);
  const userAddress = getLocalStorage(CONST_LOCAL_STORAGE_USER_ADDRESS);
  const navigate = useNavigate();

  const toggleCanUpdate = () => navigate('/address?source=checkout');

  return (
    <Paper sx={{ padding: 2, marginBottom: 1 }}>
      {getHeading('DELIVERY ADDRESS')}
      <Divider sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 1 }} />
      {!showUpdate && (
        <Paper sx={{ paddingTop: 2 }}>
          <CustomTwoInlineColumns>
            {userAddress?.addressId || userAddress?.pinCode ? (
              <>
                <CustomTypography>
                  <b>Deliver to: {[userAddress?.aliasName, userAddress?.addressLine].join(', ')}</b>
                </CustomTypography>
                <CustomTypography sx={{ paddingY: 1 }}>
                  {userAddress?.addressStreet} {[userAddress?.district, userAddress?.state].join(', ')}
                </CustomTypography>
                <CustomTypography>
                  <b>Pincode/Zipcode:</b> {userAddress.pinCode}
                </CustomTypography>
                <CustomTypography sx={{ paddingTop: 1 }}>
                  <b>Phone:</b> {userAddress.phoneNumber}
                </CustomTypography>
              </>
            ) : (
              <Box>No Address Found</Box>
            )}
            <CustomButton variant="outlined" name="UPDATE" onClick={toggleCanUpdate} />
          </CustomTwoInlineColumns>
        </Paper>
      )}
    </Paper>
  );
};

export default UserAddress;
