import React from 'react';
import { Card } from '@mui/material';

const CardDOM = props => {
  const { children, contentDOM, style, sx } = props;
  return (
    <Card elevation={1} sx={sx} style={style}>
      {children || contentDOM}
    </Card>
  );
};

export default CardDOM;
