import React from 'react';
import Grid from '@mui/material/Grid';

// eslint-disable-next-line no-lone-blocks
{
  // E.g:
  /* <CustomResGrid columnsizes={{ xs: 12, md: 6 }}>
  <Element1 />
  <Element2 />
</CustomResGrid>; */
}

const CustomResGrid = ({ children, sx }) => {
  return (
    <Grid container spacing={1} sx={sx}>
      {React.Children.map(children, (child, index) => {
        const columnsizes = child.props.columnsizes || { xs: 12, md: 6 }; // Use default if not specified
        return (
          <Grid item key={index} {...columnsizes}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CustomResGrid;
