import React, { useRef } from 'react';
import {
  CONST_FIELD_DATE_PICKER,
  CONST_FIELD_RADIO_GROUP,
  CONST_FIELD_SELECT,
  CONST_MODULE_DISCOUNTS,
  CONST_MODULE_VOUCHERS,
  CONST_TYPE_NUMBER,
} from 'utils/constants';
import FormBuilder from 'utils/formUtils/FormBuilder';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import CustomRowColumns from 'ui-component/CustomRowColumns/CustomRowColumns';
import CustomCard from 'ui-component/CustomCard/CustomCard';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import { voucherDiscountsForSelect, voucherTypeForSelect } from 'utils/variables';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';

// const custUserName = {
//   fieldName: 'custUserName',
//   fieldLabel: 'Customer Username',
//   fieldValue: '',
//   options: {
//     md: 6,
//     placeholder: 'Enter Customer Username',
//     isReq: true,
//     validationError: '',
//     seq: 2,
//   },
// };

const initialState = {
  voucherType: {
    fieldLabel: 'Voucher Type',
    fieldName: 'voucherType',
    fieldType: CONST_FIELD_RADIO_GROUP,
    fieldValue: 'ALL_CUST',
    options: {
      isReq: true,
      md: 12,
      selectOptions: voucherTypeForSelect,
      validationError: '',
      seq: 1,
      isNotField: true,
    },
  },
  discountType: {
    fieldName: 'discountType',
    fieldLabel: 'Discount Type',
    fieldValue: voucherDiscountsForSelect[0].value,
    options: {
      md: 6,
      placeholder: 'Select Discount Type',
      isReq: true,
      selectOptions: voucherDiscountsForSelect,
      validationError: '',
      seq: 4,
    },
    fieldType: CONST_FIELD_SELECT,
  },
  discount: {
    fieldName: 'discount',
    fieldLabel: 'Discount Value',
    fieldValue: 1,
    type: CONST_TYPE_NUMBER,
    options: {
      md: 6,
      placeholder: 'Enter Discount',
      isReq: true,
      validationError: '',
      seq: 5,
    },
  },
  voucherCode: {
    fieldName: 'voucherCode',
    fieldLabel: 'Voucher Code',
    fieldValue: '',
    options: {
      md: 6,
      placeholder: 'Enter Voucher Code',
      isReq: true,
      validationError: '',
      seq: 3,
      disableWhileEdit: true,
    },
  },
  usageLimit: {
    fieldName: 'usageLimit',
    fieldLabel: 'Usage Limit',
    fieldValue: 1,
    type: CONST_TYPE_NUMBER,
    options: {
      md: 6,
      placeholder: 'Enter Usage Limit',
      isReq: true,
      validationError: '',
      seq: 6,
    },
  },
  expiryDate: {
    fieldName: 'expiryDate',
    fieldLabel: 'Expiry Date',
    fieldValue: '',
    options: {
      md: 6,
      placeholder: 'Enter Expiry Date (yyyy-MM-dd)',
      isReq: true,
      validationError: '',
      seq: 7,
      minDate: new Date(),
    },
    fieldType: CONST_FIELD_DATE_PICKER,
  },
  offerDetails: {
    fieldName: 'offerDetails',
    fieldLabel: 'Description',
    fieldValue: '',
    options: {
      md: 6,
      placeholder: 'Enter Details',
      validationError: '',
      multiline: true,
      seq: 8,
    },
  },
};

const module = CONST_MODULE_VOUCHERS;
const VoucherForm = ({ postOrPut, idName, vouchersOne }) => {
  const formBuilderRef = useRef(null);
  const isEdit = vouchersOne?.[idName];
  const { getMethodByModule } = useStoreAccessByModule();

  const postOrPutHandle = async postData => {
    const res = await postOrPut(postData);
    if (res?.ok) {
      handleCancelUpdate?.();
    }
  };

  const handleSubmit = () => {
    formBuilderRef.current.handleSubmit();
  };

  const handleCancelUpdate = () => {
    formBuilderRef.current.resetForm();
    if (isEdit) {
      setContextState({
        setState: getMethodByModule({ module }),
        paramName: 'vouchersOne',
        paramValue: {},
      });
    }
  };

  return (
    <CustomRowColumns
      listToLoop={[
        { md: 2 },
        {
          element: (
            <CustomCard style={{ padding: 20 }}>
              <CustomRowColumns
                listToLoop={[
                  {
                    element: (
                      <FormBuilder
                        formBuilderRef={formBuilderRef}
                        initialState={initialState}
                        module={module}
                        onValidationSuccess={postOrPutHandle}
                        idName={idName}
                        itemOne={vouchersOne}
                        isSeqChanged={true}
                      />
                    ),
                    md: 9,
                  },
                  {
                    element: (
                      <CustomFlexRow>
                        <CustomButton
                          module={module}
                          handleClick={handleSubmit}
                          name="Add +"
                          showLoader
                          isActionPostOrPut
                          isEdit={!!isEdit}
                        />
                        <CustomButton
                          sx={{ ml: 1 }}
                          module={CONST_MODULE_DISCOUNTS}
                          handleClick={handleCancelUpdate}
                          name="Clear"
                          showLoader
                          color="inherit"
                        />
                      </CustomFlexRow>
                    ),
                    style: {
                      alignSelf: 'flex-end',
                      textAlign: 'right',
                    },
                    md: 3,
                  },
                ]}
              />
            </CustomCard>
          ),
          md: 10,
        },
      ]}
    />
  );
};
export default VoucherForm;
