import { LinearProgress } from '@mui/material';
import React from 'react';
import useAppContext from 'store/useAppContext';

const CustomLinearProgress = () => {
  const {
    employeesState: { getAllFetching: empGetAllFetching, getFetching: empGetFetching } = {},
    productsState: { getAllFetching: productGetAllFetching, getFetching: productGetFetching } = {},
    cartsState: { getAllFetching: cartGetAllFetching, getFetching: cartGetFetching, putting: cartUpdating } = {},
    ordersState: { deliveryOrdersFetching, completedOrdersFetching, allOrdersForClientFetching },
    categoriesState: { getAllFetching: ctgryGetAllFetching, getFetching: ctgryGetFetching },
    discountsState: { getAllFetching: dscntGetAllFetching, getFetching: dscntGetFetching },
    vouchersState: { getAllFetching: vouGetAllFetching, getFetching: vouGetFetching },
  } = useAppContext();

  const loaders = [
    empGetAllFetching,
    empGetFetching,
    productGetAllFetching,
    productGetFetching,
    cartGetAllFetching,
    cartGetFetching,
    cartUpdating,
    deliveryOrdersFetching,
    completedOrdersFetching,
    allOrdersForClientFetching,
    ctgryGetAllFetching,
    ctgryGetFetching,
    dscntGetAllFetching,
    dscntGetFetching,
    vouGetAllFetching,
    vouGetFetching,
  ];

  const isLoading = loaders.includes(true);

  return isLoading && <LinearProgress />;
};

export default CustomLinearProgress;
