const appState = {
  isMoreMenuOpen: null,
  isConfirmModalOpen: false,
  message: '',
  handleAppConfirmModal: null,
  // isManual
  isManual: false,
  manualConfirmButtonLabel: '',
  manualPassLoading: false,
  manualOnConfirm: undefined,
};

export default appState;
