import React, { useRef, useState, useEffect } from 'react';
import { CONST_MODULE_ADDRESSES, CONST_TYPE_NUMBER } from 'utils/constants';
import FormBuilder from 'utils/formUtils/FormBuilder';
import CustomSlideDialog from 'ui-component/CustomSlideDialog/CustomSlideDialog';
import AddressMapSelect from './AddressMapSelect';
import CustomDraggableDialog from 'ui-component/CustomDraggableDialog/CustomDraggableDialog';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import { Box } from '@mui/material';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CustomErrorText from 'ui-component/forms/CustomErrorText';

const initialState = {
  aliasName: {
    fieldName: 'aliasName',
    fieldLabel: 'Name',
    fieldValue: '',
    options: {
      md: 6,
      placeholder: 'Enter Name',
      isReq: true,
      validationError: '',
    },
  },
  addressLine: {
    fieldName: 'addressLine',
    fieldLabel: 'Address line 1',
    fieldValue: '',
    options: {
      md: 6,
      isReq: true,
      placeholder: 'Door No / Building Name',
      validationError: '',
    },
  },
  addressStreet: {
    fieldName: 'addressStreet',
    fieldLabel: 'Address line 2',
    fieldValue: '',
    options: {
      md: 12,
      placeholder: 'Street, Home town',
      validationError: '',
    },
  },
  district: {
    fieldName: 'district',
    fieldLabel: 'City',
    fieldValue: '',
    options: {
      md: 6,
      placeholder: 'Enter City',
      validationError: '',
    },
  },
  state: {
    fieldName: 'state',
    fieldLabel: 'State/Province',
    fieldValue: '',
    options: {
      md: 6,
      placeholder: 'Enter State Or Province',
      validationError: '',
    },
  },
  pinCode: {
    fieldName: 'pinCode',
    fieldLabel: 'Postal code',
    fieldValue: '',
    type: CONST_TYPE_NUMBER,
    options: {
      md: 6,
      placeholder: 'Enter Pincode',
      isReq: true,
      validationError: '',
    },
  },
  phoneNumber: {
    fieldName: 'phoneNumber',
    fieldLabel: 'Phone number',
    fieldValue: '',
    type: CONST_TYPE_NUMBER,
    options: {
      md: 6,
      placeholder: 'Phone number',
      isReq: true,
      validationError: '',
    },
  },
  addressId: {
    fieldName: 'addressId',
    fieldValue: 0,
    options: { isNotField: true },
  },
  latitude: {
    fieldName: 'latitude',
    fieldValue: 0,
    options: { isNotField: true },
  },
  longitude: {
    fieldName: 'longitude',
    fieldValue: 0,
    options: { isNotField: true },
  },
};

const module = CONST_MODULE_ADDRESSES;
const AddressForm = ({ idName, postOrPut, branchsOne }) => {
  const formBuilderRef = useRef(null);
  const addressMapRef = useRef(null);

  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(false);
  // Draggable Map Modal State
  const [isDraggableModalOpen, setDraggableModalOpen] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [locationError, setLocationError] = useState(null);

  const toggleDraggableModal = () => setDraggableModalOpen(prevState => !prevState);

  useEffect(() => {
    if (location?.latitude && location?.longitude) {
      initialState.latitude.fieldValue = location?.latitude;
      initialState.longitude.fieldValue = location?.longitude;
    }
  }, [location]);

  const resetForm = () => {
    formBuilderRef.current.resetForm?.();
    setLocation(null);
    setLoading(false);
    setLocationError(null);
  };

  const postOrPutHandle = async postData => {
    setLocationError(null);
    setLoading(true);
    if (location?.latitude && location?.longitude) {
      postData = {
        ...postData,
        latitude: location?.latitude,
        longitude: location?.longitude,
      };
      const res = await postOrPut(postData);
      setLoading(false);
      res?.ok && resetForm();
    } else {
      setLocationError('Location is mandatory');
      setLoading(false);
    }
  };

  const handleSubmit = () => formBuilderRef.current?.handleSubmit?.();

  const isEdit = branchsOne?.[idName];

  if (!navigator.geolocation) {
    console.info('Geolocation is not supported by your browser');
  }

  const setSelectedLocation = () => {
    if (markerPosition.lat && markerPosition.lng) {
      setLocation({ latitude: markerPosition.lat, longitude: markerPosition.lng });
      setLocationError(null);
    }
    toggleDraggableModal();
  };

  const clearSelectedLocation = () => {
    setMarkerPosition(null);
    setSelectedPlace(null);
    setLocationError(null);
    toggleDraggableModal();
  };

  return (
    <>
      <CustomSlideDialog
        isForm
        dialogHeader="Address"
        module={module}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        resetForm={resetForm}
        loading={loading}>
        <div>
          <FormBuilder
            formBuilderRef={formBuilderRef}
            initialState={initialState}
            module={module}
            onValidationSuccess={postOrPutHandle}
            idName={idName}
            itemOne={branchsOne}
          />
          <br />
          <CustomFlexRow>
            <CustomButton color="warning" name="Select Location on Map" onClick={toggleDraggableModal} />
          </CustomFlexRow>
          {selectedPlace?.formatted_address && (
            <CustomFlexRow sx={{ py: 2 }}>
              <PersonPinCircleIcon fontSize="large" style={{ fontSize: 20, color: '#ea4336' }} />
              <Box sx={{ ml: 1 }}>{selectedPlace?.formatted_address}</Box>
            </CustomFlexRow>
          )}
          {!!locationError && <CustomErrorText>{locationError}</CustomErrorText>}
        </div>
      </CustomSlideDialog>
      <CustomDraggableDialog
        title="Choose location on Map"
        isDraggableModalOpen={isDraggableModalOpen}
        toggleDraggableModal={toggleDraggableModal}
        markerPosition={markerPosition}
        setSelectedLocation={setSelectedLocation}
        clearSelectedLocation={clearSelectedLocation}>
        <AddressMapSelect
          addressMapRef={addressMapRef}
          selectedPlace={selectedPlace}
          setSelectedPlace={setSelectedPlace}
          markerPosition={markerPosition}
          setMarkerPosition={setMarkerPosition}
          userLocation={userLocation}
          setUserLocation={setUserLocation}
          isInfoWindowOpen={isInfoWindowOpen}
          setIsInfoWindowOpen={setIsInfoWindowOpen}
        />
      </CustomDraggableDialog>
    </>
  );
};

export default AddressForm;
