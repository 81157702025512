import React, { useEffect } from 'react';
import { APIProvider, Map, InfoWindow, AdvancedMarker } from '@vis.gl/react-google-maps';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import CustomRightButton from 'ui-component/CustomRightButton';

const AddressMapSelect = ({
  selectedPlace,
  setSelectedPlace,
  markerPosition,
  setMarkerPosition,
  userLocation,
  setUserLocation,
  isInfoWindowOpen,
  setIsInfoWindowOpen,
}) => {
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const { latitude, longitude } = position.coords;
        setUserLocation({ lat: latitude, lng: longitude });
      },
      error => {
        console.error('Error getting user location:', error);
      }
    );
  }, []);

  const fetchAddress = (lat, lng) => {
    const geocoder = new window.google.maps.Geocoder();
    const latLng = { lat, lng };

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === 'OK' && results[0]) {
        setSelectedPlace(results[0]);
      } else {
        console.error('Geocoder failed due to: ' + status);
      }
    });
  };

  const handleMapClick = event => {
    const lat = event?.detail?.latLng.lat;
    const lng = event?.detail?.latLng.lng;
    setMarkerPosition({ lat, lng });
    fetchAddress(lat, lng);
  };

  const getLocation = () => {
    navigator.geolocation.getCurrentPosition(
      position => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setMarkerPosition({ lat: latitude, lng: longitude });
      },
      error => {
        console.error(error);
      }
    );
  };

  return (
    <div style={{ height: '100vh', width: '100%' }}>
      <CustomRightButton name="Use Current Location" onClick={getLocation} />
      <APIProvider
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
        libraries={['maps', 'marker', 'places', 'routes', 'streetView']}>
        <Map
          defaultCenter={userLocation || { lat: 0, lng: 0 }}
          defaultZoom={13}
          style={{ width: '100%', height: '100%' }}
          onClick={handleMapClick}
          mapId="ADDR_MAP_ID">
          {markerPosition && (
            <AdvancedMarker position={markerPosition} onClick={() => setIsInfoWindowOpen(true)}>
              <PersonPinCircleIcon fontSize="large" style={{ fontSize: 50, color: '#ea4336' }} />
              {isInfoWindowOpen && (
                <InfoWindow position={markerPosition} onCloseClick={() => setIsInfoWindowOpen(false)}>
                  <div>{selectedPlace?.formatted_address || 'Selected Location'}</div>
                </InfoWindow>
              )}
            </AdvancedMarker>
          )}
        </Map>
      </APIProvider>
    </div>
  );
};

export default AddressMapSelect;
