import React from 'react';
import { Box, Button } from '@mui/material';

const rightAlign = { display: 'flex', justifyContent: 'flex-end', mb: 2 };

const CustomRightButton = ({ module, action, handleClick, onClick, label, color, name }) => {
  return (
    <Box sx={rightAlign}>
      <Button variant="contained" color={color || 'primary'} onClick={onClick || handleClick || undefined}>
        {label || name || '+ New'}
      </Button>
    </Box>
  );
};

export default CustomRightButton;
