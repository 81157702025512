import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { FormHelperText } from '@mui/material';

export default function CustomRadioGroup({
  module,
  fieldLabel = '',
  fieldValue,
  fieldName,
  options: { isReq, validations = [], validationError = '', selectOptions, radioFontSize = 0 },
  handleChange,
  onChange,
  onSelect,
}) {
  return (
    <FormControl error={!!validationError} required={isReq}>
      <FormLabel id={`${module}_${fieldName}`} style={{ fontSize: 11 }}>
        {fieldLabel}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={fieldValue}
        onChange={e => {
          if (typeof handleChange === 'function') {
            handleChange(e.target.value, fieldName);
          }
          if (typeof onChange === 'function') {
            onChange(e.target.value, fieldName);
          }
          if (typeof onSelect === 'function') {
            onSelect(e.target.value, fieldName);
          }
        }}>
        {selectOptions?.map(el => (
          <FormControlLabel
            key={el.value}
            value={el?.value}
            control={
              <Radio
                // required={isReq}
                sx={{
                  '& .MuiSvgIcon-root': {
                    fontSize: radioFontSize || 14,
                  },
                }}
              />
            }
            label={el?.label}
          />
        ))}
      </RadioGroup>
      {!!validationError && <FormHelperText>{validationError}</FormHelperText>}
    </FormControl>
  );
}
