const vouchersState = {
  // Data
  vouchersData: [], //getAll
  vouchersForm: {}, //Post/put
  vouchersOne: {}, //get
  page: 1,
  size: 10,
  //Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  //Form
  vouchersModalOpen: false,
  formState: {},
  // Additional
  parentDepartmentId: 0,
  // Special-data
  deliveryFee: 1.5,
  serviceFee: 1,
  smallOrderFee: 0.5,
  cutleryReq: false,
  specialComments: '',
  voucherCode: '',
  voucherDiscountType: '',
  voucherDiscount: 0,
};
export default vouchersState;
