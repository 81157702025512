import { getUserRole } from 'utils/commonFunc';
import clientOrders from './client-menu-items/orders';
import clientProducts from './client-menu-items/products';
import dashboard from './dashboard';
// import employees from './employees';
import products from './products';
import orders from './orders';
import branch from './branch';
import deliveryOrders from './riders-menu-items/deliveryOrders';
// import adminDeliveryOrders from './deliveryOrders';

// ==============================|| MENU ITEMS ||============================== //

const AppMenuItems = () => {
  const { isRoleAdmin, isRoleCustomer, isRoleRider } = getUserRole();
  const menuItems = [dashboard, products, orders, branch];
  const clientMenuItems = [clientProducts, clientOrders, branch];
  const riderMenuItems = [deliveryOrders, branch];
  const items = isRoleAdmin ? menuItems : isRoleCustomer ? clientMenuItems : isRoleRider ? riderMenuItems : [];
  return {
    items,
  };
};

export default AppMenuItems;
