// assets
import DashboardIcon from '@mui/icons-material/Dashboard';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const branch = {
  id: 'branch',
  title: 'Branch',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Branch Timings',
      type: 'item',
      url: '/branch-info',
      icon: DashboardIcon,
      breadcrumbs: false,
    },
  ],
};

export default branch;
