export const initialLoggedUser = {
  userName: '',
  userEmail: '',
  userId: '',
  userToken: '',
  userRole: '',
  orgId: '',
};

const authState = {
  isAuthenticated: false,
  user: { userName: '', password: '' },
  isLoginLoading: false,
  loggedUser: initialLoggedUser,
  isSignupLoading: false,
};

export default authState;
