import React from 'react';
import { Delete, Edit } from '@mui/icons-material';
import { CONST_DELETE, CONST_GET } from 'utils/constants';

const getTableEditDeleteDomList = ({ getItem, getFetching, deleteItem, deleting, addonList = [] }) => {
  const tableMenuList = [
    ...(getItem
      ? [
          {
            action: CONST_GET,
            icon: <Edit sx={{ pr: 1 }} />,
            label: 'Edit',
            handleMenuClick: () => {
              getItem?.();
            },
            isLoading: getFetching,
          },
        ]
      : []),
    {
      action: CONST_DELETE,
      icon: <Delete sx={{ pr: 1 }} />,
      label: 'Delete',
      handleMenuClick: () => {
        deleteItem?.();
      },
      isLoading: deleting,
    },
    ...(addonList || []),
  ];
  return tableMenuList;
};

export { getTableEditDeleteDomList };
