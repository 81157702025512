import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function CustomTabs({ tabsList }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box
        sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper', borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example">
          {tabsList.map((tab, i) => {
            return (
              <Tab
                key={i}
                label={tab.label.toUpperCase()}
                {...a11yProps(i)}
                sx={{ fontSize: { xs: '12px', sm: '12px', md: '13px' } }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box>
        {tabsList.map((tab, i) => {
          return (
            <TabPanel key={i} value={value} index={i}>
              {tab?.element || `No ${tab.label} Found.`}
            </TabPanel>
          );
        })}
      </Box>
    </>
  );
}
