import React, { useState } from 'react';
import { Grid, CardContent, Card, Box } from '@mui/material';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import { downloadPdf, formatCurrency, isUserAdmin } from 'utils/commonFunc';
import { CustomProductImage } from '../view-product/ViewProduct';
import { useNavigate } from 'react-router-dom';
import NotFound from 'views/pages/notfound';
import {
  CONST_ORDER_COD,
  CONST_ORDER_PAG,
  CONST_ORDER_STATUS_ORDER_PLACED,
  CONST_ORDER_STATUS_PREPARING,
  CONST_ORDER_STATUS_READY_TO_SERVE,
  CONST_ORDER_STATUS_ON_THE_WAY,
  CONST_ORDER_STATUS_DELIVERED,
  CONST_ORDER_STATUS_TAKE_AWAY,
  CONST_ORDER_STATUS_ORDER_COMPLETED,
  CONST_GET,
  CONST_MODULE_PRODUCTS,
  CONST_MODULE_ORDERS,
  CONST_POST,
  CONST_ORDER_STATUS_ORDER_REQUESTED,
  CONST_ORDER_STATUS_PAYMENT_FAILED,
  CONST_ORDER_STATUS_ORDER_CANCELLED,
  CONST_ORDER_STATUS_ORDER_FAILED,
  CONST_ORDER_STATUS_PAYMENT_PENDING,
  CONST_APP_CONFIRM_MODAL,
  CONST_ORDER_STATUS_RIDE_ASSIGNED,
  CONST_ORDER_STATUS_OUT_FOR_DELIVERY,
  CONST_ORDER_STATUS_PICKED_UP,
} from 'utils/constants';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import CheckBox from '@mui/icons-material/CheckBox';
import OrderTracking, { StepperIcon } from './OrderTracking';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useAppContext from 'store/useAppContext';
import CustomTooltip from 'ui-component/CustomTooltip/CustomTooltip';
import { LoadingCard } from 'ui-component/CustomLoader/CustomLoader';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import CustomSelect from 'ui-component/forms/CustomSelect';

export const commonStatus = [
  {
    value: CONST_ORDER_STATUS_ORDER_REQUESTED,
    label: 'Order Requested',
    description: `Order request has been made, Lets wait for admin to approve`,
  },
  {
    value: CONST_ORDER_STATUS_ORDER_PLACED,
    label: 'Order placed',
    description: `Your order has been placed, Please wait`,
  },
  { value: CONST_ORDER_STATUS_PREPARING, label: 'Preparing', description: `Your order is getting prepared` },
  {
    value: CONST_ORDER_STATUS_READY_TO_SERVE,
    label: 'Ready to serve',
    description: `Your order has been prepared and its ready to serve now, Thanks for your patience`,
  },
];

export const deliveryTrackStatusData = {
  [CONST_ORDER_STATUS_RIDE_ASSIGNED]: {
    value: CONST_ORDER_STATUS_RIDE_ASSIGNED,
    label: 'Assigned for Delivery',
    description: `Your order has been assigned to a delivery person, Your order will be reached soon`,
  },
  [CONST_ORDER_STATUS_PICKED_UP]: {
    value: CONST_ORDER_STATUS_PICKED_UP,
    label: 'Out for Delivery',
    description: `Your order is picked up from restaurant and it's out for delivery now`,
  },
  [CONST_ORDER_STATUS_OUT_FOR_DELIVERY]: {
    value: CONST_ORDER_STATUS_OUT_FOR_DELIVERY,
    label: 'Out for Delivery',
    description: `Your order is out for delivery now`,
  },
};

export const finalCommonStatus = {
  value: CONST_ORDER_STATUS_ORDER_COMPLETED,
  label: 'Order completed',
  description: `Order has been delivered and completed`,
  disabled: true,
};

export const deliveryStatusList = [
  ...commonStatus,
  { value: CONST_ORDER_STATUS_ON_THE_WAY, label: 'On the way', description: `Your order on its own way` },
  { value: CONST_ORDER_STATUS_DELIVERED, label: 'Delivered', description: `Your order has delivered, Thanks` },
  // finalCommonStatus,
];

export const failedStatusData = {
  [CONST_ORDER_STATUS_PAYMENT_PENDING]: {
    value: CONST_ORDER_STATUS_PAYMENT_PENDING,
    label: 'Payment pending',
    description: 'Order payment not yet completed, Please make a payment or Contact admin',
  },
  [CONST_ORDER_STATUS_PAYMENT_FAILED]: {
    value: CONST_ORDER_STATUS_PAYMENT_FAILED,
    label: 'Payment failed',
    description: 'Order payment has failed, Please try again or Contact admin',
  },
  [CONST_ORDER_STATUS_ORDER_FAILED]: {
    value: CONST_ORDER_STATUS_ORDER_FAILED,
    label: 'Order failed',
    description: 'We were not able to place your order. Please try again or Contact admin',
  },
  [CONST_ORDER_STATUS_ORDER_CANCELLED]: {
    value: CONST_ORDER_STATUS_ORDER_CANCELLED,
    label: 'Order cancelled',
    description: 'Order has been cancelled',
  },
};

export const failedStatusList = failedStatus => [
  {
    value: CONST_ORDER_STATUS_ORDER_REQUESTED,
    label: 'Order Requested',
    description: `Order request has been made, Lets wait for admin to approve`,
  },
  ...(failedStatusData[failedStatus] ? [failedStatusData[failedStatus]] : []),
];

export const takeAwayStatusList = [
  ...commonStatus,
  {
    value: CONST_ORDER_STATUS_READY_TO_SERVE,
    label: 'Ready to serve',
    description: `Your order has been prepared and its ready to serve now, Thanks for your patience`,
  },
  {
    value: CONST_ORDER_STATUS_TAKE_AWAY,
    label: 'Take away',
    description: `Its a take away order, Please wait and get it`,
  },
  // finalCommonStatus,
];

const module = CONST_MODULE_ORDERS;
const Orders = ({
  orderType,
  orderSessionId,
  orderItems,
  updateOrderStatusCb,
  orderItemsFetchingSessionId,
  /** Order manual approve **/
  orderManualApproveModalData,
  // assign
  assigingRiderOrderId,
  assignRiderToOrder,
  rideOrderId,
  // rider-name
  riderName = '',
  riderUserName = '',
}) => {
  const isRoleAdmin = isUserAdmin();
  const navigate = useNavigate();
  const {
    crudMethods,
    productsMethods: { setProductsState = undefined } = {},
    ordersState: {
      deliveryOrderItemsFetching,
      completedOrderItemsFetching,
      allOrdersItemsForClientFetching,
      failedOrderItemsFetching,
      allRidersFetching,
      allRiders,
      assignRiderByAdminUpdating,
    },
    appMethods: { setAppState },
    setAppNotification,
  } = useAppContext();
  const [reportFetchingId, setReportFetchingId] = useState(null);
  const [loadingItem, setStatusItemLoading] = useState({ trackStatus: '', orderItemId: 0 });
  const { getMethodByModule } = useStoreAccessByModule();

  const isDelivery = orderType === CONST_ORDER_COD;
  const isTakeAway = orderType === CONST_ORDER_PAG;

  const updateOrderStatus = async (trackStatus, orderItemId) => {
    setStatusItemLoading({ trackStatus, orderItemId });
    await updateOrderStatusCb({ trackStatus, orderItemId, orderType, orderSessionId });
    setStatusItemLoading({ trackStatus: '', orderItemId: 0 });
  };

  const viewOrder = async getId => {
    const prodModule = CONST_MODULE_PRODUCTS;
    await handleApiAction({
      crudMethods,
      setState: setProductsState,
      module: prodModule,
      action: CONST_GET,
      getId,
      idName: 'productId',
      stateParam: 'productsView',
    });
    navigate(`/products/view-product/${getId}`);
  };

  const viewOrDownloadInvoice = async orderItemData => {
    setReportFetchingId(orderItemData?.orderSessionId);
    const res = await handleApiAction({
      crudMethods,
      module,
      action: CONST_POST,
      apiPropName: 'getOrderBillReport',
      orderSessionId: orderItemData?.orderSessionId,
      headerAccept: 'application/pdf',
      loadingParam: 'orderBillReportFetching',
    });
    setReportFetchingId(null);
    downloadPdf({ data: res.data, name: `order_invoice_${orderItemData.orderSessionId}` });
  };

  const toggleCancelOrderConfirmModal = (orderStatus, orderItemId) => {
    setContextState({
      setState: getMethodByModule({ module }),
      paramName: '',
      paramValue: {
        cancelOrderConfirmModalOpen: true,
        acceptOrderRejectOrderSessionId: orderSessionId,
        acceptOrderRejectOrderStatus: orderStatus,
        acceptOrderRejectOrderItemId: orderItemId,
        acceptOrderRejectOrderType: orderType,
      },
      isFullObjectUpdate: true,
    });
  };

  const handleApproveModalOpen = orderStatus => {
    if (orderManualApproveModalData.isManual) {
      setContextState({
        setState: setAppState,
        paramName: '',
        paramValue: {
          [CONST_APP_CONFIRM_MODAL]: true,
          message: 'Are you sure want to Accept?',
          ...orderManualApproveModalData,
        },
        isFullObjectUpdate: true,
      });
    }
    setContextState({
      setState: getMethodByModule({ module }),
      paramName: '',
      paramValue: {
        acceptOrderRejectOrderSessionId: orderSessionId,
        acceptOrderRejectOrderStatus: orderStatus,
      },
    });
  };

  const passRiderName = riderName || riderUserName;
  const getRiderSelectionSection = (status, isSelectedCurrentStatus) => {
    if (status === CONST_ORDER_STATUS_READY_TO_SERVE && rideOrderId !== 0 && isSelectedCurrentStatus) {
      if (passRiderName) {
        return (
          <Box sx={{ ml: 1, mt: 2 }}>
            <b>Assigned:</b> {passRiderName}
          </Box>
        );
      }
      return (
        <Box sx={{ ml: 1, mt: 2, width: 200 }}>
          <CustomSelect
            fieldLabel="Assign Rider"
            options={{
              placeholder: 'Select...',
              selectOptions: allRiders.map(rider => ({
                label: rider.riderName,
                value: rider.userName,
              })),
            }}
            variant="outlined"
            size="small"
            loading={allRidersFetching || assigingRiderOrderId === rideOrderId}
            onSelect={selectedRiderUserName => rideOrderId && assignRiderToOrder?.(rideOrderId, selectedRiderUserName)}
            disabled={assignRiderByAdminUpdating}
          />
        </Box>
      );
    }
  };

  const takeAwayOrder = isTakeAway ? takeAwayStatusList : [];
  const orderStatusToSelect = isDelivery ? deliveryStatusList : takeAwayOrder;
  const getStatusListForAdminToUpdate = orderFirstItem => {
    const { orderStatus: currentStatus, orderItemId, latitude, longitude } = orderFirstItem;
    const currentStatusIndex = orderStatusToSelect.findIndex(statusItem => statusItem.value === currentStatus);
    const isDisabled = currentStatus === CONST_ORDER_STATUS_ORDER_REQUESTED;
    return (
      <Box>
        {orderStatusToSelect.map(({ value: status, label, disabled }, i) => {
          const isSelectedCurrentStatus = status === currentStatus;
          const isAlreayMarked = i <= currentStatusIndex;
          const currentlyUpdating = loadingItem.trackStatus === status && loadingItem.orderItemId === orderItemId;
          const isLastItem = i + 1 === orderStatusToSelect.length;
          const showAcceptCancelSection = isSelectedCurrentStatus && status === CONST_ORDER_STATUS_ORDER_REQUESTED;
          const showTrackLink = isSelectedCurrentStatus && status === CONST_ORDER_STATUS_ON_THE_WAY;

          return (
            <Box sx={{ marginBottom: 1 }} key={status}>
              <CustomFlexRow>
                <Box sx={{ mt: 0.5 }}>
                  <StepperIcon completed={isAlreayMarked} active={isSelectedCurrentStatus} />
                </Box>
                <Box>
                  {/* Update Order status */}
                  <CustomTooltip content={!isAlreayMarked ? `Mark as ${label}` : ''}>
                    <CustomButton
                      sx={{
                        ml: 1.5,
                        marginRight: 1,
                        ...(isSelectedCurrentStatus && { backgroundColor: 'aliceblue' }),
                      }}
                      variant="outlined"
                      size="small"
                      name={label}
                      onClick={() => !loadingItem?.trackStatus && updateOrderStatus(status, orderItemId)}
                      startIcon={isSelectedCurrentStatus && <CheckBox fontSize="small" />}
                      disabled={isAlreayMarked || currentlyUpdating || isDisabled}
                      showLoader
                      passLoading={currentlyUpdating}
                    />
                  </CustomTooltip>
                  {getRiderSelectionSection(status, isSelectedCurrentStatus)}
                  {/* Accept or Cancel Order */}
                  {showAcceptCancelSection && (
                    <Box sx={{ m: 1.5 }}>
                      <CustomFlexRow>
                        <CustomButton
                          colorSame
                          sx={{ mr: 1 }}
                          name="Accept Order"
                          size="small"
                          variant="outlined"
                          onClick={() => handleApproveModalOpen(status)}
                        />
                        <CustomButton
                          colorSame
                          name="Cancel Order"
                          size="small"
                          color="error"
                          variant="outlined"
                          onClick={() => toggleCancelOrderConfirmModal(status, orderItemId)}
                        />
                      </CustomFlexRow>
                    </Box>
                  )}
                  {showTrackLink && (
                    <CustomFlexRow sx={{ ml: 1, mt: 2, alignItems: 'center' }}>
                      {passRiderName && (
                        <Box>
                          <b>Assigned:</b> {passRiderName}
                        </Box>
                      )}
                      <CustomButton
                        sx={{ ml: 2, textDecoration: 'underline' }}
                        variant="text"
                        size="small"
                        color="error"
                        name="Track Order"
                        onClick={() => {
                          if (latitude && longitude) {
                            navigate(`/track-order/${orderSessionId}/${latitude}/${longitude}`);
                          } else {
                            setAppNotification?.({
                              status: 500,
                              data: {
                                message: 'Warning',
                                details: ['No latitude and longitude found for this order.'],
                              },
                            });
                          }
                        }}
                      />
                    </CustomFlexRow>
                  )}
                </Box>
              </CustomFlexRow>
              {!isLastItem && (
                <Box
                  sx={{
                    ml: 1.4,
                    height: showAcceptCancelSection ? 40 : 30, // Ensures full height within the parent Box
                    backgroundColor: currentStatusIndex > i ? 'green' : 'lightgrey', // Set desired line color
                    border: 'none', // Remove default border
                  }}
                  style={{ width: 2 }}
                />
              )}
            </Box>
          );
        })}
      </Box>
    );
  };

  const ordersItemsLen = orderItems?.length;
  const orderFirstItem = ordersItemsLen ? orderItems?.[0] : {};
  const renderOrdersItemsOrNotFoundSection = () => {
    return ordersItemsLen > 0 ? (
      <>
        {orderItems?.map((orderItem, i) => {
          const { orderItemId, productId, productName, description, quantity, priceWthTax } = orderItem;
          return (
            <Box
              key={orderItemId}
              sx={
                orderItems.length > 0
                  ? {
                      paddingLeft: 1,
                      paddingRight: 1,
                      paddingTop: 1,
                      paddingBottom: ordersItemsLen === i + 1 ? 1 : 0,
                      backgroundColor: '#eef2f6',
                    }
                  : {}
              }>
              <Card>
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs md lg alignItems="center">
                      <CustomProductImage style={{ width: 100, height: 90, borderRadius: 5 }} />
                    </Grid>
                    <Grid item xs md lg>
                      <Box sx={{ marginLeft: { md: 1 } }}>
                        <Box sx={{ marginBottom: 1 }} onClick={() => !isRoleAdmin && viewOrder(productId)}>
                          <CustomTypography variant="h4">{productName}</CustomTypography>
                          <CustomTypography variant="caption">{description || ''}</CustomTypography>
                          <Box>
                            <CustomTypography variant="caption" sx={{ marginTop: 1 }}>
                              Quantity: {quantity}
                            </CustomTypography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs md lg>
                      <CustomTypography variant="body2">
                        <b>{formatCurrency(priceWthTax)}</b>
                      </CustomTypography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          );
        })}
        <Box sx={{ mt: 2, pl: 3 }}>
          {isRoleAdmin &&
          orderFirstItem?.orderStatus !== CONST_ORDER_STATUS_ORDER_COMPLETED &&
          !failedStatusData?.[orderFirstItem.orderStatus] ? (
            getStatusListForAdminToUpdate(orderFirstItem)
          ) : (
            <OrderTracking
              orderFirstItem={orderFirstItem}
              currentStatus={orderFirstItem?.orderStatus}
              orderStatusToSelect={orderStatusToSelect}
              viewOrDownloadInvoice={() => viewOrDownloadInvoice(orderFirstItem)}
              orderSessionId={orderFirstItem?.orderSessionId}
              reportFetchingId={reportFetchingId}
              toggleCancelOrderConfirmModal={toggleCancelOrderConfirmModal}
              // assign
              assigingRiderOrderId={assigingRiderOrderId}
              assignRiderToOrder={assignRiderToOrder}
            />
          )}
        </Box>
      </>
    ) : (
      <NotFound
        message="No Orders Found"
        subMessage="Make Your First Order !"
        showButton
        buttonLabel="View Products"
        navPage="/products"
      />
    );
  };

  return (
    <>
      {(deliveryOrderItemsFetching ||
        completedOrderItemsFetching ||
        allOrdersItemsForClientFetching ||
        failedOrderItemsFetching) &&
      orderItemsFetchingSessionId === orderSessionId ? (
        <LoadingCard />
      ) : (
        renderOrdersItemsOrNotFoundSection()
      )}
    </>
  );
};

export default Orders;
