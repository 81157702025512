import { isValidElement } from 'react';
import { format } from 'date-fns';
import {
  CONST_LOCAL_STORAGE_BRANCH,
  CONST_LOCAL_STORAGE_LOGGED_USER,
  CONST_ROLE_ADMIN,
  CONST_ROLE_CUSTOMER,
  CONST_ROLE_RIDER,
} from './constants';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';

const replaceSpecialCharacter = inputStr => {
  const result = inputStr?.replace(/[^a-zA-Z0-9\s]/g, ' ');
  return result || inputStr;
};

const makeFirstCaps = str => {
  if (str && typeof str === 'string') {
    return str?.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
  }
  return '';
};

const capitalize = str => {
  if (str && typeof str === 'string') {
    return str?.replace(/^./, strItem => `${strItem.toUpperCase()}`);
  }
  return '';
};

const setLocalStorage = (key, value) => {
  if (key && value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const getLocalStorage = key => {
  if (localStorage.getItem(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
};

const removeLocalStorage = key => {
  if (localStorage.getItem(key)) {
    return localStorage.removeItem(key);
  }
};

const dateFormat = (date, formatString = 'dd-MM-yyyy') => {
  return date ? format(new Date(date), formatString) : '';
};

const isDomElement = item => !!(item && isValidElement(item));

function createNumberListByGivenNumAndSize(num, size) {
  return Array.from({ length: num }, (_, i) => (i + 1) * size);
}

const formatDataToSelectComponent = ({ passList, labelParam, valueParam }) => {
  return passList.map(el => ({
    label: el?.[labelParam],
    value: el?.[valueParam],
  }));
};

const getDiscountAmountUtil = (originalPrice, discountPercentage, decimalPlaces = 2) => {
  const discountDecimal = discountPercentage / 100;
  const discountAmount = originalPrice * discountDecimal;
  return Number(discountAmount.toFixed(decimalPlaces));
};

const getDiscountPriceBeforeTax = (originalPrice, discountPercentage) => {
  const discountDecimal = discountPercentage / 100;
  const discountedPriceBeforeTax = originalPrice * (1 - discountDecimal);
  return discountedPriceBeforeTax;
};

function calculateFinalPrice(originalPrice, discountPercentage, taxAmount, decimalPlaces = 0) {
  const discountedPriceBeforeTax = getDiscountPriceBeforeTax(originalPrice, discountPercentage);
  const finalPrice = discountedPriceBeforeTax + taxAmount;
  const roundedFinalPrice = Number(finalPrice.toFixed(decimalPlaces));
  return roundedFinalPrice;
}

const formatCurrency = (number = 0) => {
  const formattedCurrency = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'AED',
    minimumFractionDigits: 2,
  }).format(number);

  return formattedCurrency;
};

function getStatusInfo(status) {
  const statusColors = {
    ORDER_PLACED: { color: '#00bcd4', label: 'Order Placed' },
    PAYMENT_PENDING: { color: '#f44336', label: 'Pending Payment' },
    PAYMENT_COMPLETED: { color: '#4caf50', label: 'Payment Completed' },
    PREPARING: { color: '#2196f3', label: 'Preparing' },
    READY_TO_SERVE: { color: '#ffeb3b', label: 'Ready to Serve' },
    PAYMENT_COD: { color: '#90a4ae', label: 'Cash on Delivery' },
    ON_THE_WAY: { color: '#ff9800', label: 'On the Way' },
    DELIVERED: { color: '#4caf50', label: 'Delivered' },
    KEPT_IN_TABLE: { color: '#673ab7', label: 'Kept in Table' },
    TAKE_AWAY: { color: '#f44336', label: 'Take Away' },
    ORDER_COMPLETED: { color: '#4caf50', label: 'Order Completed' },
  };

  return statusColors[status] || { color: '#000000', label: status };
}

const getLocalUser = () => getLocalStorage(CONST_LOCAL_STORAGE_LOGGED_USER);

const isUserAdmin = () => {
  const user = getLocalUser();
  const isAdmin = user?.userRole === CONST_ROLE_ADMIN;
  return isAdmin;
};

const getUserRole = () => {
  const user = getLocalUser();
  const isRoleAdmin = !!(user?.userRole === CONST_ROLE_ADMIN);
  const isRoleCustomer = !!(user?.userRole === CONST_ROLE_CUSTOMER);
  const isRoleRider = !!(user?.userRole === CONST_ROLE_RIDER);
  return { isRoleAdmin, isRoleCustomer, isRoleRider };
};

const getOrgId = () => {
  const locBranch = getLocalStorage(CONST_LOCAL_STORAGE_BRANCH);
  const user = getLocalUser();
  return locBranch?.branchId || user?.orgId;
};

const downloadPdf = async ({ data, type = 'pdf', name = '' }) => {
  const pdfBlob = await data?.blob();
  const url = window.URL.createObjectURL(pdfBlob);

  if (window.navigator.userAgent.includes('wv')) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ url, name, type }));
  } else {
    const link = document.createElement('a');
    link.href = url;
    link.download = `${name}.${type}`;
    link.click();
  }
  window.URL.revokeObjectURL(url);
};

const formAddress = ({
  userName = 'customer@gmail.com',
  addressLine = '342, Badda House',
  addressStreet = 'Vellad, karuvanchal',
  district = 'Kannur',
  state = 'Kerala',
  pinCode = '876786',
  phoneNumber = '9876787678',
}) => {
  return (
    <>
      <CustomTypography sx={{ pl: 0.5, fontSize: 12 }}>
        <b>Customer:</b> {userName}
      </CustomTypography>
      <CustomTypography sx={{ pl: 0.5, fontSize: 12 }}>
        <b>Phone:</b> {phoneNumber}
      </CustomTypography>
      <CustomTypography sx={{ pl: 0.5, fontSize: 12 }}>
        <b>Address:</b> {addressLine}, {addressStreet}, {district}, {state}.
      </CustomTypography>
      <CustomTypography sx={{ pl: 0.5, fontSize: 12 }}>
        <b>Pin/Zip:</b> {pinCode}
      </CustomTypography>
    </>
  );
};

export {
  makeFirstCaps,
  capitalize,
  setLocalStorage,
  getLocalStorage,
  dateFormat,
  isDomElement,
  createNumberListByGivenNumAndSize,
  formatDataToSelectComponent,
  calculateFinalPrice,
  getDiscountAmountUtil,
  getDiscountPriceBeforeTax,
  formatCurrency,
  replaceSpecialCharacter,
  getStatusInfo,
  getLocalUser,
  isUserAdmin,
  getOrgId,
  removeLocalStorage,
  downloadPdf,
  getUserRole,
  formAddress,
};
