const addressesState = {
  // Data
  addressesData: [], //getAll
  addressesForm: {}, //Post/put
  addressesOne: {}, //get
  page: 1,
  size: 10,
  //Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  //Form
  addressesModalOpen: false,
  formState: {},
  // Additional
  parentDepartmentId: 0,
  // Address
  primaryAddressUpdating: false,
  primaryAddress: {},
  getPrimaryAddressFetching: false,
};
export default addressesState;
