import React, { useEffect, useCallback } from 'react';
import Snackbar from '@mui/material/Snackbar';
import useAppContext from 'store/useAppContext';
import MuiAlert from '@mui/material/Alert';
import { initialAppNotificationState } from 'store/providers/AppProvider';
import AlertTitle from '@mui/material/AlertTitle';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import { CONST_LOCAL_STORAGE_LOGGED_USER, CONST_LOGGED_USER, CONST_ROUTE_SIGN_IN } from 'utils/constants';
import { initialLoggedUser } from 'store/providers/states/authState';
import { setLocalStorage } from 'utils/commonFunc';
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CustomAlert = ({ open, handleClose, message = '', color = 'info' }) => {
  const vertical = 'top';
  const horizontal = 'right';
  const navigate = useNavigate();
  const { authMethods: { setAuthState } = {}, appNotification, setAppNotification } = useAppContext();

  const resetNotification = useCallback(() => {
    setAppNotification(initialAppNotificationState);
  }, [setAppNotification]);

  useEffect(() => {
    if (appNotification) {
      const timeout = setTimeout(() => {
        resetNotification();
      }, 6000);

      return () => clearTimeout(timeout);
    }
  }, [appNotification, resetNotification, setAppNotification]);

  useEffect(() => {
    if (appNotification?.status === 401) {
      console.log('====Token Expired ===');
      setContextState?.({
        setState: setAuthState,
        paramName: CONST_LOGGED_USER,
        paramValue: initialLoggedUser,
      });
      setLocalStorage(CONST_LOCAL_STORAGE_LOGGED_USER, {});
      const logoutTimer = setTimeout(() => {
        navigate(CONST_ROUTE_SIGN_IN);
        window.location.reload();
      }, 3000);
      return () => clearTimeout(logoutTimer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appNotification?.status]);

  const { status = null, data: { message: apiMessage = '', details = [], error = '' } = {} } = appNotification ?? {};
  const isError = typeof status === 'number' && ![200, 201].includes(status);
  const messageTitle = apiMessage ? apiMessage : error ? 'Error' : '';
  const messageContent = details?.length ? details?.join(', ') : error;
  const isOpen = !!(messageTitle || (open && message));

  return (
    isOpen && (
      <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        key={vertical + horizontal}>
        <Alert
          severity={isError ? 'error' : color}
          sx={{ width: '100%' }}
          onClose={() => {
            resetNotification();
            handleClose?.();
          }}>
          {messageTitle && <AlertTitle>{messageTitle}</AlertTitle>}
          {messageContent || message}
        </Alert>
      </Snackbar>
    )
  );
};

export default CustomAlert;
