import React from 'react';
import { Typography } from '@mui/material';

const CustomErrorText = ({ children }) => {
  return (
    <Typography
      component="p"
      className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium css-s0sgjw-MuiFormHelperText-root"
      id="ADDRESSES_-helper-text">
      {children}
    </Typography>
  );
};

export default CustomErrorText;
