import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import useAppContext from 'store/useAppContext';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import { CONST_APP_CONFIRM_MODAL } from 'utils/constants';
import CustomButton from 'ui-component/CustomButton/CustomButton';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function CustomConfirmDialog({
  manualConfirmMessage = '',
  manualConfirmButtonLabel = '',
  manualCancelButtonLabel = '',
  manualConfirmOpen = false,
  manualConfirmId = 0,
  manualConfirmClick = undefined,
  manualCancelClick = undefined,
}) {
  const {
    appMethods: { setAppState },
    appState: {
      isConfirmModalOpen,
      message,
      handleAppConfirmModal,
      isManual,
      manualConfirmButtonLabel: storeManualConfirmButtonLabel,
      manualPassLoading,
      manualOnConfirm,
    },
  } = useAppContext();

  const handleCloseClick = () => {
    if (manualCancelClick) {
      manualCancelClick();
    } else {
      setContextState({
        setState: setAppState,
        paramName: CONST_APP_CONFIRM_MODAL,
        paramValue: false,
      });
    }
  };

  const handleConfirmClick = async () => {
    if (manualConfirmOpen && manualConfirmClick) {
      manualConfirmClick(manualConfirmId);
    } else if (isManual && typeof manualOnConfirm === 'function') {
      await manualOnConfirm?.();
      handleCloseClick();
    } else {
      handleAppConfirmModal?.();
      handleCloseClick();
    }
  };

  return (
    <Dialog
      open={isConfirmModalOpen || manualConfirmOpen}
      onClose={handleCloseClick}
      PaperComponent={PaperComponent}
      // aria-labelledby="draggable-dialog-title"
      // autoFocus={false}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <span style={{ fontSize: 15 }}>Confirm</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{manualConfirmMessage || message || 'Are you sure you want to delete?'}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <CustomButton
          variant="text"
          color="primary"
          name={manualCancelButtonLabel || 'Cancel'}
          handleClick={handleCloseClick}
          colorSame={true}
          disabled={manualPassLoading}
        />
        <CustomButton
          variant="text"
          color="primary"
          name={manualConfirmButtonLabel || storeManualConfirmButtonLabel || 'Delete'}
          handleClick={handleConfirmClick}
          showLoader
          passLoading={manualPassLoading}
        />
      </DialogActions>
    </Dialog>
  );
}
