import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import useAppContext from 'store/useAppContext';
import CategoryForm from './VoucherForm';
import makeStyles from '@mui/styles/makeStyles';
import CustomTable from 'ui-component/tables/CustomTable';
import {
  CONST_MODULE_VOUCHERS,
  CONST_DELETE,
  CONST_GET,
  CONST_GETALL,
  CONST_POST,
  CONST_PUT,
  CONST_TYPE_SUCCESS,
} from 'utils/constants';
// Pagination
import { handleApiAction } from 'utils/apiUtils/apiAction';
import CustomRowColumns from 'ui-component/CustomRowColumns/CustomRowColumns';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { voucherDiscountsForSelect, voucherTypeForSelect } from 'utils/variables';
import { formatCurrency } from 'utils/commonFunc';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(0),
    marginTop: 20,
  },
}));

const idName = 'voucherCode';
const module = CONST_MODULE_VOUCHERS;
const Vouchers = () => {
  const classes = useStyles();
  const {
    crudMethods,
    vouchersState: { page, size, vouchersData, vouchersOne },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState: getMethodByModule({ module }),
      module,
      page,
      size,
    }),
    [crudMethods, getMethodByModule, page, size]
  );

  useEffect(() => {
    handleApiAction({
      ...commonParams,
      action: CONST_GETALL,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchAll = () => handleApiAction({ ...commonParams, action: CONST_GETALL });

  const get = getId => {
    handleApiAction({
      ...commonParams,
      action: CONST_GET,
      getId,
      idName,
    });
  };

  const postOrPut = payload => {
    return handleApiAction({
      ...commonParams,
      action: payload?.[idName] ? CONST_PUT : CONST_POST,
      payload,
      idName,
      [idName]: payload?.[idName],
      refetchAll,
      notification: { show: true, type: CONST_TYPE_SUCCESS },
      ...(!payload?.[idName] && {
        apiPropName: payload?.custUserName ? 'createVoucherForUser' : 'createVoucherForAll',
      }),
      ...(payload?.custUserName && { userName: payload?.custUserName }),
    });
  };

  const deleteItem = delId => {
    return handleApiAction({
      ...commonParams,
      action: CONST_DELETE,
      idName,
      delId,
      refetchAll,
      notification: { show: true, type: CONST_TYPE_SUCCESS },
    });
  };

  const voucherKeys = ['voucherCode', 'voucherType', 'discount', 'expiryDate', 'offerDetails'];
  const voucherHead = ['Voucher Code', 'For', 'Discount', 'Expiry', 'Desciption', 'Actions'];

  return (
    <>
      <CustomRowColumns
        listToLoop={[
          { md: 4, sm: 0, xs: 0 },
          {
            element: <CategoryForm postOrPut={postOrPut} idName={idName} vouchersOne={vouchersOne} />,
            md: 8,
            sm: 12,
            xs: 12,
          },
        ]}
      />
      <Box className={classes.root}>
        <CustomTable
          module={module}
          dataList={vouchersData.map(voucher => ({
            ...voucher,
            voucherType: voucherTypeForSelect.find(el => el.value === voucher.voucherType).label,
            discount:
              voucher.discountType === voucherDiscountsForSelect[0].value
                ? `${voucher.discount}%`
                : formatCurrency(voucher.discount),
          }))}
          dataKeys={voucherKeys}
          headers={voucherHead}
          idName={idName}
          deleteItem={deleteItem}
          getItem={get}
        />
      </Box>
    </>
  );
};
export default Vouchers;
