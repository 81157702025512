// logger.js
const isPlatform = {
  web: typeof document !== 'undefined' && !window.ReactNativeWebView,
  reactNative: typeof document !== 'undefined' && !!window.ReactNativeWebView,
};

export const createLogger = namespace => {
  return {
    log: (event, data) => {
      const timestamp = new Date().toISOString();
      const logData = {
        namespace,
        event,
        timestamp,
        data,
        platform: isPlatform.web ? 'Web' : 'RN-WebView',
      };

      if (isPlatform.web) {
        // Web browser logging
        console.group(`${namespace} - ${event}`);
        console.log('Timestamp:', timestamp);
        console.log('LogData:', data);
        console.log('Platform:', 'Web');
        console.groupEnd();
      }

      // Also send to React Native
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'LOG',
            ...logData,
          })
        );
      }
    },
    error: (event, error) => {
      const timestamp = new Date().toISOString();
      const logData = {
        namespace,
        event,
        timestamp,
        error: error.message,
        stack: error.stack,
        platform: isPlatform.web ? 'Web' : 'React Native WebView',
      };

      if (isPlatform.web) {
        console.group(`${namespace} - ERROR - ${event}`);
        console.error('Timestamp:', timestamp);
        console.error('LogError:', error);
        console.groupEnd();
      }

      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: 'ERROR',
            ...logData,
          })
        );
      }
    },
  };
};
