import React, { useImperativeHandle, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import {
  CONST_MODULE_ORDERS,
  CONST_ORDER_STATUS_ORDER_CANCELLED,
  CONST_ORDER_STATUS_ORDER_PLACED,
  CONST_POST,
} from 'utils/constants';
import useAppContext from 'store/useAppContext';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import CustomSelect from 'ui-component/forms/CustomSelect';
// import { orderCancelReasonsForSelect } from 'utils/variables';
import CustomInput from 'ui-component/forms/CustomInput';
import { Box } from '@mui/material';
import { getUserRole } from 'utils/commonFunc';

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const initialReasonState = { cancellationReason: '', cancellationRemarks: '' };
const initialErrState = { reasonValidationError: '', remarksValidationError: '' };

const module = CONST_MODULE_ORDERS;
export default function OrderCancelConfirmDialog({ cancelConfirmDialogRef, onApproveOrCancelSuccess }) {
  const {
    crudMethods,
    ordersMethods: { setOrdersState },
    ordersState: { cancelOrderConfirmModalOpen, acceptOrderRejectOrderSessionId, cancellationReasons },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const [cancelReason, setCancelReason] = useState(initialReasonState);
  const [showRemarkInput, setShowRemarkInput] = useState(false);
  const [acceptOrRejectLoading, setAcceptOrRejectLoading] = useState(false);
  const [validationErrorObj, setValidationError] = useState(initialErrState);

  const resetState = () => {
    setCancelReason(initialReasonState);
    setValidationError(initialErrState);
    setShowRemarkInput(false);
  };

  const toggleCancelOrderConfirmModal = () => {
    resetState();
    setContextState({
      setState: getMethodByModule({ module }),
      paramName: 'cancelOrderConfirmModalOpen',
      paramValue: !cancelOrderConfirmModalOpen,
    });
  };

  const acceptOrRejectHandler = async passData => {
    const { isRoleCustomer } = getUserRole();
    const { orderType = 0, orderSessionId = 0 } = passData ?? {};
    try {
      setAcceptOrRejectLoading(true);
      await handleApiAction({
        crudMethods,
        setState: setOrdersState,
        module,
        action: CONST_POST,
        apiPropName: isRoleCustomer ? 'cancelOrderByCustomer' : 'acceptOrRejectOrderByAdmin',
        orderSessionId: orderSessionId || acceptOrderRejectOrderSessionId,
        payload: {
          comment: `${cancelReason?.cancellationReason || ''}*${cancelReason?.cancellationRemarks || ''}`,
          reqStatus: orderSessionId ? CONST_ORDER_STATUS_ORDER_PLACED : CONST_ORDER_STATUS_ORDER_CANCELLED,
        },
      });
      onApproveOrCancelSuccess(orderType, orderSessionId);
      !orderSessionId && toggleCancelOrderConfirmModal();
      setAcceptOrRejectLoading(false);
    } catch (err) {}
  };

  const handleRejectOrder = async e => {
    e?.preventDefault();
    acceptOrRejectHandler();
    resetState();
  };

  const handleReasonSelect = (reasonItem, name) => {
    setCancelReason(prevState => ({
      ...prevState,
      [name]: reasonItem || '',
    }));
  };

  useImperativeHandle(cancelConfirmDialogRef, () => ({
    acceptOrRejectHandler,
    acceptOrRejectLoading,
  }));

  return (
    <Dialog
      open={cancelOrderConfirmModalOpen}
      PaperComponent={PaperComponent}
      // aria-labelledby="draggable-dialog-title"
      // autoFocus={false}
      fullWidth
      maxWidth="xs">
      <form onSubmit={handleRejectOrder}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <span style={{ fontSize: 15 }}>Please provide your reason for cancellation</span>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>{'Are you sure you want to delete?'}</DialogContentText> */}
          <CustomSelect
            fieldLabel="Reason"
            fieldName="cancellationReason"
            options={{
              isReq: true,
              md: 6,
              validationError: validationErrorObj.reasonValidationError,
              selectOptions: cancellationReasons || [],
              placeholder: 'Select reason',
            }}
            onSelect={(value, name) => handleReasonSelect(value, name)}
            fieldValue={cancelReason.cancellationReason}
          />
          <Box sx={{ mt: 3 }}>
            <CustomInput
              fieldLabel="Remarks"
              fieldName="cancellationRemarks"
              options={{
                // isReq: true,
                md: 6,
                validationError: validationErrorObj.remarksValidationError,
                placeholder: 'Enter reason',
              }}
              onChange={(value, name) => handleReasonSelect(value, name)}
              fieldValue={cancelReason.cancellationRemarks}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <CustomButton
            name="Close"
            onClick={toggleCancelOrderConfirmModal}
            colorSame={true}
            variant="outlined"
            size="small"
            disabled={acceptOrRejectLoading}
          />
          <CustomButton
            type="submit"
            name="Cancel Order"
            showLoader
            passLoading={acceptOrRejectLoading}
            colorSame
            color="error"
            variant="outlined"
            size="small"
          />
        </DialogActions>
      </form>
    </Dialog>
  );
}
