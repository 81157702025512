// assets
import LayersIcon from '@mui/icons-material/Layers';
import DiscountIcon from '@mui/icons-material/Discount';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import VoucherOffer from '@mui/icons-material/LocalOffer';
import { CONST_LABEL_FOOD, CONST_LABEL_FOODS, CONST_LABEL_PRODUCTS, CONST_LABEL_VOUCHERS } from 'utils/constants';
// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const products = {
  id: 'products',
  title: `${CONST_LABEL_FOOD} ${CONST_LABEL_PRODUCTS}`,
  type: 'group',
  children: [
    {
      id: 'products',
      title: CONST_LABEL_FOODS,
      type: 'item',
      url: '/admin/products',
      target: true,
      icon: FastfoodIcon,
    },
    {
      id: 'categories',
      title: 'Categories',
      type: 'item',
      url: '/admin/categories',
      target: true,
      icon: LayersIcon,
    },
    {
      id: 'discounts',
      title: 'Discounts',
      type: 'item',
      url: '/admin/discounts',
      target: true,
      icon: DiscountIcon,
    },
    {
      id: 'vouchers',
      title: CONST_LABEL_VOUCHERS,
      type: 'item',
      url: '/admin/vouchers',
      target: true,
      icon: VoucherOffer,
    },
  ],
};

export default products;
