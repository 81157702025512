import { intialTimeSlots } from 'views/pages/branches/BranchTimings';

const dashboardsState = {
  // Data
  branchesData: [], // getAll
  branchesForm: {}, // post/put
  branchesOne: {}, // get
  branchesView: {}, // view
  page: 1,
  size: 10,
  // Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  // Form
  branchesModalOpen: false,
  formState: {},
  orderTracks: [],
  getTracksLoading: false,
  // Main
  timeSlotsParam: 'timeSlots',
  timeSlots: [{ ...intialTimeSlots }],
  // Fetch
  branchTimingsFetching: false,
  branchTimings: [],
  branchTimingsUpdating: false,
  branchTimingsDeleting: false,
  branchAvailabilityUpdating: false,
};

export default dashboardsState;
