import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  CONST_GET,
  CONST_GETALL,
  CONST_MODULE_ORDERS,
  CONST_ORDER_COD,
  CONST_POST,
  CONST_TAB_ASSIGNED,
  CONST_TAB_AWAITING,
} from 'utils/constants';
import useAppContext from 'store/useAppContext';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import { getUserRole } from 'utils/commonFunc';
import { useLocation, useNavigate } from 'react-router-dom';
import RiderOrderCard from '../RiderOrderCard';
import CustomNoDataFound from 'ui-component/CustomNoDataFound';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const module = CONST_MODULE_ORDERS;
export default function AdminRiderOrdersTab() {
  const theme = useTheme();
  const {
    crudMethods,
    ordersMethods: { setOrdersState = undefined } = {},
    ordersState: {
      // admin
      allRidersFetching,
      allAwaitingOrders,
      allAwaitingOrdersFetching,
      allAssignedRiderOrders,
      allAssignedRiderOrdersFetching,
      // assign
      assignRiderByAdminUpdating,
    },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const [orderItemsFetchingSessionId, setOrderItemsFetchingSessionId] = useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const queryTab = queryParams.get('tab');
  const defaultTab = queryTab === 'delivery_orders' ? 1 : 0;
  const { isRoleAdmin } = getUserRole();
  const cancelConfirmDialogRef = useRef(null);
  const [assigingRiderOrderId, setAssigingRiderOrderId] = useState(0);
  let awaitingOrdersRef = useRef(null);

  const handleChange = (event, newValue) => {
    if (newValue) {
      clearInterval(awaitingOrdersRef.current);
      awaitingOrdersRef.current = null;
    } else if (newValue === 0 && !awaitingOrdersRef.current) {
      awaitingOrdersRef.current = setInterval(() => {
        handleApiAction({
          ...commonParams,
          action: CONST_GETALL,
          apiPropName: orderPayloadData[newValue].apiPropName,
          stateParam: orderPayloadData[newValue].stateParam,
          orderType: CONST_ORDER_COD,
        });
      }, 120_000); // Fetch every 2 mins
    }
    setActiveTab(newValue);
    navigate(`?tab=${newValue === 1 ? 'delivery_orders' : 'awaiting_orders'}`);
  };

  useEffect(() => {
    if (isRoleAdmin) {
      handleChange(null, defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab, queryTab, isRoleAdmin]);

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState: setOrdersState || getMethodByModule({ module }),
      module,
    }),
    [crudMethods, setOrdersState, getMethodByModule]
  );

  const fetchAllRiders = async () => {
    await handleApiAction({
      ...commonParams,
      action: CONST_GET,
      apiPropName: 'getAllRiders',
      stateParam: 'allRiders',
      loadingParam: 'allRidersFetching',
      riderStatus: 'AVAILABLE',
    });
  };

  useEffect(() => {
    if (!allRidersFetching) {
      fetchAllRiders();
    }
    return () => {
      clearInterval(awaitingOrdersRef.current);
      awaitingOrdersRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderPayloadData = useMemo(
    () => ({
      0: {
        apiPropName: 'getAllAwaitingRideOrders',
        stateParam: 'allAwaitingOrders',
        loadingParam: 'allAwaitingOrdersFetching',
      },
      1: {
        apiPropName: 'getAllAssignedRiderOrders',
        stateParam: 'allAssignedRiderOrders',
        loadingParam: 'allAssignedRiderOrdersFetching',
      },
    }),
    []
  );

  const fetchAwaitingAndDeliveryOrders = useCallback(
    (forceFetch = false) => {
      if (
        isRoleAdmin &&
        ((!activeTab && !allAwaitingOrdersFetching) || (activeTab && !allAssignedRiderOrdersFetching) || forceFetch)
      ) {
        handleApiAction({
          ...commonParams,
          action: CONST_GETALL,
          apiPropName: orderPayloadData[activeTab].apiPropName,
          stateParam: orderPayloadData[activeTab].stateParam,
          loadingParam: orderPayloadData[activeTab].loadingParam,
          orderType: CONST_ORDER_COD,
        });
      }
    },
    [activeTab, commonParams, allAwaitingOrdersFetching, allAssignedRiderOrdersFetching, orderPayloadData, isRoleAdmin]
  );

  useEffect(() => {
    fetchAwaitingAndDeliveryOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, isRoleAdmin]);

  const assignRiderToOrder = async (rideOrderId, riderUserName) => {
    setAssigingRiderOrderId(rideOrderId);
    await handleApiAction({
      ...commonParams,
      action: CONST_POST,
      apiPropName: 'assignRiderByAdmin',
      loadingParam: 'assignRiderByAdminUpdating',
      rideOrderId,
      riderUserName,
    });
    fetchAwaitingAndDeliveryOrders(true);
    setAssigingRiderOrderId(0);
  };

  return (
    <Box>
      <Paper
        sx={{
          borderRadius: 1,
        }}
        elevation={1}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          // indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example">
          <Tab label="Awaiting" {...a11yProps(0)} />
          <Tab label="Assigned" {...a11yProps(1)} />
        </Tabs>
      </Paper>
      <Box>
        <TabPanel value={activeTab} index={0} dir={theme.direction}>
          {allAwaitingOrders?.length ? (
            allAwaitingOrders.map((riderOrder, i) => {
              return (
                <RiderOrderCard
                  key={riderOrder?.rideOrderId}
                  {...riderOrder}
                  tab={CONST_TAB_AWAITING}
                  assignRiderToOrder={assignRiderToOrder}
                  assignRiderByAdminUpdating={assignRiderByAdminUpdating}
                  assigingRiderOrderId={assigingRiderOrderId}
                />
              );
            })
          ) : (
            <CustomNoDataFound loading={allAwaitingOrdersFetching} text="No Awaiting Orders Found." />
          )}
        </TabPanel>
        <TabPanel value={activeTab} index={1} dir={theme.direction}>
          {allAssignedRiderOrders?.length ? (
            allAssignedRiderOrders.map((riderOrder, i) => {
              return <RiderOrderCard key={riderOrder?.rideOrderId} {...riderOrder} tab={CONST_TAB_ASSIGNED} />;
            })
          ) : (
            <CustomNoDataFound loading={allAssignedRiderOrdersFetching} text="No Orders Found." />
          )}
        </TabPanel>
      </Box>
    </Box>
  );
}
