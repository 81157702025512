import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import { store } from 'store';

// style + assets
import 'assets/scss/style.scss';
import 'assets/scss/global.scss';

import config from './config';
import AppProvider from 'store/providers/AppProvider';

const root = createRoot(document.getElementById('root'));
root.render(
<React.StrictMode>
 <Provider store={store}>
        <AppProvider>
            <BrowserRouter basename={config.basename}>
                <App />
            </BrowserRouter>
        </AppProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
