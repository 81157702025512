import React, { useCallback, useImperativeHandle } from 'react';
import { formObjBuild, formStateByData } from 'utils/formUtils/formBuilderUtils';
import CustomInput from '../../ui-component/forms/CustomInput';
import CustomRowColumns from 'ui-component/CustomRowColumns/CustomRowColumns';
import { useEffect } from 'react';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import CustomSelect from '../../ui-component/forms/CustomSelect';
import CustomRadioGroup from '../../ui-component/forms/CustomRadioGroup';
import CustomDatePicker from '../../ui-component/forms/CustomDatePicker';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import useValidateForm from './useValidateForm';
import { formPostData } from './commonUtils';

const formElements = {
  INPUT: formData => <CustomInput {...formData} />,
  SELECT: formData => <CustomSelect {...formData} />,
  MULTI_SELECT: formData => <CustomSelect isMulti={true} {...formData} />,
  RADIO_GROUP: formData => <CustomRadioGroup {...formData} />,
  DATE_PICKER: formData => <CustomDatePicker {...formData} />,
};

const getElement = (fieldType, formData) => formElements?.[fieldType]?.(formData);

const FormBuilder = ({
  formBuilderRef,
  initialState = {},
  module = '',
  onValidationSuccess,
  itemOne, // This is the fetched item (fetchOne) for each module
  idName, // idName for each fetchOne (e.g categories --> 'productCatId')
  isSeqChanged = false,
}) => {
  const { getMethodByModule, getStateParamDataByModule } = useStoreAccessByModule();
  const { validateForm } = useValidateForm();

  const updateModuleState = useCallback(
    (passValue, paramName = '', isFullUpdate = false) => {
      if (module) {
        setContextState({
          setState: getMethodByModule({ module }),
          paramName: paramName || 'formState',
          paramValue: passValue,
          isFullUpdate,
        });
      }
    },
    [getMethodByModule, module]
  );

  const formState = getStateParamDataByModule({
    module,
    passStateParamName: 'formState',
  });

  useEffect(() => {
    if (!Object.keys(formState || {}).length) {
      updateModuleState(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialState, module]);

  useEffect(() => {
    if (itemOne?.[idName]) {
      const passForm = formStateByData(itemOne, initialState);
      updateModuleState(passForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemOne?.[idName], JSON.stringify(itemOne || {})]);

  const handleChange = (fieldValue, fieldName, formObj = {}) => {
    const { valueToOtherFields = [], controlFieldNameToShow = '', controlByValueToShow = '' } = formObj?.options ?? {};
    const newState = {
      ...formState,
      [fieldName]: {
        ...formState?.[fieldName],
        fieldValue,
      },
    };

    if (controlFieldNameToShow) {
      if (newState?.[controlFieldNameToShow]) {
        newState[controlFieldNameToShow] = {
          ...newState[controlFieldNameToShow],
          options: {
            ...newState[controlFieldNameToShow]?.options,
            isHide: fieldValue !== controlByValueToShow,
            isReq: fieldValue === controlByValueToShow,
          },
        };
      }
    }

    if (Array.isArray(valueToOtherFields) && valueToOtherFields?.length) {
      valueToOtherFields.forEach(fieldNameObj => {
        if (newState[fieldNameObj]) {
          newState[fieldNameObj] = {
            ...newState[fieldNameObj],
            fieldValue,
          };
        }
      });
    }

    updateModuleState(newState);
  };

  const handleSubmit = async e => {
    e?.preventDefault();
    const { isErrorExist, formState: payload } = validateForm(module);
    if (!isErrorExist) {
      const postData = formPostData(payload);
      // if (itemOne?.[idName]) {
      //   postData[idName] = itemOne?.[idName];
      // }
      onValidationSuccess?.(postData);
    }
  };

  const resetForm = () => {
    const isFullUpdate = true;
    updateModuleState({}, `${module.toLowerCase()}One`, isFullUpdate);
    updateModuleState(initialState);
  };

  useImperativeHandle(formBuilderRef, () => ({ handleSubmit, resetForm, updateModuleState }));

  // UI Form building
  const formStateList = Object.values(formState || {})
    .filter(el => el)
    .map(el => {
      const formObj = formObjBuild(el || {});
      return (
        formObj?.fieldName &&
        formObj?.fieldType &&
        !formObj?.options?.isHide && {
          element: getElement(formObj?.fieldType, {
            ...formObj,
            module,
            handleChange: (value, name) => handleChange(value, name, formObj),
          }),
          md: formObj.options?.md || 12,
          sm: formObj.options?.md && formObj.options?.md < 7 ? formObj.options?.md * 2 : 12,
          fieldName: formObj.fieldName,
          fieldValue: formObj.fieldValue,
        }
      );
    })
    .filter(el => el?.element);

  const finalFormStateList = isSeqChanged
    ? [...(formStateList || [])].sort((el1, el2) => el1?.options?.seq - el2?.options?.seq)
    : formStateList;

  return (
    <form ref={formBuilderRef} onSubmit={handleSubmit}>
      <CustomRowColumns isForm={true} listToLoop={finalFormStateList} />
      <button type="submit" style={{ display: 'none' }} />
    </form>
  );
};

export default FormBuilder;
