import { useRoutes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

// routes
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoutes from './AdminRoutes';
import ClientRoutes from './ClientRoutes';
import RiderRoutes from './RiderRoutes';
import CommonRoutes from './CommonRoutes';
import useAppContext from 'store/useAppContext';
import { getLocalUser, getUserRole } from 'utils/commonFunc';
import NotFound from 'views/pages/notfound';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const navigate = useNavigate();
  const user = getLocalUser();
  const {
    authState: { isAuthenticated: isAuth },
  } = useAppContext();
  const { isRoleAdmin, isRoleCustomer, isRoleRider } = getUserRole();
  const isAuthenticated = !!(isAuth || user?.userRole);

  let routes = [AuthenticationRoutes(isAuthenticated, { isRoleAdmin, isRoleCustomer, isRoleRider })];
  const commonRoutes = CommonRoutes(isAuthenticated, isRoleAdmin);
  if (isRoleAdmin) {
    const adminRoutes = AdminRoutes(isAuthenticated);
    routes = [...routes, adminRoutes, commonRoutes];
  }

  if (isRoleCustomer) {
    const clientRoutes = ClientRoutes(isAuthenticated);
    routes = [...routes, clientRoutes, commonRoutes];
  }

  if (isRoleRider) {
    const riderRoutes = RiderRoutes(isAuthenticated);
    routes = [...routes, riderRoutes, commonRoutes];
  }

  const finalRoutes = [
    ...routes,
    {
      path: '*',
      element: <NotFound />,
    },
  ];

  const AppRoutes = useRoutes(finalRoutes);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/signin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return AppRoutes;
}
