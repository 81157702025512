import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import SidebarLogo from 'ui-component/Logo';
import { MENU_OPEN } from 'store/customizationReducer/actions';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ isPathBranches = false }) => {
  const defaultId = useSelector(state => state.customization.defaultId);
  const dispatch = useDispatch();
  return (
    <ButtonBase
      disableRipple
      onClick={() => {
        if (!isPathBranches) {
          dispatch({ type: MENU_OPEN, id: defaultId });
        }
      }}
      component={Link}
      to={!isPathBranches ? config.defaultPath : '/admin/branches'}>
      <SidebarLogo />
    </ButtonBase>
  );
};

export default LogoSection;
