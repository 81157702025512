import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import CustomButton from 'ui-component/CustomButton/CustomButton';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function CustomDraggableDialog({isDraggableModalOpen, toggleDraggableModal, children, title, width, height, markerPosition, setSelectedLocation, clearSelectedLocation}) {
  return (
      <Dialog
        open={isDraggableModalOpen}
        onClose={toggleDraggableModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        fullWidth
      >
        <DialogContent style={{ width, height }}>
         {children}
        </DialogContent>
        <DialogActions>
          <CustomButton name="Clear" color="inherit" onClick={clearSelectedLocation} isActionPostOrPut />
          <CustomButton
            onClick={setSelectedLocation}
            name="Set Location"
            showLoader
            isActionPostOrPut
            disabled={!markerPosition?.lat}
          />
        </DialogActions>
      </Dialog>
  );
}
