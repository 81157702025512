import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CustomTypography from './CustomTypography/CustomTypography';
import CustomLoader from './CustomLoader/CustomLoader';

const CustomNoDataFound = ({ text, itemsName, loading, sx }) => (
  <Paper variant="outlined" sx={sx}>
    <Grid container justifyContent="center" alignItems="center" sx={{ height: '20vh' }}>
      <Grid item>
        {loading ? (
          <CustomLoader />
        ) : (
          <CustomTypography gutterBottom variant="h5">
            {text || `No ${itemsName || 'Data'} Found`}
          </CustomTypography>
        )}
      </Grid>
    </Grid>
  </Paper>
);

export default CustomNoDataFound;
