import { useCallback } from 'react';
import { setContextState } from '../utils/contextStoreUtils/setContextUtils';
import { getLocalStorage } from 'utils/commonFunc';
import { CONST_LOCAL_STORAGE_LOGGED_USER, CONST_TYPE_SUCCESS } from 'utils/constants';

const formatResponse = formatData => {
  const { data, sourceFormat, returnType, options = {} } = formatData;
  if (Array.isArray(sourceFormat) && returnType === 'object') {
    let returnObj = {};
    sourceFormat?.forEach(el => {
      returnObj = {
        ...returnObj,
        [el.change]: data?.[el?.actual],
      };
    });
    return returnObj;
  } else if (
    options?.readContent &&
    Array.isArray(data?.content) &&
    Array.isArray(sourceFormat) &&
    returnType === 'array'
  ) {
    const returnList = data?.forEach(() => {
      let returnObj = {};
      sourceFormat?.forEach(el => {
        returnObj = {
          ...returnObj,
          [el?.change]: data?.[el?.actual],
        };
      });
    });

    return { ...data, content: returnList };
  }
};

const useApiCall = () => {
  const callApi = useCallback(async configData => {
    const {
      url = '',
      // method = "GET",
      payload = {},
      headers = {},
      loadingParam = '',
      stateParam = '',
      setState,
      sourceFormat,
      returnType,
      readContent = false,
      // contextState from handler
      contextState,
      module,
      // additional
      toggleModal,
      toggleMenu,
      refetchAll,
      headerAccept = '',
      hideError = false,
      ...rest
    } = configData;
    const method = configData?.method ? configData?.method.toUpperCase() : 'GET';
    //** Token section */
    const { userToken: localUserToken = '', userId = 0 } = getLocalStorage(CONST_LOCAL_STORAGE_LOGGED_USER) || {};
    const { authState: { loggedUser: { userToken = '' } = {} } = {}, setAppNotification } = contextState || {};
    const token = userToken || localUserToken;
    //** Headers section */
    headers['Accept'] = headerAccept || 'application/json';
    if (userId) headers['currentUserId'] = `${userId}`;
    if (['POST', 'PUT'].includes(method)) headers['Content-Type'] = headerAccept || 'application/json';
    if (token) headers['Authorization'] = `Bearer ${token}`;

    // setAppNotification?.(initialAppNotificationState);
    //** Loading begin */
    if (loadingParam && setState) {
      setContextState({ setState, paramName: loadingParam, paramValue: true });
    }
    try {
      //** API section */
      // console.info('API_INITIATED', { url, method });
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}${url}`, {
        method,
        headers,
        ...(headerAccept === 'application/pdf' && { responseType: 'blob' }),
        ...(['POST', 'PUT'].includes(method) && payload && { body: JSON.stringify(payload) }),
      });
      // console.info('API_ENDED', { status: response.status, url, method });
      if (!response.ok && !hideError) {
        const resData = await response.json();
        setAppNotification?.({ status: response.status, data: resData });
      }
      const { notification: { show = false, type = '', message = '' } = {} } = rest || {};

      let data = '';
      if (response?.ok && headerAccept === 'application/pdf') {
        data = response;
      } else if (response?.ok) {
        data = await response?.json();
      }
      if (response.ok && show) {
        setAppNotification?.({
          status: response.status,
          data: {
            ...(type === CONST_TYPE_SUCCESS && { message: 'Success' }),
            details: [message || data?.message || 'Success'],
          },
        });
      }
      if (setState && loadingParam) {
        setContextState({
          setState,
          paramName: loadingParam,
          paramValue: false,
        });
      }
      //** Modal close */ Close modal (If the action is performing on modal)
      if (response.ok && toggleModal && typeof toggleModal === 'function') {
        toggleModal?.();
      }
      //** Menu toggle */
      if (response.ok && toggleMenu && typeof toggleMenu === 'function') {
        toggleMenu?.();
      }
      //** Refetch section */
      if (response.ok && refetchAll && typeof refetchAll === 'function') {
        refetchAll?.();
      }
      //** Store begin */

      const resData =
        sourceFormat && data
          ? formatResponse({
              data,
              sourceFormat,
              returnType,
              options: { readContent },
            })
          : data;

      if (stateParam && resData && setState) {
        setContextState({
          setState,
          paramName: stateParam,
          paramValue: resData,
        });
      }
      return { ok: response?.ok, status: response?.status, data: resData };
    } catch (error) {
      console.error(`Error: ${method}: ${url}: `, error);
      if (error?.status === 401) {
        console.error('Not-authorized');
        return;
      }
      if (loadingParam && setState) {
        setContextState({
          setState,
          paramName: loadingParam,
          paramValue: false,
        });
      }
      !hideError && setAppNotification?.(error);
    }
  }, []);

  return callApi;
};

export default useApiCall;
