import React from 'react';
import Grid from '@mui/material/Grid';

export default function TwoInlineColumnsDOM({
  children,
  columns = [6, 6], // Array to hold column configurations (can be single or multiple)
  justifyContent = 'space-between',
  alignItems = 'center',
  sx = {},
}) {
  // Ensure columns are valid (either a single valid configuration or an array of them)
  if (!Array.isArray(columns)) {
    columns = [columns]; // Convert single configuration to an array
  }

  const currentColumns = columns[Math.floor(Math.random() * columns.length)]; // Select a random configuration

  return (
    <Grid
      container
      spacing={{ xs: 1, sm: 2, md: 2 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      direction="row"
      alignItems={alignItems}
      justifyContent={justifyContent}
      sx={sx}>
      {React.Children.map(
        children,
        (child, i) =>
          child && (
            <Grid key={child} item columns={{ xs: 12, sm: currentColumns[i], md: currentColumns[i] }}>
              {child}
            </Grid>
          )
      )}
    </Grid>
  );
}
