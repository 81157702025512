const cartsState = {
  // Data
  cartsData: [], //getAll
  cartsForm: {}, //Post/put
  cartsOne: {}, //get
  page: 1,
  size: 10,
  //Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  //Form
  cartsModalOpen: false,
  formState: {},
  // Additional
  parentDepartmentId: 0,
  // Filter
  categoriesWithCount: [],
  categoriesWithCountFetching: false,
  selectedCategoryId: 0,
};
export default cartsState;
