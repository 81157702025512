import React, { useState, useEffect, useMemo } from 'react';
import { Box, Container, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import { useNavigate, useParams } from 'react-router-dom';
import useAppContext from 'store/useAppContext';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import {
  CONST_ACTION_VIEW,
  CONST_GET,
  CONST_GETALL,
  CONST_LOCAL_STORAGE_LOGGED_USER,
  CONST_MODULE_CARTS,
  CONST_MODULE_PRODUCTS,
  CONST_POST,
} from 'utils/constants';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import CustomRatings from 'ui-component/CustomRatings/CustomRatings';
import { formatCurrency, getLocalStorage, isUserAdmin } from 'utils/commonFunc';
import CustomStrikeoutText from 'ui-component/CustomStrikeoutText';
import CustomTwoInlineColumns from 'ui-component/CustomTwoInlineColumns/CustomTwoInlineColumns';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CustomTabs from 'ui-component/CustomTabs/CustomTabs';
import CustomResGrid from 'ui-component/CustomResponsiveGrid/CustomResGrid';
import { getProductPrice, getDiscountAmount, getTotalPriceWithDiscount } from '../carts/Carts';
import { categoryTypesForSelect } from 'utils/variables';
import food1 from 'assets/images/foods/food1.webp';
import food2 from 'assets/images/foods/food2.webp';
import food3 from 'assets/images/foods/food3.jpg';
import food4 from 'assets/images/foods/food4.avif';
import food5 from 'assets/images/foods/food5.jpg';
import food6 from 'assets/images/foods/food6.webp';
const foodImages = [food1, food2, food3, food4, food5, food6];

const idName = 'productId';
const module = CONST_MODULE_PRODUCTS;
const cartModule = CONST_MODULE_CARTS;
const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
  },
  imageContainer: {
    maxWidth: '100%',
    maxHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 10,
  },
  productDetails: {
    padding: theme.spacing(2),
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  card: {
    display: 'flex',
    borderRadius: 10,
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
  media: {
    width: '100%',
    maxWidth: 300,
    objectFit: 'cover',
  },
  content: {
    padding: theme.spacing(1),
    flex: '1 0 auto',
  },
  ratingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  price: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
  },
  description: {
    marginTop: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  priceSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  originalPrice: {
    textDecoration: 'line-through',
    marginRight: theme.spacing(1),
  },
  discountPercent: {
    color: 'green',
    fontWeight: 'bold',
  },
}));

export const CustomProductImage = ({ src, style, sx }) => {
  const classes = useStyles();
  // Temporary
  const [randomFoodImage, setRandomFoodImage] = useState('');
  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * foodImages.length);
    setRandomFoodImage(foodImages[randomIndex]);
  }, []);
  // Temp

  return (
    <Paper className={classes.imageContainer}>
      <img src={src || randomFoodImage} alt="Product" style={style} />
    </Paper>
  );
};

export const CustomProductPrice = ({ product }) => {
  const classes = useStyles();
  const { productPrice, discountPercent, quantity = 1 } = product;

  const discountAmountPerProduct = getDiscountAmount(productPrice, discountPercent);

  const priceDetails = [
    {
      label: formatCurrency(getProductPrice(productPrice, quantity) || 0),
      className: classes.price,
    },
    ...(discountPercent
      ? [
          {
            label: (
              <CustomStrikeoutText
                text={formatCurrency(getTotalPriceWithDiscount(productPrice, discountAmountPerProduct, quantity) || 0)}
              />
            ),
          },
          {
            label: `${discountPercent || 0}% Off`,
            sx: { color: 'green', fontWeight: 'bold' },
          },
        ]
      : []),
  ];

  return (
    <Box sx={{ marginTop: 2 }}>
      <Grid container spacing={1} alignItems="center">
        {priceDetails.map((item, index) => (
          <Grid item xs sm md key={item?.productId || index}>
            <CustomTypography {...item} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export const generateTwoElementGrid = ({ label, value }, i = 0) => (
  <CustomResGrid key={label || i} sx={{ marginTop: 2 }}>
    <>{label}</>
    <CustomTypography variant="h5">{value}</CustomTypography>
  </CustomResGrid>
);

//******* Main Component *****/
const ViewProduct = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { productId: id } = useParams();
  const {
    crudMethods,
    productsMethods: { setProductsState = undefined },
    productsState: { productsView: product = {}, getFetching },
    cartsMethods: { setCartsState },
    cartsState: { cartsData, getAllFetching },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const itemId = product?.[idName];

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState: setProductsState || getMethodByModule({ module }),
      module,
    }),
    [crudMethods, setProductsState, getMethodByModule]
  );

  const get = () => {
    handleApiAction({
      ...commonParams,
      action: CONST_GET,
      getId: id,
      idName,
      clickAction: CONST_ACTION_VIEW,
    });
  };

  /***** Fetch Carts */
  useEffect(() => {
    const cartsLen = cartsData?.length;
    if (!cartsLen && !getAllFetching) {
      handleApiAction({
        crudMethods,
        module: CONST_MODULE_CARTS,
        action: CONST_GETALL,
        setState: setCartsState,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!id) {
      navigate('/products');
    } else if (id && !itemId && !getFetching) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, itemId]);

  const isAlreadyExistCheck = () => cartsData.find(cartItem => cartItem?.productId === itemId);
  const isAlreadyExist = isAlreadyExistCheck();

  const {
    productName: title,
    productPrice: price,
    productType,
    rating = 1432,
    description,
    discountPercent,
    categoryName,
    categoryType,
    taxAmount,
    sku,
  } = product;
  const postOrPut = async navPage => {
    const user = getLocalStorage(CONST_LOCAL_STORAGE_LOGGED_USER);
    const res = await handleApiAction({
      crudMethods,
      setState: setCartsState || getMethodByModule({ module: cartModule }),
      module: cartModule,
      action: CONST_POST,
      payload: {
        productId: itemId,
        quantity: 1,
        userName: user?.userId,
        cartItemId: 0,
      },
      idName,
      // refetchAll,
      // notification: { show: true, type: CONST_TYPE_SUCCESS },
    });
    res?.ok && navPage && navigate(navPage);
  };

  const addToCart = () => {
    if (isAlreadyExist) {
      navigate('/view-cart');
      return;
    }
    postOrPut('/view-cart');
  };

  const buyNow = () => {
    if (isAlreadyExist) {
      navigate('/view-checkout');
      return;
    }
    postOrPut('/view-checkout');
  };

  const specificationDiv = [
    { label: 'Product Name', value: title },
    { label: 'Product Type', value: productType },
    { label: 'Stock Keeping Unit', value: sku },
  ].map(generateTwoElementGrid);

  const categoryTypeName = categoryTypesForSelect?.find(selectItem => selectItem.value === categoryType)?.label;

  const categoryDiv = [
    { label: 'Category Name', value: categoryName },
    { label: 'Category Type', value: categoryTypeName },
  ].map(generateTwoElementGrid);

  const priceDiv = [
    { label: 'Price', value: formatCurrency(price) },
    {
      label: 'Discount',
      value: (
        <>
          {formatCurrency(getDiscountAmount(price, discountPercent))}{' '}
          <span style={{ color: 'grey' }}>({discountPercent}%)</span>
        </>
      ),
    },
    { label: 'Tax', value: formatCurrency(taxAmount) },
  ].map(generateTwoElementGrid);

  const isRoleAdmin = isUserAdmin();
  return (
    <Container className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CustomProductImage src={null} style={{ maxWidth: '100%', maxHeight: '100%', borderRadius: 20 }} />
          {!isRoleAdmin && (
            <Box className={classes.buttonContainer}>
              <CustomButton
                fullWidth
                style={{ marginRight: 2 }}
                variant="contained"
                color={isAlreadyExist ? 'warning' : 'primary'}
                name={isAlreadyExist ? 'GO TO CART' : 'ADD TO CART'}
                isActionPostOrPut={true}
                module={cartModule}
                onClick={addToCart}
                passLoading={getFetching}
              />
              <CustomButton
                fullWidth
                style={{ marginLeft: 2 }}
                variant="contained"
                color="error"
                name="BUY NOW"
                isActionPostOrPut={true}
                module={cartModule}
                onClick={buyNow}
                passLoading={getFetching}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper variant="outlined" className={classes.productDetails}>
            <Box className={classes.content}>
              <CustomTwoInlineColumns>
                <CustomTypography variant="h2" gutterBottom label={title} />
                <FavoriteBorderIcon />
              </CustomTwoInlineColumns>
              <Box className={classes.ratingContainer}>
                <CustomRatings rating={rating} />
                <CustomTypography variant="body2" ml={1} label={`(${rating} ratings)`} />
              </Box>
              <CustomProductPrice product={product} />
              <br />
              <CustomTypography variant="h6" label="DESCRIPTION" />
              <CustomTypography variant="caption" className={classes.description} label={description} />
            </Box>
            <CustomTabs
              tabsList={[
                { label: 'Specifications', element: specificationDiv },
                { label: 'Category', element: categoryDiv },
                { label: 'Price details', element: priceDiv },
                { label: 'Reviews' },
              ]}
            />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ViewProduct;
