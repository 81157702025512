import React from 'react';
import Typography from '@mui/material/Typography';

const CustomTypography = props => {
  const { children, label, className, variant, ml, sx, columnsizes, style, bold, onClick } = props;
  return (
    <Typography
      className={className}
      variant={variant}
      ml={ml}
      sx={sx}
      columnsizes={columnsizes}
      style={{ ...style, ...(bold && { fontWeight: 'bold' }) }}
      onClick={onClick}>
      {label || children}
    </Typography>
  );
};

export default CustomTypography;
