import React from 'react';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CONST_LABEL_PAGE } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import CustomButton from 'ui-component/CustomButton/CustomButton';

const useStyles = makeStyles(theme => ({
  rootHeight: {
    minHeight: '100vh',
  },
  rootAdminHeight: {
    minHeight: '20vh',
  },
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const NotFound = ({
  type = CONST_LABEL_PAGE,
  message = '',
  subMessage = '',
  navPage = '',
  showButton = false,
  buttonLabel,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();

  return (
    <Box className={`${classes.root} ${classes.rootHeight}`}>
      <Typography variant="h4" className={classes.title}>
        {message || '404 Page Not Found'}
      </Typography>
      <>
        <Typography variant="subtitle1" onClick={() => !showButton && navPage && navigate(navPage)}>
          {subMessage || 'The page you are looking for does not exist.'}
        </Typography>
        {showButton && buttonLabel ? (
          <CustomButton sx={{ marginTop: 2 }} name={buttonLabel} onClick={() => navigate(navPage)} variant="outlined" />
        ) : (
          <CustomButton
            sx={{ marginTop: 2 }}
            name="Go Back"
            onClick={() => navigate('/auth/signin')}
            variant="outlined"
          />
        )}
      </>
    </Box>
  );
};

export default NotFound;
