import React, { useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';

const CustomIcon = ({ children, module, isActionPostOrPut, loadingParam, onClick, sx = {} }) => {
  const { getStateParamDataByModule } = useStoreAccessByModule();

  const loading = useMemo(() => {
    if (isActionPostOrPut) {
      return ['posting', 'putting', loadingParam].some(
        param =>
          !!getStateParamDataByModule({
            module,
            passStateParamName: param,
          })
      );
    } else if (typeof loadingParam === 'string') {
      return getStateParamDataByModule({
        module,
        passStateParamName: loadingParam,
      });
    } else {
      return false;
    }
  }, [isActionPostOrPut, loadingParam, module, getStateParamDataByModule]);

  return (
    <IconButton sx={sx} disabled={loading} onClick={onClick}>
      {children}
    </IconButton>
  );
};

export default CustomIcon;
