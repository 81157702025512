import { useState, useEffect } from 'react';
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps';

const MapDirections = ({ currentCoords, endCoords, myCenter, myZoom }) => {
  const map = useMap();
  const routesLibrary = useMapsLibrary('routes');
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);

  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(
      new routesLibrary.DirectionsRenderer({
        map,
        suppressMarkers: true,
        preserveViewport: myCenter || myZoom,
      })
    );
  }, [routesLibrary, map, myCenter, myZoom]);

  useEffect(() => {
    if (!directionsService || !directionsRenderer) return;
    directionsService
      .route({
        origin: currentCoords,
        destination: endCoords,
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true,
      })
      .then(response => {
        directionsRenderer?.setDirections(response);
      });
  }, [directionsService, directionsRenderer, currentCoords, endCoords]);

  return null;
};

export default MapDirections;
