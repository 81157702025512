import React from 'react';
import { Skeleton } from '@mui/material';
import CustomResGrid from 'ui-component/CustomResponsiveGrid/CustomResGrid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import Divider from '@mui/material/Divider';

export function CartsSkeleton() {
  return (
    <>
      <CustomResGrid sx={{ position: 'relative' }}>
        <Box columnsizes={{ sm: 12, xs: 12, md: 8, lg: 8 }} sx={{}}>
          <Paper sx={{ padding: 2, marginBottom: 1 }}>
            <CustomTypography variant="h4" sx={{ color: '#364152', paddingY: 1 }}>
              ORDER SUMMARY
            </CustomTypography>
            <Divider sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 1 }} />
            {/* List of skeletons for cart items */}
            {[...Array(3)].map((_, i) => (
              <Box key={i} sx={{ display: 'flex', alignItems: 'center', py: 1 }}>
                <Skeleton variant="rectangular" width={120} height={80} />
                <Box sx={{ flexGrow: 1, pl: 2 }}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" sx={{ fontSize: '12px' }} />
                </Box>
                <Skeleton variant="text" sx={{ width: 50 }} />
              </Box>
            ))}
          </Paper>
          {/* Skeleton for User Address (conditionally rendered?) */}
          <Paper sx={{ padding: 2, marginY: 1 }}>
            <CustomTypography variant="h4" sx={{ color: '#364152', paddingY: 1 }}>
              PAYMENT DETAILS
            </CustomTypography>
            <Divider sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 1 }} />
            <Paper sx={{ paddingTop: 2 }}>
              <Skeleton variant="text" />
            </Paper>
          </Paper>
        </Box>
        <Box columnsizes={{ sm: 12, xs: 12, md: 4, lg: 4 }}>
          <Paper sx={{ padding: 2 }}>
            {/* Skeleton for Price Details */}
            <CustomTypography variant="h4" sx={{ color: '#364152', paddingY: 1 }}>
              PRICE DETAILS
            </CustomTypography>
            <Divider sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 1 }} />
            <Box sx={{ paddingTop: 1, paddingLeft: 2, paddingBottom: 2 }}>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Box>
            <Box sx={{ paddingLeft: 2, paddingBottom: 2 }}>
              <Skeleton variant="text" />
            </Box>
          </Paper>
        </Box>
      </CustomResGrid>
      {/* Skeleton for Checkout/Place Order Button */}
      <CustomResGrid>
        <Paper elevation={1} sx={{ position: 'sticky', bottom: 0, left: 0, width: '100%', zIndex: 1 }}>
          <Box sx={{ textAlign: 'right', justifyContent: 'center' }}>
            <Skeleton variant="text" width={150} height={40} />
          </Box>
        </Paper>
      </CustomResGrid>
    </>
  );
}

export default CartsSkeleton;
