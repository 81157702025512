import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
// import CustomRightButton from 'ui-component/CustomRightButton';
import {
  CONST_GET,
  CONST_GETALL,
  CONST_MODULE_ORDERS,
  CONST_ORDER_COD,
  CONST_ORDER_STATUS_DELIVERED,
  CONST_ORDER_STATUS_ORDER_COMPLETED,
  CONST_ORDER_STATUS_ORDER_PLACED,
  CONST_POST,
} from 'utils/constants';
import useAppContext from 'store/useAppContext';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import { handleApiAction } from 'utils/apiUtils/apiAction';
import OrderCollapse from './OrderCollapse';
import { getLocalUser, isUserAdmin } from 'utils/commonFunc';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomPagination from 'ui-component/CustomPagination/CustomPagination';
import OrderCancelConfirmDialog from '../clients/orders/OrderCancelConfirmDialog';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const module = CONST_MODULE_ORDERS;
export default function RecentOrders({ view = 'recent_orders' }) {
  // view: recent_orders(new orders)(admin), failed_orders(admin), client_orders
  const theme = useTheme();
  const {
    crudMethods,
    ordersMethods: { setOrdersState = undefined } = {},
    ordersState: {
      // recent-orders (admin)
      deliveryOrdersFetching,
      deliveryOrders,
      // completed-orders (admin)
      completedOrdersFetching,
      completedOrdersData,
      completedOrdersPage: page,
      completedOrdersSize: size,
      // client-orders
      allOrdersForClientFetching,
      allOrdersForClient,
      // failed-orders (admin)
      failedOrdersData,
      failedOrdersFetching,
      // failedOrderItemsFetching,
      // Order reject/cancel
      cancelOrderConfirmModalOpen,
      acceptOrderRejectOrderSessionId,
      acceptOrderRejectOrderType,
      cancellationReasonsFetching,
      allRiders,
      allRidersFetching,
    },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const [orderItemsFetchingSessionId, setOrderItemsFetchingSessionId] = useState(0);
  const [activeTab, setActiveTab] = React.useState(0);
  const { search } = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const queryTab = queryParams.get('tab');
  const defaultTab = queryTab === 'completed_orders' ? 1 : 0;
  const isRoleAdmin = isUserAdmin();
  const cancelConfirmDialogRef = useRef(null);
  const [assigingRiderOrderId, setAssigingRiderOrderId] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    navigate(`?tab=${newValue === 1 ? 'completed_orders' : 'new_orders'}`);
  };

  useEffect(() => {
    if (isRoleAdmin && view === 'recent_orders') {
      setActiveTab(defaultTab);
      !queryTab && navigate(`?tab=new_orders`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTab, queryTab, isRoleAdmin]);

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState: setOrdersState || getMethodByModule({ module }),
      module,
      ...(activeTab && {
        page,
        size,
      }),
    }),
    [crudMethods, setOrdersState, getMethodByModule, page, size, activeTab]
  );

  const fetchAllRiders = async () => {
    await handleApiAction({
      ...commonParams,
      action: CONST_GET,
      apiPropName: 'getAllRiders',
      stateParam: 'allRiders',
      loadingParam: 'allRidersFetching',
      riderStatus: 'AVAILABLE',
    });
  };

  const fetchCancellationReasons = async () => {
    await handleApiAction({
      ...commonParams,
      action: CONST_GET,
      apiPropName: 'getCancellationReasons',
      stateParam: 'cancellationReasons',
      loadingParam: 'cancellationReasonsFetching',
    });
  };

  useEffect(() => {
    if (!cancellationReasonsFetching) {
      fetchCancellationReasons();
    }
    if (!allRidersFetching && !allRiders?.length) {
      fetchAllRiders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orderPayloadData = useMemo(
    () => ({
      0: {
        orderType: CONST_ORDER_COD,
        stateParam: 'deliveryOrders',
        loadingParam: 'deliveryOrdersFetching',
        itemLoadingParam: 'deliveryOrderItemsFetching',
      },
      1: {
        orderType: CONST_ORDER_COD,
        stateParam: 'completedOrdersData',
        loadingParam: 'completedOrdersFetching',
        itemLoadingParam: 'completedOrderItemsFetching',
      },
    }),
    []
  );

  const fetchInternalOrders = useCallback(
    (forceFetch = false) => {
      if (
        isRoleAdmin &&
        view === 'recent_orders' &&
        ((!activeTab && !deliveryOrdersFetching) || (activeTab && !completedOrdersFetching) || forceFetch)
      ) {
        handleApiAction({
          ...commonParams,
          action: CONST_GETALL,
          apiPropName: activeTab ? 'getCompletedOrders' : 'getInternalOrders',
          stateParam: orderPayloadData[activeTab].stateParam,
          loadingParam: orderPayloadData[activeTab].loadingParam,
          orderType: CONST_ORDER_COD,
        });
      }
    },
    [activeTab, commonParams, completedOrdersFetching, deliveryOrdersFetching, orderPayloadData, isRoleAdmin, view]
  );

  const user = getLocalUser();
  const fetchAllOrdersForClient = useCallback(() => {
    if (!isRoleAdmin && !allOrdersForClientFetching && view === 'client_orders') {
      handleApiAction({
        ...commonParams,
        action: CONST_GETALL,
        apiPropName: 'getAllOrdersForClient',
        stateParam: 'allOrdersForClient',
        loadingParam: 'allOrdersForClientFetching',
        orderType: CONST_ORDER_COD,
        userName: user?.userId,
      });
    }
  }, [allOrdersForClientFetching, commonParams, user?.userId, isRoleAdmin, view]);

  const fetchAllFailedOrders = useCallback(() => {
    if (isRoleAdmin && !failedOrdersFetching && view === 'failed_orders') {
      handleApiAction({
        ...commonParams,
        action: CONST_GETALL,
        apiPropName: 'getFailedOrdersByAdmin',
        stateParam: 'failedOrdersData',
        loadingParam: 'failedOrdersFetching',
        orderType: CONST_ORDER_COD,
        userName: user?.userId,
      });
    }
  }, [failedOrdersFetching, commonParams, user?.userId, isRoleAdmin, view]);

  useEffect(() => {
    fetchInternalOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, isRoleAdmin, page, size]);

  useEffect(() => {
    fetchAllOrdersForClient();
    fetchAllFailedOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRoleAdmin]);

  const getOrdersBySession = async ({ orderType, orderSessionId, orderStatus, forceFetch }) => {
    const isOrderComplete = [CONST_ORDER_STATUS_DELIVERED, CONST_ORDER_STATUS_ORDER_COMPLETED].includes(orderStatus);
    setOrderItemsFetchingSessionId(orderSessionId);
    await handleApiAction({
      ...commonParams,
      action: CONST_GETALL,
      apiPropName: 'getOrderItemsBySession',
      stateParam: `${orderType?.toLowerCase()}OrderItemsData${orderSessionId}`,
      orderSessionId,
      loadingParam:
        view === 'recent_orders'
          ? orderPayloadData[activeTab]?.itemLoadingParam
          : view === 'failed_orders'
          ? 'failedOrderItemsFetching'
          : 'allOrdersItemsForClientFetching',
    });
    if (!activeTab && !isOrderComplete) {
      fetchInternalOrders(forceFetch); // forceFetch: true
    }
    if (!isOrderComplete) {
      fetchAllOrdersForClient();
    }
    setOrderItemsFetchingSessionId(0);
  };

  const updateOrderStatusCb = async ({ trackStatus, orderType, orderSessionId }) => {
    await handleApiAction({
      ...commonParams,
      action: CONST_POST,
      apiPropName: 'updateOrderStatus',
      orderSessionId,
      trackStatus,
      loadingParam: 'orderStatusUpdating',
    });
    getOrdersBySession({ orderType, orderSessionId });
  };

  const onApproveOrCancelSuccess = (orderType = '', orderSessionId = 0) => {
    getOrdersBySession({
      orderSessionId: orderSessionId || acceptOrderRejectOrderSessionId,
      orderType: orderType || acceptOrderRejectOrderType,
      orderStatus: CONST_ORDER_STATUS_ORDER_PLACED,
      forceFetch: true,
    });
    setContextState({
      setState: getMethodByModule({ module }),
      paramName: '',
      paramValue: {
        cancelOrderConfirmModalOpen: false,
        acceptOrderRejectOrderSessionId: 0,
        acceptOrderRejectOrderStatus: '',
        acceptOrderRejectOrderItemId: 0,
        acceptOrderRejectOrderType: '',
      },
      isFullObjectUpdate: true,
    });
  };

  const assignRiderToOrder = async (rideOrderId, riderUserName) => {
    setAssigingRiderOrderId(rideOrderId);
    await handleApiAction({
      crudMethods,
      setState: setOrdersState,
      module,
      action: CONST_POST,
      apiPropName: 'assignRiderByAdmin',
      loadingParam: 'assignRiderByAdminUpdating',
      rideOrderId,
      riderUserName,
    });
    fetchInternalOrders(true);
    setAssigingRiderOrderId(0);
  };

  if (isRoleAdmin && view === 'recent_orders') {
    return (
      <>
        <Box>
          {/* {!!activeTab && (
          <CustomRightButton
            label="Create Order"
            handleClick={() => {
              // fetchCategories();
              // toggleModal();
            }}
          />
        )} */}
          <Paper
            sx={{
              borderRadius: 1,
              // marginTop: !activeTab ? 6 : 0
            }}
            elevation={1}>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              // indicatorColor="secondary"
              textColor="inherit"
              variant="fullWidth"
              aria-label="full width tabs example">
              <Tab label="New Orders" {...a11yProps(0)} />
              <Tab label="Completed Orders" {...a11yProps(1)} />
            </Tabs>
          </Paper>
          <Box>
            <TabPanel value={activeTab} index={0} dir={theme.direction}>
              <OrderCollapse
                orderType={CONST_ORDER_COD}
                recentOrders={deliveryOrders}
                getOrdersBySession={getOrdersBySession}
                updateOrderStatusCb={updateOrderStatusCb}
                ordersFetching={deliveryOrdersFetching}
                orderItemsFetchingSessionId={orderItemsFetchingSessionId}
                isManual={true}
                manualConfirmButtonLabel="Accept"
                manualPassLoading={cancelConfirmDialogRef.current?.acceptOrRejectLoading}
                manualOnConfirm={cancelConfirmDialogRef.current?.acceptOrRejectHandler}
                assignRiderToOrder={assignRiderToOrder}
                assigingRiderOrderId={assigingRiderOrderId}
              />
            </TabPanel>
            <TabPanel value={activeTab} index={1} dir={theme.direction}>
              <OrderCollapse
                orderType={CONST_ORDER_COD}
                recentOrders={completedOrdersData?.content}
                getOrdersBySession={getOrdersBySession}
                updateOrderStatusCb={updateOrderStatusCb}
                ordersFetching={completedOrdersFetching}
                orderItemsFetchingSessionId={orderItemsFetchingSessionId}
              />
              <CustomPagination
                listData={completedOrdersData}
                page={page}
                size={size}
                setState={setOrdersState}
                isFetching={allOrdersForClientFetching}
                pageParamName="completedOrdersPage"
                sizeParamName="completedOrdersSize"
              />
            </TabPanel>
          </Box>
        </Box>
        <OrderCancelConfirmDialog
          cancelConfirmDialogRef={cancelConfirmDialogRef}
          cancelOrderConfirmModalOpen={cancelOrderConfirmModalOpen}
          onApproveOrCancelSuccess={onApproveOrCancelSuccess}
        />
      </>
    );
  }
  if (isRoleAdmin && view === 'failed_orders') {
    return (
      <OrderCollapse
        orderType={CONST_ORDER_COD}
        recentOrders={failedOrdersData}
        getOrdersBySession={getOrdersBySession}
        ordersFetching={failedOrdersFetching}
        orderItemsFetchingSessionId={orderItemsFetchingSessionId}
      />
    );
  }
  if (!isRoleAdmin && view === 'client_orders') {
    return (
      <>
        <OrderCollapse
          orderType={CONST_ORDER_COD}
          recentOrders={allOrdersForClient}
          getOrdersBySession={getOrdersBySession}
          updateOrderStatusCb={updateOrderStatusCb}
          ordersFetching={allOrdersForClientFetching}
          orderItemsFetchingSessionId={orderItemsFetchingSessionId}
        />
        <OrderCancelConfirmDialog
          cancelConfirmDialogRef={cancelConfirmDialogRef}
          cancelOrderConfirmModalOpen={cancelOrderConfirmModalOpen}
          onApproveOrCancelSuccess={onApproveOrCancelSuccess}
        />
      </>
    );
  }
}
