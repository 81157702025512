const productsState = {
  // Data
  productsData: {}, // getAll
  searchProductList: [], // searchProducts
  productsForm: {}, // post/put
  productsOne: {}, // get
  productsView: {}, // view
  page: 1,
  size: 10,
  // Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  // Form
  productsModalOpen: false,
  formState: {},
  productAvailabilityUpdating: false,
  // Filter
  categoryProducts: [],
  categoryProductsFetching: false,
};

export default productsState;
