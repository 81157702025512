import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/system/Box';
import Skeleton from '@mui/material/Skeleton';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';

export default function OrderCollapseSkeleton({ keyIndex = 0 }) {
  return (
    <Accordion elevation={1}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${keyIndex + 1}-content`}
        id={`panel${keyIndex + 1}-header`}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} md={2}>
            <CustomFlexRow>
              <Skeleton variant="text" width={30} />
              <CustomTypography sx={{ mt: 0.2, ml: 1 }} variant="subtitle">
                <Skeleton variant="text" width={50} />
              </CustomTypography>
            </CustomFlexRow>
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomFlexRow>
              <Skeleton variant="circular" width={20} height={20} />
              <CustomTypography sx={{ mt: 0.3, ml: 1, color: 'green', fontWeight: 'bolder' }} variant="subtitle">
                <Skeleton variant="text" width={80} />
              </CustomTypography>
            </CustomFlexRow>
          </Grid>
          <Grid item xs={12} md={3}>
            <CustomFlexRow>
              <Skeleton variant="circular" width={20} height={20} />
              <CustomTypography sx={{ mt: 0.2, ml: 1 }} variant="subtitle">
                <Skeleton variant="text" width={50} />
              </CustomTypography>
            </CustomFlexRow>
          </Grid>
          <Grid item xs={12} md={4}>
            <CustomFlexRow>
              <Skeleton variant="circular" width={20} height={20} />
              <CustomTypography sx={{ mt: 0.1, ml: 1 }} variant="subtitle">
                <Skeleton variant="text" width={100} />
              </CustomTypography>
            </CustomFlexRow>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ textAlign: 'right' }}>
          <Skeleton variant="text" width={50} />
        </Box>
        <Skeleton variant="text" width={300} />
      </AccordionDetails>
    </Accordion>
  );
}
