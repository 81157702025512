import React, { useState } from 'react';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import { ChatOutlined, Restaurant } from '@mui/icons-material';
import { Box, OutlinedInput, Switch } from '@mui/material';
import VoucherInput from './VoucherInput';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import useAppContext from 'store/useAppContext';

const SpecialRequest = () => {
  const [showAddonInput, setShowAddonInput] = useState(false);
  const {
    vouchersMethods: { setVouchersState },
    vouchersState: { cutleryReq, specialComments },
  } = useAppContext();

  const toggleAdditionalInput = () => {
    setShowAddonInput(prevState => !prevState);
  };

  const setVoucherContext = updateState => {
    if (Object.keys(updateState || {})?.length) {
      setContextState({
        setState: setVouchersState,
        paramValue: updateState,
      });
    }
  };

  return (
    <>
      <Box>
        <CustomTypography
          variant="h4"
          sx={{
            padding: 2,
            fontWeight: 'bold',
          }}>
          SPECIAL REQUEST
        </CustomTypography>
        <CustomFlexRow>
          <CustomFlexRow sx={{ alignItems: 'center', pl: 2, width: '75%' }}>
            <Restaurant fontSize="small" />
            <Box sx={{ px: 2 }}>
              <CustomTypography>Cutlery</CustomTypography>
              <CustomTypography variant="caption" sx={{ whiteSpace: 'normal' }}>
                Reduce waste. Select this option only if you really need cutlery.
              </CustomTypography>
            </Box>
          </CustomFlexRow>
          <Box sx={{ float: 'right', alignContent: 'center' }}>
            <Switch
              checked={cutleryReq}
              color="secondary"
              onChange={e => {
                setVoucherContext({
                  cutleryReq: e.target.checked,
                });
              }}
            />
          </Box>
        </CustomFlexRow>
        <CustomFlexRow sx={{ px: 2, pt: 2, alignItems: 'center' }}>
          <ChatOutlined fontSize="small" />
          <Box sx={{ px: 2, cursor: 'pointer' }} onClick={toggleAdditionalInput}>
            <CustomTypography>Anything Special?</CustomTypography>
            <CustomTypography variant="caption">Anything else we need to know?</CustomTypography>
          </Box>
        </CustomFlexRow>
        {showAddonInput && (
          <Box sx={{ mx: 2, mt: 1 }}>
            <OutlinedInput
              fullWidth
              id="outlined-adornment-password"
              placeholder="Enter special comments"
              onChange={e => {
                setVoucherContext({
                  specialComments: e.target.value,
                });
              }}
              value={specialComments}
            />
          </Box>
        )}
        <CustomTypography
          variant="h4"
          sx={{
            mt: 2,
            padding: 2,
            borderTop: 1,
            borderColor: 'divider',
            fontWeight: 'bold',
          }}>
          SAVE ON YOUR CARD
        </CustomTypography>
        <VoucherInput />
      </Box>
    </>
  );
};

export default SpecialRequest;
