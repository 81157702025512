import {
  formActionGet,
  formActionGetAll,
  formActionPost,
  formActionPut,
  formActionPatch,
  formActionDelete,
} from './crudUtils';

const apiAction = ({
  crudMethods,
  setState,
  // pagination
  page,
  size,
  // passActionInfo
  action,
  module,
  orgId,
  idName,
  getId,
  payload,
  delId,
  // new params
  apiPropName,
  loadingParam,
  ...rest
}) => {
  const commonParams = {
    ...rest,
    module,
    payload,
    apiPropName,
    loadingParam,
    orgId,
    idName,
  };
  // CRUD HANDLER
  const apiActions = action => {
    const actions = {
      getAll: () => formActionGetAll({ ...commonParams, page, size }),
      get: () => formActionGet({ ...commonParams, [idName]: getId }),
      post: () => formActionPost(commonParams),
      put: () => formActionPut({ ...commonParams, [idName]: payload?.[idName] }),
      patch: () => formActionPatch({ ...commonParams, ...(payload?.[idName] && { [idName]: payload?.[idName] }) }),
      delete: () => formActionDelete({ ...commonParams, [idName]: delId }),
    };

    return actions[action]?.(); // Use optional chaining to handle missing actions
  };

  return crudMethods?.crudService({
    ...rest,
    ...apiActions(action),
    setState,
  });
};

export default apiAction;

export const handleApiAction = passActionParams => {
  const {
    // From componet
    crudMethods,
    // From component or context hook
    setState,
    // Module const
    module,
    // Action
    action,
    payload,
    // Additional
    page,
    size,
    apiPropName,
    loadingParam,
    ...rest // idName, orgId, getId, getIdName, delId, refetchAll, ...rest
  } = passActionParams;
  return apiAction({
    crudMethods,
    setState,
    module,
    action,
    payload,
    page,
    size,
    apiPropName,
    loadingParam,
    ...rest,
  });
};
