import React, { useState, useEffect, useCallback } from 'react';
import { Box, FormControl, IconButton, List, ListItem, ListItemText } from '@mui/material';
import { Delete, AddCircleOutline } from '@mui/icons-material';
import CustomInput from 'ui-component/forms/CustomInput';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import CustomSelect from 'ui-component/forms/CustomSelect';
import { dayMapper, weekDaysForSelect } from 'utils/variables';
import useAppContext from 'store/useAppContext';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import { END_TIME_VALIDATION_ERROR } from 'utils/constants';
import CustomLoader from 'ui-component/CustomLoader/CustomLoader';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';
import CustomIcon from 'ui-component/CustomIcon/CustomIcon';
import { IconPencil, IconEye } from '@tabler/icons';
import CustomTooltip from 'ui-component/CustomTooltip/CustomTooltip';
import { isUserAdmin } from 'utils/commonFunc';
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';

const dayOrder = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];

const groupByDayOfWeek = slots => {
  const daysSlotObj = slots.reduce((acc, slot) => {
    const day = slot.dayOfWeek;
    acc[day] = acc[day] || [];
    acc[day].push(slot);
    return acc;
  }, {});
  const resDaySlot = {};
  for (const orderDay of dayOrder) {
    if (daysSlotObj?.[orderDay]) {
      resDaySlot[orderDay] = daysSlotObj[orderDay];
    }
  }
  return resDaySlot;
};

export const formatTime = (timeStr = '', options = {}) => {
  const { isLocal } = options;
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const date = now.getDate();
  const splitTime = timeStr.split(':');
  const dateTimeString = `${year}-${month}-${date} ${splitTime[0]}:${splitTime[1]}:00`;
  return isLocal ? format(new Date(dateTimeString), 'hh:mm a') : new Date(dateTimeString).getTime();
};

const useStyles = makeStyles(theme => ({
  timeSlotContainer: {
    marginBottom: theme.spacing(1),
  },
  dayOfWeek: {
    fontWeight: theme.typography.fontWeightBold,
  },
  startTime: {
    color: theme.palette.text.secondary,
  },
}));

export const intialTimeSlots = { startTime: '', endTime: '', dayOfWeek: '' };
const BranchTimings = ({ addOrUpdateTimeSlot, deleteTimeSlot }) => {
  const isAdmin = isUserAdmin();
  const {
    branchesState: { timeSlots, timeSlotsParam, branchTimings, branchTimingsUpdating, branchTimingsDeleting },
    branchesMethods: { setBranchesState },
  } = useAppContext();

  const classes = useStyles();
  const [effectHasRun, setEffectHasRun] = useState(false);
  const [timeError, setTimeError] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [removingInx, setRemovingInx] = useState(null);

  const updateTimeSlots = paramValue => {
    setContextState({
      setState: setBranchesState,
      paramName: timeSlotsParam, // 'timeSlots'
      paramValue: [...paramValue],
    });
  };

  useEffect(() => {
    if (!effectHasRun && Array.isArray(branchTimings) && branchTimings?.length) {
      updateTimeSlots([...branchTimings.map(timeObj => ({ ...timeObj }))]);
      setEffectHasRun(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchTimings]);

  const handleSlotChange = (index, key, slot) => {
    const updatedSlots = [...timeSlots];
    updatedSlots[index][key] = slot;
    updateTimeSlots(updatedSlots);
  };

  const isModified = useCallback(
    (slot = {}, index) => {
      const existSlot = branchTimings[index];
      const exSt = formatTime(existSlot?.startTime);
      const exEt = formatTime(existSlot?.endTime);
      const cuSt = formatTime(slot?.startTime);
      const cuEt = formatTime(slot?.endTime);
      const isChanged = existSlot?.timeSlotId && (exSt !== cuSt || exEt !== cuEt);
      return isChanged;
    },
    [branchTimings]
  );

  const handleAddOrUpdateTimeSlot = async (slot, index, onlyUpdate = false) => {
    const startTime = formatTime(slot?.startTime);
    const endTime = formatTime(slot?.endTime);
    const isEndTimeInvalid = slot?.endTime && startTime >= endTime;
    setTimeError({
      [index]: isEndTimeInvalid ? END_TIME_VALIDATION_ERROR : '',
    });
    if (!isEndTimeInvalid) {
      if (!slot.timeSlotId || isModified(slot, index)) {
        const res = await addOrUpdateTimeSlot(slot);
        res?.ok && updateTimeSlots([...(timeSlots || []), ...(!onlyUpdate ? [intialTimeSlots] : [])]);
      } else {
        updateTimeSlots([...(timeSlots || []), intialTimeSlots]);
      }
    }
  };

  const delAndUpdateContext = index => {
    const updatedSlots = [...timeSlots];
    updatedSlots.splice(index, 1);
    updateTimeSlots(updatedSlots);
  };

  const removeTimeSlot = async (index, timeSlotId = 0) => {
    if (timeSlots.length > 1 && timeSlotId) {
      setRemovingInx(index);
      const res = await deleteTimeSlot(timeSlotId);
      res?.ok && delAndUpdateContext(index);
      setRemovingInx(null);
    } else if (timeSlots.length > 1) {
      delAndUpdateContext(index);
    }
  };

  const formattedTimeSlots = !isEdit && groupByDayOfWeek(branchTimings);

  return (
    <Box sx={{ width: '50%' }}>
      <CustomFlexRow sx={{ alignItems: 'center' }}>
        <CustomTypography variant="h3" sx={{ mr: 1 }}>
          Timings
        </CustomTypography>
        {isAdmin && (
          <CustomIcon name="Edit" onClick={() => setIsEdit(prevIsEdit => !prevIsEdit)}>
            {isEdit ? (
              <CustomTooltip content="View">
                <IconEye />
              </CustomTooltip>
            ) : (
              <CustomTooltip content="Edit">
                <IconPencil />
              </CustomTooltip>
            )}
          </CustomIcon>
        )}
      </CustomFlexRow>
      {!isEdit && (
        <Box sx={{ mt: 1, ml: 3 }}>
          {Object.entries(formattedTimeSlots).map(([day, slots]) => (
            <div key={day} className={classes.timeSlotContainer}>
              <CustomTypography variant="h5" className={classes.dayOfWeek}>
                {dayMapper[day]}
              </CustomTypography>
              <List>
                {slots?.map(slot => (
                  <ListItem key={slot.timeSlotId} sx={{ padding: 0, pl: 1 }}>
                    <ListItemText
                      primary={
                        <CustomTypography variant="subtitle">
                          {`${formatTime(slot.startTime, { isLocal: true })} - ${formatTime(slot.endTime, {
                            isLocal: true,
                          })}`}
                        </CustomTypography>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </div>
          ))}
        </Box>
      )}
      {isEdit && (
        <FormControl component="fieldset">
          {timeSlots.map((slot, index) => {
            const isNotRecent = timeSlots.length > index + 1;
            const isStartTimeEmpty = !slot.startTime;
            const isTimeModified = isModified(slot, index);
            return (
              <>
                <CustomSelect
                  options={{ selectOptions: weekDaysForSelect }}
                  value={slot.dayOfWeek}
                  fieldName="dayOfWeek"
                  fieldLabel="Day"
                  fieldValue={slot.dayOfWeek}
                  onChange={(value, name) => handleSlotChange(index, name, value)}
                  sx={{ mt: 3, width: 200 }}
                  variant="outlined"
                  size="small"
                  disabled={!!slot?.timeSlotId}
                />
                <CustomFlexRow key={slot?.timeSlotId || index} sx={{ mt: 2 }}>
                  <CustomInput
                    type="time"
                    fieldName="startTime"
                    fieldLabel="Open Time"
                    fieldValue={slot.startTime}
                    onChange={(value, name) => handleSlotChange(index, name, value)}
                    sx={{ mr: 1, ml: 1, width: 125 }}
                    variant="outlined"
                    size="small"
                    // disabled={isNotRecent}
                  />
                  <CustomInput
                    type="time"
                    fieldName="endTime"
                    fieldLabel="Close Time"
                    fieldValue={slot.endTime}
                    onChange={(value, name) => handleSlotChange(index, name, value)}
                    sx={{ mr: 1, width: 125 }}
                    variant="outlined"
                    size="small"
                    disabled={isStartTimeEmpty}
                    options={{ validationError: timeError?.[index] }}
                  />
                  <CustomTooltip content="Delete time">
                    <IconButton onClick={() => removeTimeSlot(index, slot?.timeSlotId)}>
                      {branchTimingsDeleting && index === removingInx ? (
                        <CustomLoader size={15} />
                      ) : (
                        <Delete fontSize="15" />
                      )}
                    </IconButton>
                  </CustomTooltip>
                  {isTimeModified && (
                    <CustomTooltip content="Update time">
                      <IconButton
                        onClick={() => handleAddOrUpdateTimeSlot(slot, index, true)}
                        disabled={!slot.dayOfWeek || !slot.startTime || !slot.endTime || branchTimingsUpdating}>
                        {branchTimingsUpdating ? <CustomLoader size={15} /> : <TaskAltRoundedIcon />}
                      </IconButton>
                    </CustomTooltip>
                  )}
                  {!isNotRecent && (
                    <CustomTooltip content="Add new time">
                      <IconButton
                        onClick={() => handleAddOrUpdateTimeSlot(slot, index)}
                        disabled={!slot.dayOfWeek || !slot.startTime || !slot.endTime || branchTimingsUpdating}>
                        {branchTimingsUpdating ? <CustomLoader size={15} /> : <AddCircleOutline />}
                      </IconButton>
                    </CustomTooltip>
                  )}
                </CustomFlexRow>
              </>
            );
          })}
        </FormControl>
      )}
    </Box>
  );
};

export default BranchTimings;
