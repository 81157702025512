import React, { useEffect, useMemo, useState } from 'react';
import useAppContext from 'store/useAppContext';
import {
  CONST_MODULE_CARTS,
  CONST_DELETE,
  CONST_GETALL,
  CONST_PUT,
  CONST_PAGE_CART,
  CONST_PLACE_ORDER,
  CONST_CHECKOUT,
  CONST_ADD_MORE_ITEMS,
  CONST_LABEL_COD,
  CONST_PAGE_CHECKOUT,
  CONST_LABEL_DATA,
  CONST_LOCAL_STORAGE_USER_ADDRESS,
  CONST_MODULE_ORDERS,
  CONST_POST,
  CONST_LABEL_ONLINE_PAY,
  CONST_PAY_COD,
  CONST_PAY_ONLINE,
  CONST_MODULE_PAYMENTS,
  CONST_ORDER_STATUS_PAYMENT_PENDING,
} from 'utils/constants';
// Pagination
import { handleApiAction } from 'utils/apiUtils/apiAction';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';
import CartCard from './CartCard';
import CustomResGrid from 'ui-component/CustomResponsiveGrid/CustomResGrid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import { getLocalStorage, getLocalUser } from 'utils/commonFunc';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import Divider from '@mui/material/Divider';
import NotFound from 'views/pages/notfound';
import { useNavigate } from 'react-router-dom';
import UserAddress from './UserAddress';
import CustomRadioGroup from 'ui-component/forms/CustomRadioGroup';
import CartsSkeleton from './CartsSkeleton';
import PriceDetailsCard, { getSmallOrderFee } from './PriceDetailsCard';
import CustomTwoInlineColumns from 'ui-component/CustomTwoInlineColumns/CustomTwoInlineColumns';

export const getDiscountAmount = (productPrice, discountPercentage) => productPrice * (discountPercentage / 100);
export const getProductPrice = (productPrice, quantity = 1) => productPrice * quantity;
export const getTotalDiscountAmount = (discountAmountPerProduct, quantity = 1) => discountAmountPerProduct * quantity;
export const getTotalPriceWithDiscount = (productPrice, discountAmountPerProduct, quantity = 1) => {
  return (productPrice + discountAmountPerProduct) * quantity;
};
export function calculateProductPrices(products) {
  const result = {
    totalPriceToShow: 0,
    totalDiscountedAmount: 0,
    totalPriceWithDiscount: 0,
  };

  products.forEach(product => {
    const productPrice = product.productPrice;
    const discountPercentage = product.discountPercent;
    const quantity = product.quantity || 1;
    const discountAmountPerProduct = getDiscountAmount(productPrice, discountPercentage);
    result.totalPriceToShow += getProductPrice(productPrice, quantity);
    result.totalDiscountedAmount += getTotalDiscountAmount(discountAmountPerProduct, quantity);
    result.totalPriceWithDiscount += getTotalPriceWithDiscount(productPrice, discountAmountPerProduct, quantity);
  });

  return result;
}

export const getHeading = heading => (
  <CustomTypography variant="h4" sx={{ color: '#364152', paddingY: 1 }}>
    {heading}
  </CustomTypography>
);

const idName = 'cartItemId';
const module = CONST_MODULE_CARTS;
//====== Main Component ======//
const Carts = ({ viewPage = CONST_PAGE_CART }) => {
  const navigate = useNavigate();
  const {
    crudMethods,
    cartsState: { page, size, getAllFetching, cartsData, deleting, putting },
    vouchersState: {
      serviceFee = 0,
      smallOrderFee = 0,
      cutleryReq = false,
      specialComments = '',
      voucherCode = '',
      deliveryFee = 0,
    },
  } = useAppContext();
  const { getMethodByModule } = useStoreAccessByModule();
  const userAddress = getLocalStorage(CONST_LOCAL_STORAGE_USER_ADDRESS);
  const [paymentMode, setPaymentMode] = useState(CONST_PAY_COD);
  const user = getLocalUser();
  const [paymentLoading, setPaymentLoading] = useState(false);

  const commonParams = useMemo(
    () => ({
      crudMethods,
      setState: getMethodByModule({ module }),
      module,
      page,
      size,
    }),
    [crudMethods, getMethodByModule, page, size]
  );

  useEffect(() => {
    handleApiAction({
      ...commonParams,
      action: CONST_GETALL,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchAll = () => handleApiAction({ ...commonParams, action: CONST_GETALL });

  const postOrPut = async payload => {
    const user = getLocalUser();
    await handleApiAction({
      ...commonParams,
      action: CONST_PUT,
      payload: {
        ...payload,
        userName: user?.userId,
      },
      idName,
      refetchAll,
      // notification: {
      // show: true,
      //type: CONST_TYPE_SUCCESS,
      // message: `Quantity has been updated to ${payload?.quantity} successfully`,
      // },
    });
  };

  const deleteItem = async (delId, isLastIndex) => {
    return handleApiAction({
      ...commonParams,
      action: CONST_DELETE,
      idName,
      delId,
      ...(isLastIndex && { refetchAll }),
    });
  };

  const createOrderPayment = async orderSessionId => {
    const totalPrices = calculateProductPrices(cartsData);
    const finalSmallOrderFee = getSmallOrderFee(totalPrices.totalPriceToShow, smallOrderFee);
    const addOnFee = deliveryFee + finalSmallOrderFee + serviceFee;
    const totalAmount = totalPrices.totalPriceToShow + addOnFee;
    const generatePaymentLinkRes = await handleApiAction({
      crudMethods,
      module: CONST_MODULE_PAYMENTS,
      setState: getMethodByModule({ module: CONST_MODULE_PAYMENTS }),
      action: CONST_POST,
      apiPropName: 'createOrderPayment',
      loadingParam: 'createOrderPaymentPosting',
      payload: {
        orderSessionId,
        paymentMode,
        paymentStatus: CONST_ORDER_STATUS_PAYMENT_PENDING,
        amount: totalAmount,
        total: totalAmount,
        paymentDetailId: 0,
        userName: user?.userId,
      },
      idName,
    });
    if (generatePaymentLinkRes?.data?.paymentUrl) {
      window.location.href = generatePaymentLinkRes?.data?.paymentUrl;
      setPaymentLoading(false);
    } else {
      setPaymentLoading(false);
      navigate('/order-success?action=delete-cart');
    }
  };

  const placeOrder = async () => {
    const totalPrices = calculateProductPrices(cartsData);
    setPaymentLoading(true);
    const postData = cartsData.map((productCart, i) => ({
      orderItemId: 0,
      ...userAddress,
      ...productCart,
      paymentMode,
      serviceFee: i === 0 ? serviceFee : 0,
      smallOrderFee: i === 0 ? getSmallOrderFee(totalPrices.totalPriceWithDiscount, smallOrderFee) : 0,
      cutleryReq: i === 0 ? cutleryReq : null,
      specialComments: i === 0 ? specialComments : '',
      voucherCode: i === 0 ? voucherCode : '',
      deliveryCharge: i === 0 ? deliveryFee : 0,
    }));
    const res = await handleApiAction({
      crudMethods,
      module: CONST_MODULE_ORDERS,
      setState: getMethodByModule({ module: CONST_MODULE_ORDERS }),
      action: CONST_POST,
      payload: postData,
      idName,
    });
    if (res.ok) {
      createOrderPayment(res?.data?.orderSessionId);
    } else {
      setPaymentLoading(false);
    }
  };

  const isPageCart = viewPage === CONST_PAGE_CART;
  const isPageCheckout = viewPage === CONST_PAGE_CHECKOUT;

  const checkoutOrPlaceorderButton = (
    <Paper
      elevation={1}
      sx={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        width: '100%',
        zIndex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        p: 1,
        sm: 12,
        xs: 12,
        md: 8,
        lg: 8,
        mt: 1,
      }}
      columnsizes={{ sm: 12, xs: 12, md: 8, lg: 8 }}>
      <CustomTwoInlineColumns>
        {isPageCart ? (
          <CustomButton size="large" name={CONST_ADD_MORE_ITEMS} onClick={() => navigate('/products')} />
        ) : (
          <Box />
        )}
        <CustomButton
          module={CONST_MODULE_ORDERS}
          size="large"
          color="error"
          name={isPageCart ? CONST_CHECKOUT : CONST_PLACE_ORDER}
          onClick={() => {
            if (isPageCart) {
              navigate('/view-checkout');
            } else if (isPageCheckout) {
              placeOrder();
            }
          }}
          disabled={
            (isPageCheckout && !userAddress?.pinCode && !userAddress?.addressLine) ||
            getAllFetching ||
            putting ||
            deleting
          }
          showLoader
          passLoading={paymentLoading}
        />
      </CustomTwoInlineColumns>
    </Paper>
  );

  return (getAllFetching || putting) && !cartsData?.length ? (
    <CartsSkeleton />
  ) : (
    <>
      {cartsData?.length > 0 ? (
        <CustomResGrid sx={{ position: 'relative' }}>
          <Box columnsizes={{ sm: 12, xs: 12, md: 8, lg: 8 }} sx={{}}>
            {isPageCheckout && <UserAddress />}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}>
              <Paper sx={{ padding: 2, marginBottom: 1 }}>
                {getHeading('ORDER SUMMARY')}
                <Divider sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 1 }} />
                {cartsData?.map((cartItem, i) => {
                  return (
                    <Box
                      key={cartItem.cartItemId}
                      sx={
                        cartsData?.length !== i + 1 && {
                          borderBottom: 1,
                          borderColor: 'divider',
                        }
                      }>
                      <CartCard
                        cartItem={cartItem}
                        onQuantityChange={postOrPut}
                        deleteItem={deleteItem}
                        putting={putting}
                        deleting={deleting}
                      />
                    </Box>
                  );
                })}
              </Paper>
              {isPageCheckout && (
                <Paper sx={{ padding: 2, marginY: 1 }}>
                  {getHeading('PAYMENT DETAILS')}
                  <Divider sx={{ borderBottom: 1, borderColor: 'divider', paddingTop: 1 }} />
                  <Paper sx={{ paddingTop: 2 }}>
                    <CustomRadioGroup
                      module={CONST_MODULE_CARTS}
                      fieldValue={paymentMode}
                      fieldName="paymentMode"
                      options={{
                        selectOptions: [
                          { label: CONST_LABEL_COD, value: CONST_PAY_COD },
                          { label: CONST_LABEL_ONLINE_PAY, value: CONST_PAY_ONLINE },
                        ],
                        radioFontSize: 18,
                      }}
                      handleChange={setPaymentMode}
                    />
                  </Paper>
                </Paper>
              )}
            </Box>
          </Box>
          <Box columnsizes={{ sm: 12, xs: 12, md: 4, lg: 4 }}>
            <PriceDetailsCard viewPage={viewPage} carts={cartsData} />
            <Box>{checkoutOrPlaceorderButton}</Box>
          </Box>
        </CustomResGrid>
      ) : (
        <NotFound
          type={CONST_LABEL_DATA}
          message="Your Cart is Empty"
          subMessage="Add items to it now."
          navPage="/products"
        />
      )}
    </>
  );
};

export default Carts;
