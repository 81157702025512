import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Slide,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { setContextState } from 'utils/contextStoreUtils/setContextUtils';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import useStoreAccessByModule from 'utils/contextStoreUtils/useStoreAccessByModule';

const StyledDialog = styled(Dialog)(({ theme, width }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 0,
    backgroundColor: theme.palette.common.white,
    position: 'fixed',
    top: 0,
    right: 0,
    height: '100%',
    maxHeight: '100%',
    width: `${width}%`,
    maxWidth: `${width}%`,
    margin: 0,
    overflowY: 'auto',
    animation: '$slideRight 0.5s',
  },
  '@keyframes slideRight': {
    '0%': {
      transform: 'translateX(100%)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CustomSlideDialog({
  customSlideDialogRef,
  module = '',
  isForm = false,
  width = '30%',
  children = null,
  dialogHeader = '',
  handleSubmit,
  isEdit,
  resetForm,
  loading = false,
}) {
  const { getMethodByModule, getStateParamDataByModule } = useStoreAccessByModule();

  const modalParamName = `${module.toLowerCase()}ModalOpen`;
  const setToggleModal = () => {
    if (!loading) {
      resetForm?.();
      setContextState({
        setState: getMethodByModule({ module }),
        paramName: modalParamName,
        paramValue: !getStateParamDataByModule({ module, passStateParamName: modalParamName }),
      });
    }
  };

  const isModalOpen = getStateParamDataByModule({ module, passStateParamName: modalParamName });

  return (
    <StyledDialog
      ref={customSlideDialogRef}
      open={isModalOpen}
      onClose={setToggleModal}
      TransitionComponent={Transition}
      width={width}>
      <DialogTitle>
        <Typography variant="button">{dialogHeader || 'Header'}</Typography>
        <IconButton aria-label="close" onClick={setToggleModal} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      {isForm ? (
        <>
          <DialogContent>{children}</DialogContent>
          <Divider />
          <DialogActions>
            <CustomButton name="Cancel" handleClick={setToggleModal} isActionPostOrPut disabled={loading} />
            <CustomButton
              module={module}
              handleClick={handleSubmit}
              name="Save"
              showLoader
              isActionPostOrPut
              isEdit={!!isEdit}
              passLoading={loading}
            />
          </DialogActions>
        </>
      ) : (
        <DialogContent>{children}</DialogContent>
      )}
    </StyledDialog>
  );
}
