// assets
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { CONST_LABEL_FOOD, CONST_LABEL_PRODUCTS, CONST_LABEL_FOODS } from 'utils/constants';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const clientProducts = {
  id: 'client-products',
  title: `${CONST_LABEL_FOOD} ${CONST_LABEL_PRODUCTS}`,
  type: 'group',
  children: [
    {
      id: 'products',
      title: CONST_LABEL_FOODS,
      type: 'item',
      url: '/products',
      icon: FastfoodIcon,
      breadcrumbs: false,
    },
  ],
};

export default clientProducts;
