import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import { Navigate } from 'react-router-dom';

// login option 3 routing
const AuthLogin = Loadable(lazy(() => import('views/pages/authentication/auth/Login')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/auth/Register3')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = (isAuthenticated, { isRoleAdmin, isRoleCustomer, isRoleRider }) => {
  return {
    path: '/auth',
    element: !isAuthenticated ? (
      <MinimalLayout />
    ) : (
      <Navigate
        to={
          isRoleAdmin ? '/admin/dashboard' : isRoleCustomer ? '/products' : isRoleRider ? '/rider/delivery-orders' : ''
        }
      />
    ),
    children: [
      {
        path: 'signin',
        element: <AuthLogin />,
      },
      {
        path: 'signup',
        element: <AuthRegister3 />,
      },
    ],
  };
};

export default AuthenticationRoutes;
