// assets
import RoomServiceIcon from '@mui/icons-material/RoomService';
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const deliveryOrders = {
  id: 'deliveryOrders',
  title: 'Orders',
  type: 'group',
  children: [
    {
      id: 'default',
      title: 'Delivery Orders',
      type: 'item',
      url: '/rider/delivery-orders',
      icon: RoomServiceIcon,
      breadcrumbs: false,
    },
  ],
};

export default deliveryOrders;
