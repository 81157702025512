import React from 'react';
import TextField from '@mui/material/TextField';
import { CONST_INPUT_LABEL_SHRINK, CONST_INPUT_SIZE, CONST_INPUT_VARIANT } from 'utils/formUtils/commonUtils';

const CustomInput = props => {
  const {
    inputRef,
    module,
    fieldLabel = '',
    fieldName,
    fieldValue,
    options: { placeholder, validationError = '', multiline = false, maxRows = 3, maxLength } = {},
    type,
    handleChange,
    onChange,
    id = '',
    variant = '',
    sx = {},
    inputProps = {},
    disabled = false,
    ...rest
  } = props;
  return (
    <TextField
      ref={inputRef}
      sx={sx}
      fullWidth
      label={fieldLabel}
      placeholder={placeholder || `Enter ${fieldLabel}`}
      type={type || 'text'}
      InputLabelProps={{
        shrink: CONST_INPUT_LABEL_SHRINK,
      }}
      InputProps={inputProps}
      variant={variant || CONST_INPUT_VARIANT}
      size={CONST_INPUT_SIZE}
      id={id || `${module}_${fieldValue}`}
      name={fieldName}
      value={fieldValue}
      onChange={e => {
        if (typeof handleChange === 'function') {
          handleChange(e?.target?.value, fieldName);
        } else if (typeof onChange === 'function') {
          onChange(e?.target?.value, fieldName);
        }
      }}
      error={!!validationError}
      helperText={validationError || ''}
      multiline={multiline}
      maxRows={maxRows}
      // required={isReq}
      disabled={disabled}
      maxLength="10"
      {...rest}
    />
  );
};

export default CustomInput;
