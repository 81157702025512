// assets
import ViewListIcon from '@mui/icons-material/ViewList';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const clientOrders = {
  id: 'client-orders',
  title: 'Cart & Orders',
  type: 'group',
  children: [
    {
      id: 'carts',
      title: 'My Carts',
      type: 'item',
      url: '/view-cart',
      icon: ShoppingCartIcon,
      breadcrumbs: false,
    },
    {
      id: 'orders',
      title: 'My Orders',
      type: 'item',
      url: '/account/orders',
      icon: ViewListIcon,
      breadcrumbs: false,
    },
  ],
};

export default clientOrders;
