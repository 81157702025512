import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import ViewProduct from 'views/pages/clients/view-product/ViewProduct';
import TrackOrder from 'views/pages/track-order/TrackOrder';

const BranchInfoAndTimings = Loadable(lazy(() => import('views/pages/branches/BranchInfoAndTimings')));

// import NotFoundPage from 'views/pages/notfound';
// ==============================|| MAIN ROUTING ||============================== //

const CommonRoutes = () => ({
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: 'branch-info',
      children: [
        {
          path: '',
          element: <BranchInfoAndTimings />,
        },
      ],
    },
    {
      path: 'products/view-product/:productId',
      children: [
        {
          path: '',
          element: <ViewProduct />,
        },
      ],
    },
    {
      path: '/track-order/:orderSessionId/:toLat/:toLng',
      children: [
        {
          path: '',
          element: <TrackOrder />,
        },
      ],
    },
  ],
});

export default CommonRoutes;
