// material-ui
// import RestaurantIcon from '@mui/icons-material/Restaurant';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { getLocalStorage, getLocalUser } from 'utils/commonFunc';
import { CONST_LOCAL_STORAGE_BRANCH } from 'utils/constants';
import useAppContext from 'store/useAppContext';
import CompanyLogo from 'utils/theme-logo/Logo';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const SidebarLogo = () => {
  const { branchesState: { branchesData = [] } = {} } = useAppContext() ?? {};
  const localStorageBranch = getLocalStorage(CONST_LOCAL_STORAGE_BRANCH);
  const user = getLocalUser();
  const defaultBranch = branchesData?.find(branch => branch?.branchId === user?.orgId);
  return (
    <Box style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
      {/* <CompanyLogo height="50" width="60" />{' '} */}
      <Typography variant="h4" sx={{ marginLeft: 1 }}>
        {localStorageBranch?.branchName || defaultBranch?.branchName || user?.orgName || 'Kaarasaaram'}
      </Typography>
    </Box>
  );
};

export default SidebarLogo;
