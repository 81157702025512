const setContextState = ({ setState, paramName, paramValue, isFullUpdate = false, isFullObjectUpdate = false }) => {
  setState(prevData => ({
    ...prevData,
    ...(paramName &&
    paramValue !== null &&
    typeof paramValue === 'object' &&
    !Array.isArray(paramValue) &&
    !isFullUpdate &&
    !isFullObjectUpdate
      ? {
          [paramName]: {
            ...prevData?.[paramName],
            ...paramValue,
          },
        }
      : !paramName && typeof paramValue === 'object'
      ? paramValue
      : {
          [paramName]: paramValue,
        }),
  }));
};

export { setContextState };
