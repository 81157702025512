import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Drawer, useMediaQuery } from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { BrowserView, MobileView } from 'react-device-detect';

// project imports
import MenuList from './MenuList';
import LogoSection from '../LogoSection';
import { drawerWidth } from 'store/constant';
import { useNavigate } from 'react-router-dom';
import CustomTwoInlineColumns from 'ui-component/CustomTwoInlineColumns/CustomTwoInlineColumns';
import CustomIcon from 'ui-component/CustomIcon/CustomIcon';
import CancelIcon from '@mui/icons-material/Cancel';
// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();
  // const mainContentRef = useRef(null);

  // useEffect(() => {
  //   const handleClickOutside = event => {
  //     if (!mainContentRef?.current?.contains(event.target)) {
  //       // Handle click outside the sidebar (e.g., close sidebar)
  //       drawerToggle(false); // Example: Close sidebar logic
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [drawerToggle]);

  const drawer = (
    <>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <CustomTwoInlineColumns>
          <Box onClick={() => navigate('/branch-info')} sx={{ display: 'flex', p: 2, mx: 'auto' }}>
            <LogoSection />
          </Box>
          <CustomIcon onClick={() => drawerToggle(false)} sx={{ fontSize: 10 }}>
            <CancelIcon fontSize="small" />
          </CustomIcon>
        </CustomTwoInlineColumns>
      </Box>
      <BrowserView
      // ref={mainContentRef}
      >
        <PerfectScrollbar
          component="div"
          style={{
            height: !matchUpMd ? 'calc(100vh - 56px)' : 'calc(100vh - 88px)',
            paddingLeft: '16px',
            paddingRight: '16px',
          }}>
          <MenuList />
          {/* <MenuCard /> */}
        </PerfectScrollbar>
      </BrowserView>
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          {/* <MenuCard /> */}
        </Box>
      </MobileView>
    </>
  );

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : 'auto' }}
      aria-label="mailbox folders">
      <Drawer
        container={container}
        variant={matchUpMd ? 'persistent' : 'temporary'}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: 'none',
            [theme.breakpoints.up('md')]: {
              top: '88px',
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit">
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
