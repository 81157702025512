// APIS SOURCE

import moduleApis from './apis';
import { getLocalStorage, getOrgId } from '../commonFunc';
import { CONST_ACTION_VIEW, CONST_LOCAL_STORAGE_LOGGED_USER } from '../constants';

// operation
// args: {
//     module
//     url,
//** context params **** */
//     loadingParam,
//     stateParam,
//** data and messages **** */
//     orgId: 0,
//     payload,
//     id: 0,
//     page: 1,
//     size: 10,
//     message
// };

const appendQueryParams = (params, canAppend = false) => {
  const paramsList = Object.values(params || {}).filter(value => value);
  if (paramsList?.length) {
    return `${canAppend ? '&' : '?'}${Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&')}`;
  }
  return '';
};

const appendIds = (ids, args) => {
  const orgId = args.branchId || args.orgId || getOrgId() || '';
  const { userId } = getLocalStorage(CONST_LOCAL_STORAGE_LOGGED_USER) ?? {};
  const { idName, getIdName } = args;
  return ids?.length
    ? `/${ids
        .map(id =>
          id === 'orgId' && orgId ? orgId : id === 'userName' ? userId : args?.[id || getIdName || idName] || ''
        )
        .join('/')}`
    : '';
};

const formAction = ({ method, apiPropName, loadingParam, args }) => {
  const { module = '', page = 0, size = 0, clickAction = '', stateParam, cartItemIds } = args ?? {};
  const API_CONFIG = moduleApis?.[`API_${module}`]?.[apiPropName];
  const appendIdsString = appendIds(API_CONFIG?.ids, args);
  const isIdsExist = appendIdsString !== '/' ? appendIdsString : '';
  const moduleLor = module?.toLowerCase();
  return {
    ...args, // module, orgId, idName, or someOtherIds, payload, page, size, message
    method,
    url: `${API_CONFIG?.api}${isIdsExist ? appendIdsString : ''}${appendQueryParams({
      ...(cartItemIds // delete caritems -> cartItemIds=1,2,3
        ? { cartItemIds }
        : { page, size }),
    })}`,
    loadingParam,
    ...(apiPropName === 'getAll' && {
      stateParam: stateParam || `${moduleLor}Data`,
    }),
    ...(apiPropName === 'get' && {
      stateParam: stateParam || `${moduleLor}${clickAction === CONST_ACTION_VIEW ? 'View' : 'One'}`,
    }),
  };
};

const formActionGetAll = args => {
  return formAction({
    method: 'GET',
    apiPropName: args?.apiPropName || 'getAll',
    loadingParam: args?.loadingParam || 'getAllFetching',
    args,
  });
};

const formActionPost = args => {
  return formAction({
    method: 'POST',
    apiPropName: args?.apiPropName || 'post',
    loadingParam: args?.loadingParam || 'posting',
    args,
  });
};

const formActionGet = args => {
  return formAction({
    method: 'GET',
    apiPropName: args?.apiPropName || 'get',
    loadingParam: args?.loadingParam || 'getFetching',
    args,
  });
};

const formActionPut = args => {
  return formAction({
    method: 'PUT',
    apiPropName: args?.apiPropName || 'put',
    loadingParam: args?.loadingParam || 'putting',
    args,
  });
};

const formActionPatch = args => {
  return formAction({
    method: 'PATCH',
    apiPropName: args?.apiPropName || 'patch',
    loadingParam: args?.loadingParam || 'putting',
    args,
  });
};

const formActionDelete = args => {
  return formAction({
    method: 'DELETE',
    apiPropName: args?.apiPropName || 'delete',
    loadingParam: args?.loadingParam || 'deleting',
    args,
  });
};

export { formActionGetAll, formActionPost, formActionGet, formActionPut, formActionPatch, formActionDelete };
