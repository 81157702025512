import React from 'react';
import './OrderCollapse.css';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { dateFormat, formAddress, formatCurrency, getUserRole } from 'utils/commonFunc';
import CustomTypography from 'ui-component/CustomTypography/CustomTypography';
import CustomNoDataFound from 'ui-component/CustomNoDataFound';
import useAppContext from 'store/useAppContext';
import Orders, { deliveryStatusList, failedStatusData } from '../clients/orders/Orders';
import { IconSoup } from '@tabler/icons';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CustomFlexRow from 'ui-component/CustomFlexRow/CustomFlexRow';
import NumbersIcon from '@mui/icons-material/Numbers';
import PaymentIcon from '@mui/icons-material/Payment';
import CustomButton from 'ui-component/CustomButton/CustomButton';
import OrderCollapseSkeleton from './OrderCollapseSkeleton';
import CustomTwoInlineColumns from 'ui-component/CustomTwoInlineColumns/CustomTwoInlineColumns';
import PersonIcon from '@mui/icons-material/Person';
import CallIcon from '@mui/icons-material/Call';
import CustomTooltip from 'ui-component/CustomTooltip/CustomTooltip';
import {
  CONST_ORDER_STATUS_DELIVERED,
  CONST_ORDER_STATUS_ORDER_COMPLETED,
  CONST_ORDER_STATUS_ORDER_REQUESTED,
  // CONST_TYPE_TEXT_AREA,
} from 'utils/constants';
import Info from '@mui/icons-material/Info';
// import CustomInput from 'ui-component/forms/CustomInput';
// import CustomIcon from 'ui-component/CustomIcon/CustomIcon';
// import Check from '@mui/icons-material/Check';
// import Close from '@mui/icons-material/Close';
// import CustomRatings from 'ui-component/CustomRatings/CustomRatings';

const getStatusLabel = passStatus => {
  const statusItem = deliveryStatusList.find(statusItem => statusItem.value === passStatus);
  const failedItemLabel = failedStatusData?.[passStatus] ? failedStatusData?.[passStatus]?.label : passStatus;
  return statusItem ? statusItem?.label : failedItemLabel;
};

export default function OrderCollapse(props) {
  const {
    orderType,
    recentOrders,
    getOrdersBySession,
    updateOrderStatusCb,
    ordersFetching,
    orderItemsFetchingSessionId,
    // Order approve
    isManual = false,
    manualConfirmButtonLabel = '',
    manualPassLoading = false,
    manualOnConfirm = undefined,
    // assign
    assigingRiderOrderId = 0,
    assignRiderToOrder,
  } = props;
  const { ordersState } = useAppContext();
  // const [isShowReviewButton, toggleReviewButton] = useState(true);
  // const [reviewText, setReviewText] = useState('');

  const getExistOrderItems = orderSessionId => {
    return ordersState?.[`${orderType?.toLowerCase()}OrderItemsData${orderSessionId}`];
  };

  // const toggleButton = () => {
  //   setReviewText('');
  //   toggleReviewButton(prevState => !prevState);
  // };

  // const onReviewChange = value => {
  //   setReviewText(value);
  // };

  // const saveReviewComment = () => {
  // api
  // reviewText
  // pass
  // };

  const { isRoleAdmin, isRoleRider } = getUserRole();
  const skeletonOrNodataFoundDiv = ordersFetching
    ? Array.from({ length: 8 }, (_, i) => i + 1).map(number => <OrderCollapseSkeleton key={number} keyIndex={number} />)
    : !ordersFetching && <CustomNoDataFound itemsName="Orders" loading={ordersFetching} />;
  return (
    <>
      {recentOrders?.length > 0
        ? recentOrders?.map((order, i) => {
            const {
              orderSessionId,
              total,
              orderType,
              creationDateTime,
              orderStatus,
              userName,
              phoneNumber,
              // addressLine,
              // addressStreet,
              // district,
              // state,
              // pinCode,
              comments = '',
              rideOrderId = 0,
              riderName = '',
              // test
            } = order;
            const existList = getExistOrderItems(orderSessionId);
            const isHighlighted = isRoleAdmin && orderStatus === CONST_ORDER_STATUS_ORDER_REQUESTED;
            return (
              <Accordion elevation={1} key={orderSessionId}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${i + 1}-content`}
                  id={`panel${i + 1}-header`}
                  onClick={() => {
                    if (!existList?.length) {
                      getOrdersBySession?.({ orderType, orderSessionId, orderStatus });
                    }
                  }}
                  className={isHighlighted ? 'blinking' : ''}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={2}>
                      <CustomFlexRow>
                        <NumbersIcon fontSize="small" />
                        <CustomTypography sx={{ mt: 0.2, ml: 1, fontWeight: 'bolder' }} variant="subtitle">
                          {orderSessionId}
                        </CustomTypography>
                      </CustomFlexRow>
                    </Grid>
                    <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={3}>
                      <CustomFlexRow>
                        <IconSoup size="21" />
                        <CustomTypography
                          sx={{
                            mt: 0.3,
                            ml: 1,
                            color: [CONST_ORDER_STATUS_ORDER_COMPLETED, CONST_ORDER_STATUS_DELIVERED].includes(
                              orderStatus
                            )
                              ? 'green'
                              : failedStatusData[orderStatus]
                              ? 'red'
                              : '#FF8C00',
                            fontWeight: 'bolder',
                            fontSize: 14,
                          }}
                          variant="body2">
                          {getStatusLabel(orderStatus)}
                        </CustomTypography>
                      </CustomFlexRow>
                    </Grid>
                    <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={3}>
                      <CustomFlexRow>
                        <PaymentIcon fontSize="small" />
                        <CustomTypography sx={{ mt: 0.2, ml: 1 }} variant="subtitle">
                          {formatCurrency(total)}
                        </CustomTypography>
                      </CustomFlexRow>
                    </Grid>
                    <Grid item sx={{ paddingY: { xs: 1 } }} xs={12} md={4}>
                      <CustomFlexRow>
                        <AccessTimeIcon fontSize="small" />
                        <CustomTypography sx={{ mt: 0.1, ml: 1 }} variant="subtitle">
                          {dateFormat(creationDateTime, 'MMM dd, yyyy hh:mm a')}
                        </CustomTypography>
                      </CustomFlexRow>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <CustomTwoInlineColumns>
                    {isRoleAdmin ? (
                      <CustomFlexRow sx={{ mb: 1 }}>
                        <CustomFlexRow sx={{}}>
                          <PersonIcon sx={{ fontSize: 16 }} />
                          <CustomTypography sx={{ pl: 0.5 }} variant="caption">
                            <b>{userName}</b>
                          </CustomTypography>
                        </CustomFlexRow>
                        <CustomFlexRow sx={{ ml: 1 }}>
                          <CallIcon sx={{ fontSize: 16 }} />
                          <CustomTypography sx={{ pl: 0.5 }} variant="caption">
                            <b>{phoneNumber}</b>
                          </CustomTypography>
                        </CustomFlexRow>
                        <CustomTooltip content={formAddress(order)}>
                          <Info sx={{ ml: 2, fontSize: 16 }} />
                        </CustomTooltip>
                      </CustomFlexRow>
                    ) : (
                      <></>
                    )}
                    {/* {isRoleCustomer && orderStatus === CONST_ORDER_STATUS_ORDER_COMPLETED ? (
                      <CustomFlexRow>
                        <CustomRatings rating={3} />
                        {isShowReviewButton ? (
                          <CustomButton
                            sx={{ color: '#2196f3' }}
                            variant="link"
                            name="Write review"
                            onClick={toggleButton}
                          />
                        ) : (
                          <CustomFlexRow>
                            <CustomInput
                              type={CONST_TYPE_TEXT_AREA}
                              fieldValue={reviewText}
                              onChange={onReviewChange}
                            />
                            <CustomIcon onClick={saveReviewComment}>
                              <Check />
                            </CustomIcon>
                            <CustomIcon onClick={toggleButton}>
                              <Close />
                            </CustomIcon>
                          </CustomFlexRow>
                        )}
                      </CustomFlexRow>
                    ) : (
                      <Box />
                    )} */}
                    {/* Refresh section */}
                    {!isRoleRider && (
                      <Box sx={{ textAlign: 'right', mb: 1 }}>
                        <CustomTooltip content="Refresh">
                          <CustomButton
                            variant="outlined"
                            name="Refresh"
                            size="small"
                            sx={{
                              color: '#1E90FF',
                              '&:hover': {
                                textDecoration: 'underline',
                              },
                            }}
                            onClick={() => getOrdersBySession?.({ orderType, orderSessionId, orderStatus })}
                          />
                        </CustomTooltip>
                      </Box>
                    )}
                  </CustomTwoInlineColumns>
                  <Orders
                    {...order}
                    orderType={orderType}
                    orderSessionId={orderSessionId}
                    orderItems={existList?.map(orderItem => ({ ...orderItem, comments }))}
                    updateOrderStatusCb={updateOrderStatusCb}
                    orderItemsFetchingSessionId={orderItemsFetchingSessionId}
                    orderManualApproveModalData={{
                      isManual,
                      manualConfirmButtonLabel,
                      manualPassLoading: manualPassLoading,
                      manualOnConfirm: () => manualOnConfirm({ orderType, orderSessionId }),
                    }}
                    assigingRiderOrderId={assigingRiderOrderId}
                    assignRiderToOrder={assignRiderToOrder}
                    rideOrderId={rideOrderId}
                    riderName={riderName}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })
        : skeletonOrNodataFoundDiv}
    </>
  );
}
