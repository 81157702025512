import styled from '@emotion/styled';

const SpanElement = ({ children, style, className }) => (
  <span style={style} className={className}>
    {children}
  </span>
);

const StrikethroughText = styled(SpanElement)`
  text-decoration: line-through;
  color: #878787;
`;

const CustomStrikeoutText = ({ text, children }) => {
  return <StrikethroughText>{text || children}</StrikethroughText>;
};

export default CustomStrikeoutText;
