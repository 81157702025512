const ordersState = {
  // Data
  ordersData: {}, // getAll
  ordersForm: {}, // post/put
  ordersOne: {}, // get
  ordersView: {}, // view
  page: 1,
  size: 10,
  // Loading
  getAllFetching: false,
  getFetching: false,
  posting: false,
  putting: false,
  deleting: false,
  // Form
  ordersModalOpen: false,
  formState: {},
  orderTracks: [],
  getTracksLoading: false,
  // Payment
  makePayLoading: false,
  // Recent orders (admin)
  deliveryOrders: [],
  deliveryOrdersFetching: false,
  deliveryOrderItemsFetching: false,
  takeAwayOrders: [],
  takeAwayOrdersFetching: false,
  takeAwayOrderItemsFetching: false,
  orderStatusUpdating: false,
  // Completed orders (admin)
  completedOrdersData: {},
  completedOrdersFetching: false,
  completedOrderItemsFetching: false,
  completedOrdersPage: 1,
  completedOrdersSize: 10,
  // Failed orders (admin)
  failedOrdersData: {},
  failedOrdersFetching: false,
  failedOrderItemsFetching: false,
  failedOrdersPage: 1,
  failedOrdersSize: 10,
  // All order (client)
  allOrdersForClient: [],
  allOrdersForClientFetching: false,
  allOrdersItemsForClientFetching: false,
  // Cancel order
  cancelOrderConfirmModalOpen: false,
  acceptOrderRejectOrderSessionId: 0,
  acceptOrderRejectOrderStatus: '',
  acceptOrderRejectOrderItemId: 0,
  acceptOrderRejectOrderType: '',
  // cancellation reason
  cancellationReasonsFetching: false,
  cancellationReasons: [],
  // Rider orders (Admin)
  allRiders: [],
  allRidersFetching: false,
  allAwaitingOrders: [],
  allAwaitingOrdersFetching: false,
  allAssignedRiderOrders: [],
  allAssignedRiderOrdersFetching: false,
  allCompletedRiderOrders: [],
  allCompletedRiderOrdersFetching: false,
  // Assign
  assignRiderByAdminUpdating: false,
  // Rider orders (Rider)
  assignOrderByRiderUpdating: false,
  allAssignedRiderOrdersForRider: [],
  allAssignedRiderOrdersForRiderFetching: false,
  allCompletedRiderOrdersForRiderData: {},
  allCompletedRiderOrdersForRiderFetching: false,
  allCompletedOrdersForRiderPage: 1,
  allCompletedOrdersForRiderSize: 10,
  // Track order
  allOrdersItemsBySessionFetching: false,
  riderDetailsBySessionFetching: false,
};

export default ordersState;
